// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, TouchableOpacity, KeyboardAwareScrollView } from 'react-native-ui-lib';
import DynamicForm, { CustomModal, CustomNumberInput, CustomTextField, DangerButton, Label, PrimaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { App } from "antd"
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { Icon } from "react-native-eva-icons"

const TempLoginScreen = observer(function TempLoginScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const permissions = GlobalMasterStore((state) => state.permissions);
    const setPermissions = GlobalMasterStore((state) => state.setPermissions);

    const token = GlobalMasterStore((state) => state.token);
    const setToken = GlobalMasterStore((state) => state.setToken);

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const [email, setSearch] = useState("");
    const [password, setPassword] = useState("");
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [sendingOTP, setSendingOTP] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [forgotEmail, setForgotEmail] = useState("");
    const [forgotOTP, setForgotOTP] = useState("");
    const [forgotPassword, setForgotPassword] = useState("");
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);

    const tempLoggedIn = GlobalMasterStore((state) => state.tempLoggedIn);
    const setTempLoggedIn = GlobalMasterStore((state) => state.setTempLoggedIn);

    return (<View center style={{
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    }}>
        <SafeAreaView style={{
            width: "100%",
            height: Dimensions.get('window').height,
        }}>
            <KeyboardAwareScrollView style={{
                width: "100%",
                height: Dimensions.get('window').height,
            }}>
                <View row style={{
                    width: "100%",
                    height: Dimensions.get('window').height,
                }}>
                    <View centerH centerV={Platform.OS == 'web'} flexG padding-20
                        style={{
                            width: "100%",
                            height: Platform.OS == 'web' ? Dimensions.get('window').height : undefined,
                            position: 'relative',
                            zIndex: 1,
                        }}
                    >
                        <View centerH backgroundColor="white" padding-20 style={{
                            width: "100%",
                            maxWidth: 600,
                            borderRadius: 10,
                            background: "rgba(255, 255, 255, 0.5)",
                            borderRadius: 16,
                            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                            backdropFilter: "blur(8.6px)",
                            WebkitBackdropFilter: "blur(8.6px)",
                            border: "1px solid rgba(255, 255, 255, 0.28)",
                        }}>
                            <View center style={{
                                width: "100%",
                            }}>
                                <View marginB-20 center style={{
                                    maxWidth: 500,
                                }}>
                                    <Text center>AI Assistant For Research Work</Text>
                                    {/* <Text marginV-5 center style={{
                                        fontSize: 20,
                                        fontFamily: "SourceSansProBold",
                                    }}></Text> */}
                                    <Image assetGroup="images" assetName="researchistlogolight" style={{
                                        width: 400,
                                        height: 80,
                                    }}
                                        resizeMode="contain"
                                    />
                                    <Text center>
                                        Researchist is a search engine that uses AI to find insights in research papers and help you select a topic for your research project.
                                    </Text>
                                </View>
                            </View>

                            <View style={{
                                width: "100%",
                            }}>
                                {Label({ label: "Email" })}
                                <CustomTextField value={email} placeholder="Enter Email" onChange={(value) => {
                                    setSearch(value);
                                }}
                                />
                            </View>

                            <View marginT-10 style={{
                                width: "100%",
                            }}>
                                {Label({ label: "Password" })}
                                <CustomTextField type="password" value={password} placeholder="Enter Password" onChange={(value) => {
                                    setPassword(value);
                                }}
                                />
                            </View>

                            <View marginT-10>
                                <PrimaryButton label="Login" onPress={async () => {
                                    if (!email) {
                                        if (Platform.OS === 'web') {
                                            message.success("Please enter email");
                                        } else {
                                            Alert.alert("Please enter email");
                                        }
                                        return;
                                    }

                                    if (!password) {
                                        if (Platform.OS === 'web') {
                                            message.success("Please enter access code");
                                        } else {
                                            Alert.alert("Please enter access code");
                                        }
                                        return;
                                    }

                                    await api.customRoute('Papers/action', {
                                        action: 'recordAccess',
                                        email: email,
                                    });

                                    setTempLoggedIn(true);
                                }} />
                            </View>
                        </View>
                    </View>
                    {/* <View
                        style={{
                            width: "50%",
                            height: Platform.OS == 'web' ? Dimensions.get('window').height : undefined,
                            position: 'relative',
                            zIndex: 0,
                            overflow: 'visible',
                        }}
                    >
                        <Image source={{
                            uri: "https://pixelsurge.tech/wp-content/uploads/2023/12/3d-casual-life-chatgpt-robot-with-speech-bubble.png"
                        }} style={{
                            // height="698" width="745"
                            width: Dimensions.get('window').width * 0.55,
                            height: (Dimensions.get('window').width * 0.55) * 698 / 745,
                            position: 'relative',
                            left: -50,
                            zIndex: -1,
                        }} />
                    </View> */}
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>

        <CustomModal
            title="Forgot Password"
            width={'500px'} visible={showForgotPasswordModal} onClose={() => {
                setShowForgotPasswordModal(false);
            }}>
            <View center paddingH-20>
                <View centerH style={{
                    width: "100%",
                    maxWidth: 500,
                }}>
                    <View style={{
                        width: "100%",
                    }}>
                        {Label({ label: "Email" })}
                        <CustomTextField value={forgotEmail} onChange={(value) => {
                            setForgotEmail(value);
                        }} />
                    </View>
                    {!otpSent && <View marginT-10 style={{
                        width: "100%",
                    }}>
                        <PrimaryButton label="Send OTP" disabled={sendingOTP} onPress={async () => {
                            if (!forgotEmail) {
                                if (Platform.OS === 'web') {
                                    message.success("Please enter email");
                                } else {
                                    Alert.alert("Please enter email");
                                }
                                return;
                            }

                            setSendingOTP(true);
                            const res = await api.customRoute("forgot-password", {
                                email: forgotEmail,
                            });

                            if (res.message) {
                                if (Platform.OS === 'web') {
                                    message.success(res.message);
                                } else {
                                    Alert.alert(res.message);
                                }
                            } else {
                                if (Platform.OS === 'web') {
                                    message.success("Something went wrong");
                                } else {
                                    Alert.alert("Something went wrong");
                                }
                            }
                            setSendingOTP(false);
                            setOtpSent(true);
                        }} />
                    </View>}
                    {otpSent && <View marginT-10 style={{
                        width: "100%",
                    }}>
                        {Label({ label: "OTP" })}
                        <CustomTextField value={forgotOTP} onChange={(value) => {
                            setForgotOTP(value);
                        }} />
                    </View>}
                    {otpSent && <View marginT-10 style={{
                        width: "100%",
                    }}>
                        {Label({ label: "New Password" })}
                        <CustomTextField type="password" value={forgotPassword} onChange={(value) => {
                            setForgotPassword(value);
                        }} />
                    </View>}
                    {otpSent && <View marginT-10 style={{
                        width: "100%",
                    }}>
                        <PrimaryButton label="Reset Password" onPress={async () => {
                            if (!forgotOTP) {
                                if (Platform.OS === 'web') {
                                    message.success("Please enter OTP");
                                } else {
                                    Alert.alert("Please enter OTP");
                                }
                                return;
                            }

                            if (!forgotPassword) {
                                if (Platform.OS === 'web') {
                                    message.success("Please enter new password");
                                } else {
                                    Alert.alert("Please enter new password");
                                }
                                return;
                            }

                            if (forgotPassword.length < 6) {
                                if (Platform.OS === 'web') {
                                    message.success("Password should be atleast 6 characters long");
                                } else {
                                    Alert.alert("Password should be atleast 6 characters long");
                                }
                                return;
                            }

                            const res = await api.customRoute("reset-password", {
                                email: forgotEmail,
                                otp: forgotOTP,
                                password: forgotPassword,
                            })

                            if (res.message) {
                                if (Platform.OS === 'web') {
                                    message.success(res.message);
                                } else {
                                    Alert.alert(res.message);
                                }

                                if (res.success) {
                                    setShowForgotPasswordModal(false);
                                }
                            } else {
                                if (Platform.OS === 'web') {
                                    message.success("Something went wrong");
                                } else {
                                    Alert.alert("Something went wrong");
                                }
                            }
                        }} />
                    </View>}
                </View>
            </View>

        </CustomModal>
    </View >)
})

export default TempLoginScreen

const $container = {
    flex: 1,
}