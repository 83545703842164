import React, { useEffect, useState } from "react";
import { View, Card, Text, Image, TouchableOpacity } from "react-native-ui-lib";
import ListingScreen from "../../../screens/MainScreens/CRUD/ListingScreen";
import moment from "moment";
import Svg, { Ellipse, G, Path, Defs, ClipPath } from "react-native-svg"
import RenderHtml from 'react-native-render-html';
import { Dimensions, Platform } from "react-native";
import { CustomCheckbox, CustomNumberInput, CustomSelect, CustomTextField, DangerButton, SecondaryButton } from "../../../mycomponents/DynamicForm";
import { App } from 'antd';
import { api } from "../../../services/api";

export const meetingAttendancefields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Enter Date',
            visible: false,
            width: '100%',
            value: new Date(),
        },
        {
            label: 'Meeting',
            key: 'meeting_id',
            type: 'select',
            placeholder: 'Select Meeting',
            visible: false,
            width: '100%',
            options: response.Meetings ? response.Meetings.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
        },
        {
            label: 'Employee',
            key: 'employee_id',
            type: 'select',
            placeholder: 'Select Employee',
            visible: false,
            width: '100%',
            options: response.Employees ? response.Employees.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
            value: globalData?.employee_id,
        },
        {
            label: 'Course Session',
            key: 'course_session_id',
            type: 'select',
            placeholder: 'Select Course Session',
            visible: false,
            width: '100%',
            options: response.CourseSessions ? response.CourseSessions.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
        },
        {
            label: 'Status',
            key: 'status',
            type: 'select',
            placeholder: 'Select Status',
            visible: true,
            width: '100%',
            options: [
                { label: 'Present', value: 'Present' },
                { label: 'Absent', value: 'Absent' },
                { label: 'Late', value: 'Late' },
                { label: 'Excuse Requested', value: 'Excused' },
                { label: 'Excused', value: 'Excused' },
            ],
        },
        {
            label: 'Excuse Reason',
            key: 'excused_reason',
            type: 'text',
            placeholder: 'Enter Excused Reason',
            visible: true,
            width: '100%',
        },
        {
            label: 'Support Files',
            key: 'support_files',
            type: 'upload',
            placeholder: 'Enter Support Files',
            visible: true,
            width: '100%',
            mimeType: 'image/*',
            moduleName: moduleName,
            multiple: true,
        },
        {
            label: 'Clock In',
            key: 'clock_in',
            type: 'time',
            placeholder: 'Enter Clock In',
            visible: true,
            width: '100%',
        },

        {
            label: 'Late Check In Timing',
            key: 'late_check_in_timing',
            type: 'number',
            placeholder: 'Enter Late Check In Timing',
            visible: true,
            width: '100%',
        },
        {
            label: 'Location Address',
            key: 'location_address',
            type: 'text',
            placeholder: 'Enter Location Address',
            visible: true,
            width: '100%',
        },
        {
            label: 'Face Auth Image',
            key: 'face_auth_image_url',
            type: 'upload',
            placeholder: 'Enter Face Auth Image',
            visible: true,
            mimeType: 'image/*',
            moduleName: moduleName,
            width: '100%',
        },

        {
            label: 'Clock Out',
            key: 'clock_out',
            type: 'time',
            placeholder: 'Enter Clock Out',
            visible: true,
            width: '100%',
        },

        {
            label: 'Clock Out Location Address',
            key: 'clock_out_location_address',
            type: 'text',
            placeholder: 'Enter Location Address',
            visible: true,
            width: '100%',
        },
        {
            label: 'Clock Out Face Auth Image',
            key: 'clock_out_face_auth_image_url',
            type: 'upload',
            placeholder: 'Enter Face Auth Image Url',
            visible: true,
            mimeType: 'image/*',
            moduleName: moduleName,
            width: '100%',
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '100%'
            });
        });
    }

    return formFields;
}

export const announcementMobileCard = ({ item, index, getFieldValue, onRowClick, valueText, columns, DeleteCard, actionFunctions, SidebarStatus }) => {
    const [showAddress, setShowAddress] = useState(false);

    return <Card flex style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: 10 }}>
        <TouchableOpacity padding-10 flex onPress={() => {
            setShowAddress(!showAddress);
        }}>

            <View marginB-10 row spread style={{ width: '100%' }}>
                <View center backgroundColor="#9ec1e9" style={{ width: 60, height: 60, borderRadius: 10 }}>
                    <Text style={{ fontSize: 24, color: 'white' }}>{moment(item.meeting?.date).format('DD')}</Text>
                    <Text style={{ fontSize: 12, color: 'white' }}>{moment(item.meeting?.date).format('ddd')}</Text>
                </View>

                <View flex marginL-10>
                    <View row>
                        <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                            {item.meeting?.title}
                        </Text>
                        <View marginL-10>
                            {item.status == 'Excuse Requested' && <Text bold style={{ color: '#ffc93a' }}>(Excuse Requested)</Text>}
                            {item.status == 'Absent' && <Text bold style={{ color: '#cc1010' }}>(Absent)</Text>}
                            {item.status == 'Late' && <Text bold style={{ color: '#cc1010' }}>(Late)</Text>}
                            {item.status == 'Excused' && <Text bold style={{ color: '#00a650' }}>(Excused)</Text>}
                            {item.status == 'Present' && <Text bold style={{ color: '#00a650' }}>(Present)</Text>}
                        </View>
                    </View>

                    <View>
                        <View flex>
                            <View row marginB-6 flex>
                                <View>
                                    <Text lightCardTitle>{"Check In"}: </Text>
                                </View>
                                <View flex>
                                    <Text bold>
                                        {item.clock_in ? moment(item.clock_in).format('hh:mm A') : 'No Check In'}
                                    </Text>
                                </View>
                            </View>
                            <View row flex>
                                <View>
                                    <Text lightCardTitle>{"Check Out"}: </Text>
                                </View>
                                <View flex>
                                    <Text bold>
                                        {item.clock_out ? moment(item.clock_out).format('hh:mm A') : 'No Check Out'}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </View>

            {showAddress && <View>
                <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} />
                <View flex>
                    <Text lightCardTitle>{"Check In Address"}: </Text>
                    <Text bold marginT-5>
                        {item.location_address || 'No Check In'}
                    </Text>
                </View>

                <View marginV-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} />

                <View flex>
                    <Text lightCardTitle>{"Check Out Address"}: </Text>
                    <Text bold marginT-5>
                        {item.clock_out_location_address || 'No Check Out'}
                    </Text>
                </View>
            </View>}

        </TouchableOpacity>
    </Card>;
}

export const meetingAttendanceMain = {
    fields: meetingAttendancefields,
    mobileCard: announcementMobileCard
}