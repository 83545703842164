import * as React from 'react';
import {
    Text,
    TextInput,
    Button,
    StyleSheet,
    TouchableOpacity,
    Platform,
    Dimensions,
    Alert,
} from 'react-native';
// import { FirebaseRecaptchaVerifierModal, FirebaseRecaptchaBanner } from 'expo-firebase-recaptcha';
// import { initializeApp, getApp } from 'firebase/app';
// import { getAuth, PhoneAuthProvider, signInWithCredential, RecaptchaVerifier } from 'firebase/auth';
import { api } from '../../../../../services/api';
import { App } from "antd"
import AsyncStorage from '@react-native-async-storage/async-storage';
import { GlobalMasterStore } from '../../../../../models/PermissionStore';
import { Col, CustomTextField, Label, PrimaryButton, Row, SecondaryButton } from '../../../../../mycomponents/DynamicForm';
import { useNavigationState, useNavigation } from "@react-navigation/native"
import { useNavigate, useParams } from "react-router-dom"
import { View } from 'react-native-ui-lib';

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyB9FLfsQFjuRL0tsiAicPWiD8NKIyzoAEA",
    authDomain: "researchist-ff0ed.firebaseapp.com",
    projectId: "researchist-ff0ed",
    storageBucket: "researchist-ff0ed.appspot.com",
    messagingSenderId: "438350205219",
    appId: "1:438350205219:web:f2d0f62da5b3191ac4bc96",
    measurementId: "G-QFYF2S23VE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default function LoginPhoneScreen() {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const res = await signInWithEmailAndPassword(auth, email, password);
            console.log(res);
        } catch (error) {
            console.error(error);
        }
    };

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const res = await signInWithPopup(auth, provider);
            console.log(res);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <form onSubmit={handleLogin}>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                <button type="submit">Login</button>
            </form>
            <button onClick={handleGoogleSignIn}>Sign in with Google</button>
        </div>
    );
}
