// name: { type: String, required: true },
// date: { type: Date, required: false },
// duration: { type: Number, required: false },
// description: { type: String, required: false },
// course_id: { type: Types.ObjectId, default: null, ref: 'Courses' },
// course: { type: mongoose.Schema.Types.Mixed, default: {} },

import React, { useEffect, useState } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { CustomCheckbox, CustomNumberInput, CustomTextField, DangerButton, SecondaryButton } from "../../../mycomponents/DynamicForm";
import ListingScreen from "../../../screens/MainScreens/CRUD/ListingScreen";

export const courseSessionfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const MCQComponent = ({ field, useForm, tKey, tIndex }) => {
        const lesson_mcqs = useForm((state) => state.formObject['lesson_mcqs']) || [];

        const setObj = useForm((state) => state.setFormObject);
        const getObj = useForm((state) => state.getFormObject);

        return <View flex>
            {lesson_mcqs.map((lesson_mcq, index) => {
                return <Card padding-15 marginB-15 key={index}>
                    <View row spread>
                        <View row centerV>
                            <Text marginR-10>Question</Text>

                            <CustomNumberInput value={index + 1} onChange={(value) => {
                                // reaarange
                                const temp = [...lesson_mcqs];
                                const tempObj = temp[index];
                                temp.splice(index, 1);
                                temp.splice(value - 1, 0, tempObj);
                                setObj({
                                    lesson_mcqs: temp
                                });
                            }} />
                        </View>

                        <View>

                        </View>

                    </View>

                    <View row spread centerV>
                        <View flex-1>
                            <View marginT-15>
                                <CustomTextField value={lesson_mcq.question} onChange={(value) => {
                                    const temp = [...lesson_mcqs];
                                    temp[index].question = value;
                                    setObj({
                                        lesson_mcqs: temp
                                    });
                                }} />
                            </View>
                        </View>

                        <View paddingL-15>
                            <DangerButton
                                style={{
                                    height: 42,
                                    position: 'relative',
                                    top: 7
                                }}
                                label={"Remove Question"}
                                onPress={() => {
                                    const temp = [...lesson_mcqs];
                                    temp.splice(index, 1);
                                    setObj({
                                        lesson_mcqs: temp
                                    });
                                }} />
                        </View>
                    </View>
                    <View marginV-15 >
                        <Text>Options</Text>
                        {lesson_mcq.options.map((option, optionIndex) => {
                            return <View key={optionIndex}>
                                <View row marginT-15>
                                    <View flex-1>
                                        <CustomTextField value={option.option} onChange={(value) => {
                                            const temp = [...lesson_mcqs];
                                            temp[index].options[optionIndex].option = value;
                                            setObj({
                                                lesson_mcqs: temp
                                            });
                                        }} />
                                    </View>
                                    <View paddingL-15>
                                        <DangerButton
                                            style={{
                                                height: 42,
                                                position: 'relative',
                                                top: 7
                                            }}
                                            label="Remove"
                                            onPress={() => {
                                                const temp = [...lesson_mcqs];
                                                temp[index].options.splice(optionIndex, 1);
                                                setObj({
                                                    lesson_mcqs: temp
                                                });
                                            }} />
                                    </View>
                                </View>
                                <View marginT-10 row>
                                    <CustomCheckbox value={option.is_correct} onChange={(value) => {
                                        const temp = [...lesson_mcqs];

                                        temp[index].options.forEach((item) => {
                                            item.is_correct = false;
                                        });

                                        temp[index].options[optionIndex].is_correct = value;
                                        setObj({
                                            lesson_mcqs: temp
                                        });
                                    }} />
                                    <Text>This is the correct answer</Text>
                                </View>
                            </View>
                        })}
                    </View>
                    <View left >
                        <SecondaryButton label="Add Option" onPress={() => {
                            const temp = [...lesson_mcqs];
                            temp[index].options.push({ option: "", is_correct: false });
                            setObj({
                                lesson_mcqs: temp
                            });
                        }} />
                    </View>

                </Card>
            })}

            <View left>
                <SecondaryButton label="Add Question" onPress={() => {
                    const temp = [...lesson_mcqs];
                    temp.push({ question: "", options: [{ option: "", is_correct: false }] });
                    setObj({
                        lesson_mcqs: temp
                    });
                }} />
            </View>
        </View>
    };

    const formFields = [
        {
            label: 'Trainer Type',
            key: 'type',
            type: 'select',
            placeholder: 'Select Type',
            visible: true,
            width: '100%',
            options: [
                { label: 'Internal', value: 'Internal' },
                { label: 'External', value: 'External' },
            ],
            tab: 'General',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                setObj({
                    updateVisiblity: Math.random(),
                })
            }
        },
        {
            label: 'Trainer',
            key: 'trainer_id',
            type: 'select',
            placeholder: 'Select Trainer',
            visible: (connectedObj) => connectedObj.type == 'Internal',
            width: '100%',
            options: response.Trainers ? response.Trainers.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
            tab: 'General',
        },
        {
            label: 'External Trainer Name',
            key: 'external_trainer_name',
            type: 'text',
            placeholder: 'Enter Trainer',
            visible: (connectedObj) => connectedObj.type == 'External',
            width: '100%',
            tab: 'General',
        },
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter Name',
            visible: true,
            width: '100%',
            tab: 'General',
        },
        {
            label: 'Date',
            key: 'date',
            type: 'datetime',
            placeholder: 'Enter Date',
            visible: true,
            width: '100%',
            value: new Date(Math.ceil(Date.now() / (15 * 60 * 1000)) * (15 * 60 * 1000)),
            tab: 'General',
        },
        {
            label: 'Duration',
            key: 'duration',
            type: 'duration',
            duration_options: ["minutes", "hours"],
            placeholder: 'Enter Duration',
            visible: true,
            width: '100%',
            value: 60 * 60 * 1000,
            tab: 'General',
        },
        {
            label: 'Description',
            key: 'description',
            type: 'editor',
            placeholder: 'Enter Description',
            visible: true,
            width: '100%',
            tab: 'General',
        },
        {
            label: 'Attachment Files',
            key: 'attachment_files',
            type: 'upload',
            visible: true,
            width: '100%',
            mimeType: "image/*",
            moduleName: moduleName,
            multiple: true,
            tab: 'General',
        },
        {
            label: 'MCQ',
            key: 'MCQComponent',
            type: 'custom',
            visible: view ? false : true,
            width: '100%',
            component: MCQComponent,
            tab: 'General',
        },
        {
            label: 'Course',
            key: 'course_id',
            type: 'select',
            placeholder: 'Select Course',
            visible: false,
            width: '100%',
            options: response.Courses ? response.Courses.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
            tab: 'General',
        },
        {
            type: 'custom',
            visible: (connectedObj) => {
                if (view) {
                    return true;
                } else {
                    return false;
                }
            },
            width: '100%',
            tab: 'Attendance',
            component: ({ field, useForm, tKey, tIndex }) => {
                const course_id = useForm((state) => state.formObject['course_id']);
                const course_session_id = useForm((state) => state.formObject['_id']);

                return <View w-100 flex>
                    <ListingScreen
                        {...{
                            moduleName: 'CourseSessionAttendances',
                            hideWrapper: true,
                            extraFilters: {
                                'course_id': course_id ?? null,
                                'course_session_id': course_session_id ?? null,
                            },
                            hideViews: true,
                            hideMainHeader: true,
                        }}
                        title={" "}
                        disabledClone={true}
                        disableDelete={true}
                        default_values={{
                            course_id: course_id,
                            is_default_values: true
                        }} />
                </View>
            },
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const courseSessionMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const courseSessionMain = {
    fields: courseSessionfields,
    mobileCard: null
}