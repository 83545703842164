// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, TextInput, ActivityIndicator } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, TouchableOpacity, KeyboardAwareScrollView } from 'react-native-ui-lib';
import DynamicForm, { CustomModal, CustomNumberInput, CustomTextField, DangerButton, Label, PrimaryButton, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { App } from "antd"
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { Icon } from "react-native-eva-icons"
import moment from "moment"
import { set } from "mobx"
import { HeaderResearch } from "./PaperListingScreen"

const isDesktop = Dimensions.get('window').width >= 768;

const TopicListingScreen = observer(function TopicListingScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const permissions = GlobalMasterStore((state) => state.permissions);
    const setPermissions = GlobalMasterStore((state) => state.setPermissions);

    const token = GlobalMasterStore((state) => state.token);
    const setToken = GlobalMasterStore((state) => state.setToken);
    const { route } = props;
    let queryParams = {};

    let params = route?.params ? route?.params : useParams();

    if (Platform.OS === 'web') {
        const fullUrl = new URL(window.location.href).search;
        const queryStringStart = fullUrl.indexOf('?');
        const queryString = fullUrl.substring(queryStringStart);

        const queries = queryString.substring(1);

        queries.split('&').forEach(pair => {
            const [key, value] = pair.split('=');
            queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
        });

        if (!params.ignoreQueryParams) {
            params = { ...params, ...queryParams }
        }
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const [search, setSearch] = useState(queryParams.search || "");
    const [password, setPassword] = useState("");
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [sendingOTP, setSendingOTP] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [forgotEmail, setForgotEmail] = useState("");
    const [forgotOTP, setForgotOTP] = useState("");
    const [forgotPassword, setForgotPassword] = useState("");
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const [loading, setLoading] = useState(false);
    const [refreshCount, setRefreshCount] = useState(0);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [sortColumn, setSortColumn] = useState("");
    const [sortDirection, setSortDirection] = useState("");
    const [papers, setPapers] = useState([]);
    const [cartModal, setCartModal] = useState(false);
    const cart = GlobalMasterStore((state) => state.cart);
    const setCart = GlobalMasterStore((state) => state.setCart);
    const [chatModal, setChatModal] = useState(false);
    const [citationModal, setCitationModal] = useState(false);
    const [citationText, setCitationText] = useState("");
    const [chatPaperId, setChatPaperId] = useState(null);

    const onQueryParams = (params) => {
        if (params.pageIndex) {
            setPageIndex(params.pageIndex);
        }
        if (params.pageSize) {
            setPageSize(params.pageSize);
        }
        if (params.sortColumn || params.sortColumn == null) {
            setSortColumn(params.sortColumn);
        }
        if (params.sortDirection || params.sortDirection == null) {
            setSortDirection(params.sortDirection);
        }
    }

    const onCheckboxChange = (newSet) => {
        setIds([...newSet]);
    }

    const getPapersBySearch = async () => {
        const response = await api.customRoute('Papers/action', {
            action: 'getFutureScopeOfWorksByPaperIds',
            search: search,
            paper_ids: cart.map(item => item._id),
        });

        setPapers(response.data);
    }

    useEffect(() => {
        getPapersBySearch();
    }, []);

    return (<View center style={{
        backgroundColor: "#fff",
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    }}>
        <Image assetGroup="images" assetName="research_bg" style={{
            position: 'absolute',
            width: Dimensions.get('window').width,
            height: Dimensions.get('window').height,
        }} />

        <SafeAreaView style={{
            width: "100%",
            height: Dimensions.get('window').height,
        }}>
            <KeyboardAwareScrollView style={{
                width: "100%",
                height: Dimensions.get('window').height,
            }}>
                <View centerH flexG padding-20
                    style={{
                        width: "100%",
                        height: Platform.OS == 'web' ? Dimensions.get('window').height : undefined,
                    }}
                >

                    <HeaderResearch navigationFn={navigationFn} />

                    <View marginT-10 centerH row backgroundColor="white" padding-10 style={{
                        width: "100%",
                        maxWidth: '100%',
                        borderRadius: 10,
                    }}>

                        <View row centerV style={{
                            width: "100%",
                        }}>
                            <Text h1 marginR-10>SELECT A TOPIC</Text>
                            <Text style={{
                                color: "#8b959c",
                                fontSize: 12,
                            }}>Select a topic to start your research</Text>
                        </View>
                    </View>

                    <View marginT-10 style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: "100%",
                        flexWrap: 'wrap',
                    }}
                    >
                        {papers && papers.length > 0 && papers.map((paper, i) => <Card key={i} style={{
                            flexBasis: "48.5%",
                            margin: 10,
                            padding: 10,
                            borderRadius: 10,
                        }}>
                            <div className="css-175oi2r"
                                style={{
                                    maxWidth: "100%"
                                }}
                            >
                                <div className="css-175oi2r"
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start'
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginRight: 10,
                                                marginTop: 2
                                            }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="18"
                                                viewBox="0 0 14 18"
                                                fill="none"
                                            >
                                                <path
                                                    d="M3.46154 4.69231H5.92308M3.46154 9H10.8462M3.46154 11.4615H10.8462M3.46154 13.9231H5.92308M2.23077 17H12.0769C12.4033 17 12.7164 16.8703 12.9472 16.6395C13.178 16.4087 13.3077 16.0957 13.3077 15.7692V2.23077C13.3077 1.90435 13.178 1.5913 12.9472 1.36048C12.7164 1.12967 12.4033 1 12.0769 1H2.23077C1.90435 1 1.5913 1.12967 1.36048 1.36048C1.12967 1.5913 1 1.90435 1 2.23077V15.7692C1 16.0957 1.12967 16.4087 1.36048 16.6395C1.5913 16.8703 1.90435 17 2.23077 17Z"
                                                    stroke="#20303C"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M10.8463 5C11.0162 5 11.154 4.86224 11.154 4.69231C11.154 4.52237 11.0162 4.38461 10.8463 4.38461C10.6763 4.38461 10.5386 4.52237 10.5386 4.69231C10.5386 4.86224 10.6763 5 10.8463 5Z"
                                                    fill="#20303C"
                                                    stroke="#20303C"
                                                ></path>
                                            </svg>
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 14,
                                                color: "#2c2c2c",
                                                width: "100%",
                                                textWrap: "wrap",
                                                fontFamily: 'SourceSansProSemiBold'
                                            }}
                                        >
                                            {paper.title}
                                        </div>
                                    </div>
                                </div>
                                <div className="css-175oi2r"
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start'
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: 12,
                                                color: "#8b959c",
                                                fontWeight: "normal",
                                                marginLeft: 20,
                                                textWrap: 'wrap',
                                                letterSpacing: 0.4
                                            }}
                                        >
                                            Objective: {paper.objective}
                                        </div>
                                    </div>
                                </div>
                                <div className="css-175oi2r"
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start'
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: 12,
                                                color: "#8b959c",
                                                fontWeight: "normal",
                                                marginLeft: 20,
                                                textWrap: 'wrap',
                                                letterSpacing: 0.4
                                            }}
                                        >
                                            Methodolgy: {paper.methodology}
                                        </div>
                                    </div>
                                </div>
                                <div className="css-175oi2r"
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginBottom: 20
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start'
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: 12,
                                                color: "#8b959c",
                                                fontWeight: "normal",
                                                marginLeft: 20,
                                                textWrap: 'wrap',
                                                letterSpacing: 0.4
                                            }}
                                        >
                                            Base Paper: {paper.paperTitle}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <View row style={{
                                marginTop: "auto",
                                width: "100%",
                            }}>
                                <SecondaryButton style={{
                                    width: "100%",
                                }} onPress={() => {
                                    modal.confirm({
                                        title: 'Ready to dive into this new topic?',
                                        content: 'Just a heads-up: you can switch topics anytime, but you’ll be starting fresh and any progress on your current research will reset. Are you all set to begin?',
                                        okText: 'Yes',
                                        cancelText: 'No',
                                        onOk: () => {
                                            localStorage.setItem("selectedTopic", JSON.stringify(paper));
                                            navigationFn("chat-with-paper", {
                                                id: paper.paperId,
                                            });
                                        },
                                        onCancel: () => {
                                        }
                                    });
                                }} label={"START YOUR RESEARCH"}></SecondaryButton>
                            </View>
                        </Card>)}
                    </View>
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>
    </View >)
})

export default TopicListingScreen

const $container = {
    flex: 1,
}

export const CartComponent = ({ cart, setCart, navigationFn }) => {
    if (!cart || cart.length == 0) return <View style={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    }}>
        <Text style={{
            fontSize: 14,
            color: '#000',
        }}>No papers saved!</Text>
    </View>;



    return <>
        {cart && cart.length > 0 && cart.map((paper, i) => <View marginB-10 key={i} style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
        }}>
            <View style={{
                // flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                width: "100%"
            }}>
                <View style={{
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                }}>
                    <Text style={{
                        fontSize: 14,
                        fontFamily: 'SourceSansProSemiBold',
                        color: '#414223',
                        textWrap: 'wrap',
                    }}>{paper.title}</Text>

                    {/* <Text marginB-10 style={{
                        fontSize: 13,
                        color: '#8b959c',
                        textWrap: 'wrap',
                        fontFamily: 'SourceSansProSemiBold'
                    }}>Future Scope Of Work</Text> */}

                    {/* {paper.future_scope_of_works && paper.future_scope_of_works.length > 0 && paper.future_scope_of_works.map((scope, i) => <Text key={i} style={{
                        fontSize: 13,
                        color: '#8b959c',
                        textWrap: 'wrap',
                        marginBottom: 5,
                    }}>
                        {i + 1}. {scope}</Text>)} */}

                    <View row marginB-10>
                        {/* <SecondaryButton style={{
                            marginTop: 10,
                            marginRight: 10,
                        }} onPress={() => {
                            navigationFn("chat-with-paper", {
                                id: paper._id,
                            });
                        }}>
                            <View row centerV>
                                <Icon name="file-text-outline" width={16} height={16} fill="#20303C" />
                                <Text color="#20303C" marginL-10>Select Paper</Text>
                            </View>
                        </SecondaryButton> */}
                        <SecondaryButton style={{
                            marginTop: 10,
                        }} onPress={() => {
                            setCart(cart.filter(item => item._id != paper._id));
                        }}>
                            <View row centerV>
                                <Icon name="shopping-bag-outline" width={16} height={16} fill="#b60505" />
                                <Text color="#b60505" marginL-10>Remove</Text>
                            </View>
                        </SecondaryButton>
                    </View>


                </View>
            </View>
        </View>)}

        <View marginV-10>
            <TouchableOpacity>
                <View row center padding-10 style={cart && cart.length > 5 ? {
                    backgroundColor: '#20303C',
                    borderRadius: 5,
                } : {
                    backgroundColor: '#f5f5f5',
                    borderRadius: 5,
                }}>
                    {cart && cart.length > 5 ? <Text marginR-10 style={{
                        fontSize: 12,
                        letterSpacing: 1,
                        color: '#fff',
                    }}>START YOUR JOURNEY</Text> : <Text marginR-10 style={{
                        fontSize: 12,
                        letterSpacing: 1,
                        color: '#8b959c',
                    }}>PLEASE ADD MORE PAPERS TO START YOUR JOURNEY</Text>}
                </View>
            </TouchableOpacity>
        </View>

        <View marginV-10>
            <Text h2>Suggested Papers</Text>
        </View>

        {cart && cart.length > 0 && cart.map((paper, i) => <View marginB-10 key={i} style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
        }}>
            <View style={{
                // flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                width: "100%"
            }}>
                <View style={{
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                }}>
                    <Text style={{
                        fontSize: 14,
                        fontFamily: 'SourceSansProSemiBold',
                        color: '#414223',
                        textWrap: 'wrap',
                    }}>{paper.title}</Text>


                    <Text style={{
                        fontSize: 13,
                        color: '#8b959c',
                        textWrap: 'wrap',
                        marginBottom: 5,
                    }}>
                        {paper.description}
                    </Text>

                    <View row marginB-10>
                        <SecondaryButton style={{
                            marginTop: 10,
                            marginRight: 10,
                        }} onPress={() => {
                            navigationFn("chat-with-paper", {
                                id: paper._id,
                            });
                        }}>
                            <View row centerV>
                                <Icon name="file-text-outline" width={16} height={16} fill="#20303C" />
                                <Text color="#20303C" marginL-10>Select Paper</Text>
                            </View>
                        </SecondaryButton>
                    </View>

                </View>
            </View>
        </View>)}
    </>
}

const ChatComponent = ({ id }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [aiThinking, setAIThinking] = useState(false);

    const getAIResponse = async (message) => {
        setAIThinking(true);
        const response = await api.customRoute('Papers/action', {
            action: 'getAIResponse',
            message: message,
            paperId: id,
        });

        const messageData = {
            id: Date.now(),
            sender: 'AI',
            message: response.data,
            type: 'text',
            timestamp: new Date().toISOString(),
        };

        setAIThinking(false);
        setMessages((prevMessages) => [messageData, ...prevMessages]);
    }

    const sendMessage = async (text) => {
        if (newMessage.trim() || text) {
            const messageData = {
                id: Date.now(),
                sender: 'John', // Replace with the actual sender
                message: text ? text : newMessage,
                type: 'text',
                timestamp: new Date().toISOString(),
            };
            setMessages((prevMessages) => [messageData, ...prevMessages]);
            setNewMessage('');
            getAIResponse(newMessage);
        }
    };

    const sendAttachment = async (type) => {
        try {
            let result;
            if (type === 'image') {
                result = await ImagePicker.launchImageLibraryAsync({
                    mediaTypes: ImagePicker.MediaTypeOptions.Images,
                    allowsEditing: true,
                    aspect: [4, 3],
                    quality: 1,
                });
            } else if (type === 'document') {
                result = await DocumentPicker.getDocumentAsync({
                    type: '*/*',
                    copyToCacheDirectory: true,
                });
            } else if (type === 'audio') {
                result = await AudioPicker.getAudioAsync();
            }

            if (!result.cancelled) {
                const messageData = {
                    id: Date.now(),
                    sender: 'John', // Replace with the actual sender
                    message: result.uri,
                    type,
                    timestamp: new Date().toISOString(),
                };
                setMessages((prevMessages) => [...prevMessages, messageData]);
            }
        } catch (error) {
            console.error('Error sending attachment:', error);
        }
    };

    const renderMessage = ({ item }) => {
        const isMyMessage = item.sender === 'John';

        return (
            <View style={{ flexDirection: isMyMessage ? 'row-reverse' : 'row', marginVertical: 8 }}>
                <View
                    style={{
                        backgroundColor: isMyMessage ? '#DCF8C6' : '#E8E8E8',
                        borderRadius: 8,
                        padding: 8,
                        maxWidth: '70%',
                    }}
                >
                    {item.type === 'text' && <Text>{item.message}</Text>}
                    {item.type === 'image' && <Image source={{ uri: item.message }} style={{ width: 200, height: 200 }} />}
                    {item.type === 'document' && <Text>Document: {item.message}</Text>}
                    {item.type === 'audio' && <Text>Audio: {item.message}</Text>}
                    <Text style={{ fontSize: 10, color: 'gray', marginTop: 4 }}>
                        {moment(item.timestamp).format('h:mm a')}
                    </Text>
                </View>
            </View>
        );
    };

    return (
        <View style={{ padding: 16, height: Dimensions.get('window').height - 100 }}>
            {messages.length != 0 && <FlatList
                style={{
                    flex: 1,
                }}
                data={messages}
                renderItem={renderMessage}
                keyExtractor={(item) => item.id.toString()}
                inverted
            />}

            {messages.length == 0 && <View center style={{
                flex: 1
            }}>
                <Image assetGroup="images" assetName="chat_vector" style={{
                    width: "100%",
                    height: 300,
                    resizeMode: 'contain',
                }} />
            </View>}


            {aiThinking && <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                <ActivityIndicator size="small" color="gray" />
                <Text marginL-5 style={{ color: 'gray' }}>AI is thinking</Text>
            </View>}

            {messages.length == 0 && <>
                <TouchableOpacity
                    style={{
                        backgroundColor: '#DCF8C6',
                        padding: 12,
                        borderRadius: 8,
                        marginBottom: 8,
                    }}
                    onPress={() => {
                        sendMessage("Can you please summarize this research paper?");
                    }}
                >
                    <Text>Can you please summarize this research paper?</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={{
                        backgroundColor: '#DCF8C6',
                        padding: 12,
                        borderRadius: 8,
                    }}
                    onPress={() => {
                        sendMessage('What purpose does this research paper solve?');
                    }}
                >
                    <Text>What purpose does this research paper solve?</Text>
                </TouchableOpacity>
            </>}

            <View marginT-20 style={{ flexDirection: 'row', alignItems: 'center' }}>

                <CustomTextField
                    value={newMessage}
                    placeholder="Type a message..."
                    onChange={(value) => {
                        setNewMessage(value);
                    }}
                    suffixComponent={<PrimaryButton onPress={sendMessage} style={{ height: 38, marginLeft: 10 }}>
                        <View row>
                            <Icon name="paper-plane-outline" width={16} height={16} fill="white" />
                        </View>
                    </PrimaryButton>}
                />
            </View>
        </View>
    );
};