// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, TouchableOpacity, KeyboardAwareScrollView } from 'react-native-ui-lib';
import DynamicForm, { CustomEditorField, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, DangerButton, Label, PrimaryButton, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { App, Input, Popconfirm, Segmented, Table, Tag } from "antd"
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { Icon } from "react-native-eva-icons"
import { HeaderResearch, PaperDetails } from "./PaperListingScreen"
import { Path, Svg } from "react-native-svg"
import ReactQuill, { Quill } from "react-quill"

const LibraryScreen = observer(function LibraryScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const permissions = GlobalMasterStore((state) => state.permissions);
    const setPermissions = GlobalMasterStore((state) => state.setPermissions);

    const token = GlobalMasterStore((state) => state.token);
    const setToken = GlobalMasterStore((state) => state.setToken);

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const [search, setSearch] = useState("");
    const [password, setPassword] = useState("");
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [sendingOTP, setSendingOTP] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [forgotEmail, setForgotEmail] = useState("");
    const [forgotOTP, setForgotOTP] = useState("");
    const [forgotPassword, setForgotPassword] = useState("");
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const email = GlobalMasterStore((state) => state.globalData.email);
    const [selectedPaper, setSelectedPaper] = useState(null);
    const [showPaperModal, setShowPaperModal] = useState(false);
    const [tags, setTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => {
                return <TouchableOpacity onPress={() => {
                    setSelectedPaper(record);
                    setShowPaperModal(true);
                    getPaperDetails(record.paper_id);
                    setTags(record.tags || []);
                }}>
                    <Text style={{
                        color: "rgb(38, 114, 172)",
                        fontFamily: "SourceSansProSemiBold",
                        letterSpacing: 0.5,
                    }}>
                        {record.paper_details.title}
                    </Text>
                </TouchableOpacity>
            },
        },
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
            render: (text, record) => {
                return <Text>{record.paper_details.publication_year}</Text>
            },
        },
        {
            title: 'Folder',
            dataIndex: 'folder',
            key: 'folder',
            render: (text, record) => {
                return <Text>{record.folder_name}</Text>
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return <View row centerV>
                    <View>
                        <Popconfirm
                            title="Are you sure you want to delete this paper?"
                            onConfirm={async () => {
                                const res = await api.customRoute("remove-from-folder", {
                                    _id: record._id,
                                });

                                if (res.message) {
                                    if (Platform.OS === 'web') {
                                        message.success(res.message);
                                    } else {
                                        Alert.alert(res.message);
                                    }

                                    getFolders();
                                } else {
                                    if (Platform.OS === 'web') {
                                        message.success("Something went wrong");
                                    } else {
                                        Alert.alert("Something went wrong");
                                    }
                                }
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 5,
                                paddingHorizontal: 10,
                                marginLeft: 10,
                                borderRadius: 5,
                                borderWidth: 1,
                                borderColor: "#dcdcdc80",
                                cursor: 'pointer',
                            }}>
                                <Icon name="trash-2-outline" width={16} height={16} />
                            </View>
                        </Popconfirm>
                    </View>
                </View>
            },
        },
    ];

    const data = [{
        key: '1',
        title: 'John Brown',
        authors: ['John Brown'],
        year: 32,
        citations: 'New York No. 1 Lake Park',
    }, {
        key: '2',
        title: 'Jim Green',
        authors: ['Jim Green'],
        year: 42,
        citations: 'London No. 1 Lake Park',
    }, {
        key: '3',
        title: 'Joe Black',
        authors: ['Joe Black'],
        year: 32,
        citations: 'Sidney No. 1 Lake Park',
    }, {
        key: '4',
        title: 'Jim Red',
        authors: ['Jim Red'],
        year: 32,
        citations: 'London No. 2 Lake Park',
    }, {
        key: '5',
        title: 'Jake White',
        authors: ['Jake White'],
        year: 32,
        citations: 'Dublin No. 2 Lake Park',
    }, {
        key: '6',
        title: 'John Brown',
        authors: ['John Brown'],
        year: 32,
        citations: 'New York No. 1 Lake Park',
    }, {
        key: '7',
        title: 'Jim Green',
        authors: ['Jim Green'],
        year: 42,
        citations: 'London No. 1 Lake Park',
    }, {
        key: '8',
        title: 'Joe Black',
        authors: ['Joe Black'],
        year: 32,
        citations: 'Sidney No. 1 Lake Park',
    }, {
        key: '9',
        title: 'Jim Red',
        authors: ['Jim Red'],
        year: 32,
        citations: 'London No. 2 Lake Park',
    }, {
        key: '10',
        title: 'Jake White',
        authors: ['Jake White'],
        year: 32,
        citations: 'Dublin No. 2 Lake Park',
    }, {
        key: '11',
        title: 'John Brown',
        authors: ['John Brown'],
        year: 32,
        citations: 'New York No. 1 Lake Park',
    }, {
        key: '12',
        title: 'Jim Green',
        authors: ['Jim Green'],
        year: 42,
        citations: 'London No. 1 Lake Park',
    }]

    const [papers, setPapers] = useState([]);
    const [filteredPapers, setFilteredPapers] = useState([]);
    const [folders, setFolders] = useState([]);
    const [showNewFolderModal, setShowNewFolderModal] = useState(false);
    const [showImportPaperModal, setShowImportPaperModal] = useState(false);
    const [doi, setDoi] = useState("");
    const [folderName, setFolderName] = useState("");
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [selectedSegment, setSelectedSegment] = useState("Info");

    const getFolders = async () => {
        const res = await api.customRoute("get-folders", {
            email_id: email,
        });

        setFolders(res.folders);
        setPapers(res.papers);

        const firstFolder = res.folders[0];
        setFilteredPapers(res.papers.filter((paper) => paper.folder_id == firstFolder._id));
        setCurrentFolder(firstFolder._id);
    }

    useEffect(() => {
        getFolders();
    }, [email]);

    useEffect(() => {
        if (search) {
            setCurrentFolder(null);
            setFilteredPapers(papers.filter((paper) => paper.paper_details.title.toLowerCase().includes(search.toLowerCase())));
        }
    }, [search]);

    const getPaperDetails = async (paper_id) => {
        if (selectedPaper) {
            const res = await api.customRoute("get-paper-details", {
                paper_id: paper_id,
            });

            setSelectedPaper({
                ...selectedPaper,
                ...res,
            });
        }
    }

    const updatePaper = async (paper_id, data) => {
    }

    return (<View center style={{
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    }}>
        <SafeAreaView style={{
            width: "100%",
            height: Dimensions.get('window').height,
        }}>
            <KeyboardAwareScrollView style={{
                width: "100%",
                height: Dimensions.get('window').height,
            }}>
                <View row style={{
                    width: "100%",
                    height: Dimensions.get('window').height,
                }}>

                    <View flexG
                        style={{
                            width: "100%",
                            height: Platform.OS == 'web' ? Dimensions.get('window').height : undefined,
                            position: 'relative',
                            zIndex: 1,
                        }}
                    >

                        <HeaderResearch navigationFn={navigationFn} showPaperCount={true} />

                        <View marginT-10 centerH row padding-20 style={{
                            width: "100%",
                            maxWidth: '100%',
                        }}>
                            <View row backgroundColor="white" padding-10 style={{
                                width: "100%",
                                borderRadius: 10,
                                backgroundColor: "#ffffff85",
                                borderRadius: 16,
                                shadowColor: "#000",
                                shadowOffset: {
                                    width: 0,
                                    height: 4,
                                },
                                shadowOpacity: 0.1,
                                shadowRadius: 30,
                                elevation: 5,
                                backdropFilter: "blur(5px)",
                                WebkitBackdropFilter: "blur(5px)",
                                border: "1px solid rgba(255, 255, 255, 0.3)",
                            }}>
                                <View row flex centerV>
                                    <Svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon"
                                        style={{
                                            width: "20px",
                                            verticalAlign: "middle",
                                            fill: "currentColor",
                                            overflow: "hidden",
                                        }}
                                        viewBox="0 0 1024 1024"
                                    >
                                        <Path d="M204.8 341.33v136.54h68.27V409.6h68.26v341.34h-68.26v68.26h204.8v-68.26H409.6V409.6h68.27v68.27h68.26V341.33zm614.4-68.26V204.8c-41.001 0-77.373 18.463-102.4 47.104-25.027-28.631-61.399-47.104-102.4-47.104v68.27a68.27 68.27 0 0 1 68.27 68.27v204.8H614.4v68.26h68.27v68.26a68.28 68.28 0 0 1-68.27 68.28v68.26c41.001 0 77.373-18.463 102.4-47.104 25.027 28.641 61.399 47.104 102.4 47.104v-68.26a68.28 68.28 0 0 1-68.27-68.28V614.4h68.27v-68.26h-68.27v-204.8a68.27 68.27 0 0 1 68.27-68.27z" />
                                    </Svg>
                                    <TextField
                                        value={search}
                                        placeholder="Search for saved papers"
                                        placeholderTextColor="#00000045"
                                        style={{
                                            width: 250,
                                            height: 40,
                                            color: "#000",
                                            marginLeft: 10,
                                            fontSize: 14,
                                        }}
                                        onChangeText={(value) => {
                                            setSearch(value);
                                        }}
                                    />
                                </View>

                                <TouchableOpacity onPress={() => {
                                    getPapersBySearch(true);
                                }}>
                                    <View style={{
                                        backgroundColor: "#20303c",
                                        borderRadius: 80,
                                        padding: 10,
                                        marginLeft: 10,
                                    }}>
                                        <Icon name="search-outline" width={16} height={16} fill="white" />
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>

                        <View row style={{
                            flexWrap: 'wrap',
                            padding: 20,
                        }}>
                            <View style={{
                                width: 300
                            }}>
                                {/* <CustomTextField
                                    placeholder="Search for papers"
                                    value={search} onChange={(value) => {
                                        setSearch(value);
                                    }} /> */}

                                {folders.length > 0 ? folders.map((folder, index) => {
                                    return <View key={index} style={{
                                        width: "100%",
                                        backgroundColor: folder._id === currentFolder ? "#20303c0d" : "transparent",
                                        paddingHorizontal: 10,
                                        borderRadius: 5,
                                    }}>
                                        <View style={{
                                            width: "100%",
                                            height: 1.5,
                                            backgroundColor: "#ccc",
                                            marginTop: 10,
                                            opacity: 0,
                                        }}></View>
                                        <View row centerV>
                                            <TouchableOpacity style={{
                                                flexGrow: 1,
                                            }}
                                                onPress={() => {
                                                    setFilteredPapers(papers.filter((paper) => paper.folder_id === folder._id));
                                                    setCurrentFolder(folder._id);
                                                    setSearch("");
                                                }}
                                            >
                                                <View flexG row centerV>
                                                    <Image source={{
                                                        uri: "https://cdn-icons-png.flaticon.com/512/3735/3735057.png"
                                                    }}
                                                        style={{
                                                            width: 20,
                                                            height: 20,
                                                        }}
                                                    />
                                                    <Text marginL-5 style={{
                                                        fontFamily: "SourceSansProSemiBold",
                                                        letterSpacing: 0.5,
                                                    }}>
                                                        {folder.name}
                                                    </Text>
                                                </View>
                                            </TouchableOpacity>
                                            <View marginL-5>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        setSelectedFolder(folder);
                                                        setFolderName(folder.name);
                                                        setShowNewFolderModal(true);
                                                    }}
                                                >
                                                    <Icon name="edit-outline" width={16} height={16} />
                                                </TouchableOpacity>
                                            </View>
                                            <View marginL-5>
                                                <Popconfirm
                                                    title="Are you sure you want to delete this folder?"
                                                    onConfirm={async () => {
                                                        const res = await api.customRoute("delete-folder", {
                                                            email_id: email,
                                                            folder_id: folder._id,
                                                        });

                                                        if (res.message) {
                                                            if (Platform.OS === 'web') {
                                                                message.success(res.message);
                                                            } else {
                                                                Alert.alert(res.message);
                                                            }

                                                            getFolders();
                                                        } else {
                                                            if (Platform.OS === 'web') {
                                                                message.success("Something went wrong");
                                                            } else {
                                                                Alert.alert("Something went wrong");
                                                            }
                                                        }
                                                    }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Icon name="trash-2-outline" width={16} height={16} />
                                                </Popconfirm>
                                            </View>
                                        </View>

                                        <View style={{
                                            width: "100%",
                                            height: 1.5,
                                            backgroundColor: "#000",
                                            marginTop: 10,
                                            opacity: 0.05,
                                        }}></View>
                                    </View>
                                }) : <View center style={{
                                    width: "100%",
                                    paddingVertical: 20,
                                    borderWidth: 1,
                                    borderColor: "#ccc",
                                    borderStyle: "dashed",
                                }}>
                                    <Text style={{
                                        fontFamily: "SourceSansProSemiBold",
                                        letterSpacing: 0.5,
                                    }}>
                                        No folders found
                                    </Text>
                                </View>}

                                <View marginT-40>
                                    <PrimaryButton
                                        label="New Folder"
                                        onPress={() => {
                                            setShowNewFolderModal(true);
                                        }}
                                    />
                                </View>

                                <View marginT-20>
                                    <SecondaryButton
                                        label="Import Paper By DOI"
                                        onPress={() => {
                                            setShowImportPaperModal(true);
                                        }}
                                    />
                                </View>

                                <CustomModal
                                    title={selectedFolder ? "Edit Folder" : "New Folder"}
                                    width={'500px'}
                                    visible={showNewFolderModal}
                                    onClose={() => {
                                        setShowNewFolderModal(false);
                                        setFolderName("");
                                        setSelectedFolder(null);
                                    }}>
                                    <View center marginT-10>
                                        <View centerH style={{
                                            width: "100%",
                                            maxWidth: 500,
                                        }}>
                                            <View style={{
                                                width: "100%",
                                            }}>
                                                {Label({ label: "Folder Name" })}
                                                <CustomTextField value={folderName} onChange={(value) => {
                                                    setFolderName(value);
                                                }} />
                                            </View>
                                            <View marginT-10 style={{
                                                width: "100%",
                                            }}>
                                                <PrimaryButton
                                                    label={selectedFolder ? "Update Folder" : "Create Folder"}
                                                    onPress={async () => {
                                                        if (!folderName) {
                                                            if (Platform.OS === 'web') {
                                                                message.success("Please enter folder name");
                                                            } else {
                                                                Alert.alert("Please enter folder name");
                                                            }
                                                            return;
                                                        }

                                                        let res;

                                                        if (selectedFolder) {
                                                            res = await api.customRoute("update-folder", {
                                                                folder_id: selectedFolder._id,
                                                                folder_name: folderName,
                                                                email_id: email,
                                                            });
                                                        } else {
                                                            res = await api.customRoute("create-folder", {
                                                                folder_name: folderName,
                                                                email_id: email,
                                                            });
                                                        }


                                                        if (res.message) {
                                                            if (Platform.OS === 'web') {
                                                                message.success(res.message);
                                                            } else {
                                                                Alert.alert(res.message);
                                                            }

                                                            getFolders();
                                                            setShowNewFolderModal(false);

                                                            setFolderName("");
                                                            setSelectedFolder(null);
                                                        } else {
                                                            if (Platform.OS === 'web') {
                                                                message.success("Something went wrong");
                                                            } else {
                                                                Alert.alert("Something went wrong");
                                                            }
                                                        }
                                                    }}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </CustomModal>


                                <CustomModal
                                    title="Import Paper By DOI"
                                    width={'500px'}
                                    visible={showImportPaperModal}
                                    onClose={() => {
                                        setShowImportPaperModal(false);
                                        setDoi("");
                                    }}>
                                    <View center marginT-10>
                                        <View centerH style={{
                                            width: "100%",
                                            maxWidth: 500,
                                        }}>
                                            <View style={{
                                                width: "100%",
                                            }}>
                                                {Label({ label: "DOI" })}
                                                <CustomTextField value={doi} onChange={(value) => {
                                                    setDoi(value);
                                                }} />
                                            </View>
                                            <View marginT-10 style={{
                                                width: "100%",
                                            }}>
                                                <PrimaryButton
                                                    label="Import Paper"
                                                    onPress={async () => {
                                                        if (!doi) {
                                                            if (Platform.OS === 'web') {
                                                                message.success("Please enter DOI");
                                                            } else {
                                                                Alert.alert("Please enter DOI");
                                                            }
                                                            return;
                                                        }

                                                        const res = await api.customRoute("import-paper-by-doi", {
                                                            doi: doi,
                                                            email_id: email,
                                                        });

                                                        if (!res.success) {
                                                            if (Platform.OS === 'web') {
                                                                message.success(res.message);
                                                            } else {
                                                                Alert.alert(res.message);
                                                            }
                                                            return;
                                                        }

                                                        if (res.message) {
                                                            if (Platform.OS === 'web') {
                                                                message.success(res.message);
                                                            } else {
                                                                Alert.alert(res.message);
                                                            }

                                                            if (res.paper) {
                                                                const res2 = await api.customRoute("add-to-folder", {
                                                                    folder_id: currentFolder,
                                                                    paper_details: res.paper,
                                                                    paper_id: res.paper.id,
                                                                });

                                                                if (res2.message) {
                                                                    if (Platform.OS === 'web') {
                                                                        message.success(res2.message);
                                                                    } else {
                                                                        Alert.alert(res2.message);
                                                                    }
                                                                }

                                                                if (res2.success) {
                                                                    getFolders();
                                                                }
                                                            }

                                                            setShowImportPaperModal(false);
                                                            setDoi("");
                                                        } else {
                                                            if (Platform.OS === 'web') {
                                                                message.success("Something went wrong");
                                                            } else {
                                                                Alert.alert("Something went wrong");
                                                            }
                                                        }
                                                    }}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </CustomModal>
                            </View>
                            <View flex paddingL-40>
                                <Table columns={columns} dataSource={filteredPapers} />
                            </View>
                        </View>
                    </View>
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>

        <CustomModal
            title="Paper Details"
            width={720}
            visible={showPaperModal}
            onClose={() => {
                setShowPaperModal(false);
            }}
            drawer={true}
        >
            <View paddingH-20>
                <View marginT-10 marginB-10>
                    <Segmented
                        options={['Info', 'Notes']}
                        onChange={(value) => {
                            setSelectedSegment(value);
                        }}
                    />
                </View>


                <View padding-5>
                    {selectedSegment == "Info" && <PaperDetails publication={selectedPaper?.paper_details} />}

                    {selectedSegment == "Notes" && <View centerH style={{
                    }}>
                        {/* <ReactQuill
                            value={selectedPaper?.notes}
                            onChange={(value) => {
                                setSelectedPaper({
                                    ...selectedPaper,
                                    notes: value,
                                });
                            }}
                            theme="snow"
                            style={{
                                minHeight: 450,
                            }}
                        /> */}

                        <CustomEditorField
                            value={selectedPaper?.notes}
                            onChange={(value) => {
                                setSelectedPaper({
                                    ...selectedPaper,
                                    notes: value,
                                });
                            }}
                        />
                    </View>}

                    {/* {selectedSegment == "Tags" && <View style={{
                    }}>
                        <View row marginB-10>
                            {tags.map((tag) => (
                                <span key={tag} style={{ display: 'inline-block' }}>
                                    <Tag
                                        closable
                                        onClose={(e) => {
                                            e.preventDefault();
                                            setTags(tags.filter((t) => t !== tag));
                                        }}
                                    >
                                        {tag}
                                    </Tag>
                                </span>
                            ))}
                        </View>
                        {inputVisible ? (
                            <Input
                                ref={inputRef}
                                type="text"
                                size="small"
                                style={{ width: "100%" }}
                                value={inputValue}
                                placeholder="Enter new tag name here..."
                                onChange={(e) => {
                                    setInputValue(e.target.value);
                                }}
                                onBlur={() => {
                                    if (inputValue && tags.indexOf(inputValue) === -1) {
                                        setTags([...tags, inputValue]);
                                    }
                                    setInputVisible(false);
                                    setInputValue('');
                                }}
                                onPressEnter={() => {
                                    if (inputValue && tags.indexOf(inputValue) === -1) {
                                        setTags([...tags, inputValue]);
                                    }
                                    setInputVisible(false);
                                    setInputValue('');
                                }}
                            />
                        ) : (
                            <Tag onClick={() => {
                                setInputVisible(true);
                            }} style={{
                                background: '#fff',
                                borderStyle: 'dashed',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyItems: 'center',
                                height: 32,
                            }}>
                                <View paddingH-5 row center>
                                    <Icon name="plus" width={16} height={16} />
                                    <View marginR-5>
                                        New Tag
                                    </View>
                                </View>
                            </Tag>
                        )}
                    </View>} */}

                    {/* {selectedSegment == "Related" && <View centerH style={{
                    }}>
                        <Text>Related</Text>
                    </View>} */}
                </View>
            </View>
        </CustomModal>
    </View >)
})

export default LibraryScreen

const $container = {
    flex: 1,
}