import React, { useEffect, useState } from "react";
import { View, Card, Text, Image, TouchableOpacity } from "react-native-ui-lib";
import ListingScreen from "../../../screens/MainScreens/CRUD/ListingScreen";
import moment from "moment";
import Svg, { Ellipse, G, Path, Defs, ClipPath } from "react-native-svg"
import RenderHtml from 'react-native-render-html';
import { Dimensions, Platform } from "react-native";
import { CustomCheckbox, CustomNumberInput, CustomSelect, CustomTextField, DangerButton, SecondaryButton } from "../../../mycomponents/DynamicForm";
import { App } from 'antd';
import { api } from "../../../services/api";

export const courseSessionAttendancefields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const MCQComponent = ({ field, useForm, tKey, tIndex }) => {
        const lesson_mcqs = useForm((state) => state.formObject['lesson_mcqs']) || [];

        const setObj = useForm((state) => state.setFormObject);
        const getObj = useForm((state) => state.getFormObject);

        return <View flex>
            {lesson_mcqs.map((lesson_mcq, index) => {
                return <Card padding-15 marginB-15 key={index}>
                    <View row spread>
                        <View row centerV>
                            <Text marginR-10>Question</Text>

                            {/* <CustomNumberInput value={index + 1} onChange={(value) => {
                                // reaarange
                                const temp = [...lesson_mcqs];
                                const tempObj = temp[index];
                                temp.splice(index, 1);
                                temp.splice(value - 1, 0, tempObj);
                                setObj({
                                    lesson_mcqs: temp
                                });
                            }} /> */}
                            <Text>{index + 1}</Text>
                        </View>

                        <View>

                        </View>

                    </View>

                    <View row spread centerV>
                        <View flex-1>
                            <View marginT-15>
                                {/* <CustomTextField value={lesson_mcq.question} onChange={(value) => {
                                    const temp = [...lesson_mcqs];
                                    temp[index].question = value;
                                    setObj({
                                        lesson_mcqs: temp
                                    });
                                }} /> */}
                                <Text>{lesson_mcq.question}</Text>
                            </View>
                        </View>
                        {/* 
                        <View paddingL-15>
                            <DangerButton
                                style={{
                                    height: 42,
                                    position: 'relative',
                                    top: 7
                                }}
                                label={"Remove Question"}
                                onPress={() => {
                                    const temp = [...lesson_mcqs];
                                    temp.splice(index, 1);
                                    setObj({
                                        lesson_mcqs: temp
                                    });
                                }} />
                        </View> */}
                    </View>
                    <View marginV-15 >
                        <Text>Options</Text>
                        {lesson_mcq.options.map((option, optionIndex) => {
                            return <View key={optionIndex}>
                                <View row marginT-15>
                                    <View flex-1>
                                        {/* <CustomTextField value={option.option} onChange={(value) => {
                                            const temp = [...lesson_mcqs];
                                            temp[index].options[optionIndex].option = value;
                                            setObj({
                                                lesson_mcqs: temp
                                            });
                                        }} /> */}
                                        <Text>{option.option}</Text>
                                    </View>
                                    {/* <View paddingL-15>
                                        <DangerButton
                                            style={{
                                                height: 42,
                                                position: 'relative',
                                                top: 7
                                            }}
                                            label="Remove"
                                            onPress={() => {
                                                const temp = [...lesson_mcqs];
                                                temp[index].options.splice(optionIndex, 1);
                                                setObj({
                                                    lesson_mcqs: temp
                                                });
                                            }} />
                                    </View> */}
                                </View>
                                <View marginT-10 row>
                                    {option.is_correct && <Text>This is the correct answer</Text>}
                                </View>
                            </View>
                        })}
                    </View>
                    <View left >
                        <Text>
                            Selected Answer: {lesson_mcq.selected_answer}
                        </Text>
                    </View>
                </Card>
            })}
        </View>
    };

    const formFields = [
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Enter Date',
            visible: false,
            width: '100%',
            value: new Date(),
        },
        {
            label: 'Course Session',
            key: 'course_session_id',
            type: 'select',
            placeholder: 'Select Course Session',
            visible: false,
            width: '100%',
            options: response.CourseSessions ? response.CourseSessions.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
        },
        {
            label: 'Employee',
            key: 'employee_id',
            type: 'select',
            placeholder: 'Select Employee',
            visible: false,
            width: '100%',
            options: response.Employees ? response.Employees.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
            value: globalData?.employee_id,
        },
        {
            label: 'Course Session',
            key: 'course_session_id',
            type: 'select',
            placeholder: 'Select Course Session',
            visible: false,
            width: '100%',
            options: response.CourseSessions ? response.CourseSessions.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
        },
        {
            label: 'Status',
            key: 'status',
            type: 'select',
            placeholder: 'Select Status',
            visible: true,
            width: '100%',
            options: [
                { label: 'Present', value: 'Present' },
                { label: 'Absent', value: 'Absent' },
                { label: 'Late', value: 'Late' },
                { label: 'Excused', value: 'Excused' },
                { label: 'Excused', value: 'Excused' },
            ],
        },
        {
            label: 'Excuse Reason',
            key: 'excused_reason',
            type: 'text',
            placeholder: 'Enter Excused Reason',
            visible: true,
            width: '100%',
        },
        {
            label: 'Support Files',
            key: 'support_files',
            type: 'upload',
            placeholder: 'Enter Support Files',
            visible: true,
            width: '100%',
            mimeType: 'image/*',
            moduleName: moduleName,
            multiple: true,
        },
        {
            label: 'Clock In',
            key: 'clock_in',
            type: 'time',
            placeholder: 'Enter Clock In',
            visible: true,
            width: '100%',
        },

        {
            label: 'Late Check In Timing',
            key: 'late_check_in_timing',
            type: 'number',
            placeholder: 'Enter Late Check In Timing',
            visible: true,
            width: '100%',
        },
        {
            label: 'Location Address',
            key: 'location_address',
            type: 'text',
            placeholder: 'Enter Location Address',
            visible: true,
            width: '100%',
        },
        {
            label: 'Face Auth Image',
            key: 'face_auth_image_url',
            type: 'upload',
            placeholder: 'Enter Face Auth Image',
            visible: true,
            mimeType: 'image/*',
            moduleName: moduleName,
            width: '100%',
        },

        {
            label: 'Clock Out',
            key: 'clock_out',
            type: 'time',
            placeholder: 'Enter Clock Out',
            visible: true,
            width: '100%',
        },

        {
            label: 'Clock Out Location Address',
            key: 'clock_out_location_address',
            type: 'text',
            placeholder: 'Enter Location Address',
            visible: true,
            width: '100%',
        },
        {
            label: 'Clock Out Face Auth Image',
            key: 'clock_out_face_auth_image_url',
            type: 'upload',
            placeholder: 'Enter Face Auth Image Url',
            visible: true,
            mimeType: 'image/*',
            moduleName: moduleName,
            width: '100%',
        },
        {
            label: 'MCQ',
            key: 'MCQComponent',
            type: 'custom',
            visible: true,
            width: '100%',
            component: MCQComponent,
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '100%'
            });
        });
    }

    return formFields;
}

export const announcementMobileCard = ({ item, index, getFieldValue, onRowClick, valueText, columns, DeleteCard, actionFunctions, SidebarStatus }) => {
    const [showAddress, setShowAddress] = useState(false);

    return <Card flex style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: 10 }}>
        <TouchableOpacity padding-10 flex onPress={() => {
            setShowAddress(!showAddress);
        }}>

            <View marginB-10 row spread style={{ width: '100%' }}>
                <View center backgroundColor="#9ec1e9" style={{ width: 60, height: 60, borderRadius: 10 }}>
                    <Text style={{ fontSize: 24, color: 'white' }}>{moment(item.course_session?.date).format('DD')}</Text>
                    <Text style={{ fontSize: 12, color: 'white' }}>{moment(item.course_session?.date).format('ddd')}</Text>
                </View>

                <View flex marginL-10>
                    <View row>
                        <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                            {item.course_session?.name}
                        </Text>
                        <View marginL-10>
                            {item.status == 'Excuse Requested' && <Text bold style={{ color: '#ffc93a' }}>(Excuse Requested)</Text>}
                            {item.status == 'Absent' && <Text bold style={{ color: '#cc1010' }}>(Absent)</Text>}
                            {item.status == 'Late' && <Text bold style={{ color: '#cc1010' }}>(Late)</Text>}
                            {item.status == 'Excused' && <Text bold style={{ color: '#00a650' }}>(Excused)</Text>}
                            {item.status == 'Present' && <Text bold style={{ color: '#00a650' }}>(Present)</Text>}
                        </View>
                    </View>

                    <View>
                        <View flex>
                            <View row marginB-6 flex>
                                <View>
                                    <Text lightCardTitle>{"Check In"}: </Text>
                                </View>
                                <View flex>
                                    <Text bold>
                                        {item.clock_in ? moment(item.clock_in).format('hh:mm A') : 'No Check In'}
                                    </Text>
                                </View>
                            </View>
                            <View row flex>
                                <View>
                                    <Text lightCardTitle>{"Check Out"}: </Text>
                                </View>
                                <View flex>
                                    <Text bold>
                                        {item.clock_out ? moment(item.clock_out).format('hh:mm A') : 'No Check Out'}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </View>

            {showAddress && <View>
                <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} />
                <View flex>
                    <Text lightCardTitle>{"Check In Address"}: </Text>
                    <Text bold marginT-5>
                        {item.location_address || 'No Check In'}
                    </Text>
                </View>

                <View marginV-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} />

                <View flex>
                    <Text lightCardTitle>{"Check Out Address"}: </Text>
                    <Text bold marginT-5>
                        {item.clock_out_location_address || 'No Check Out'}
                    </Text>
                </View>
            </View>}

        </TouchableOpacity>
    </Card>;
}

export const courseSessionAttendanceMain = {
    fields: courseSessionAttendancefields,
    mobileCard: announcementMobileCard
}