import React, { useEffect } from 'react';
import { TouchableOpacity, StyleSheet, Platform, ScrollView, Linking } from 'react-native';
import { Colors, View, Text, ExpandableSection, Button, Image, Card } from 'react-native-ui-lib';
import { useNavigate } from 'react-router-dom';
import { useStores } from '../models';
import { observer } from 'mobx-react-lite';
import { Icon } from 'react-native-eva-icons';
import { api } from '../services/api';
import { GlobalMasterStore } from '../models/PermissionStore';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigationState, useNavigation } from "@react-navigation/native"

export const Sidebar = observer(function Sidebar() {
    const { modalStore: { setOpenSomething, getOpenSomething, getModalHistory }, layoutStore: { isSideBarCollapsed, toggleSideBarCollapsed, getSideBarPosition, setSideBarPosition, getCurrentTab, setCurrentTab, closeSideBar } } = useStores();

    const permissions = GlobalMasterStore((state) => state.permissions);
    const setToken = GlobalMasterStore((state) => state.setToken);
    const employee = GlobalMasterStore((state) => state.employee);

    const [expandedItem, setExpandedItem] = React.useState(null);
    const [showGYM, setShowGYM] = React.useState(false);

    // const [showHRMS, setShowHRMS] = React.useState(getCurrentTab === 'HRMS');
    // const [showAccounting, setShowAccounting] = React.useState(getCurrentTab === 'Accounting');
    // const [showTasks, setShowTasks] = React.useState(getCurrentTab === 'Tasks');
    // const [showMasters, setShowMasters] = React.useState(getCurrentTab === 'Masters');
    // const [showApprovals, setShowApprovals] = React.useState(getCurrentTab === 'Approvals');

    const [showHRMS, setShowHRMS] = React.useState(true);
    const [showAccounting, setShowAccounting] = React.useState(true);
    const [showTasks, setShowTasks] = React.useState(true);
    const [showMasters, setShowMasters] = React.useState(true);
    const [showApprovals, setShowApprovals] = React.useState(true);

    const [hrmsMenu, setHrmsMenu] = React.useState([]);
    const [accountingMenu, setAccountingMenu] = React.useState([]);
    const [taskMenu, setTaskMenu] = React.useState([]);
    const [masterMenu, setMasterMenu] = React.useState([]);
    const [approvalsMenu, setApprovalsMenu] = React.useState([]);
    const [prefs, setPrefs] = React.useState({});

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
            closeSideBar();
        }
    };

    useEffect(() => {
        if (Platform.OS === 'web') {
            let path = window?.location?.pathname;
            if (path) {
                path = path.replace("/", "");

                let item = approvalsMenu.find(item => item.link === path);

                if (item) {
                    setExpandedItem("Approvals" + hrmsMenu.indexOf(item));
                }

                item = hrmsMenu.find(item => item.link === path);

                if (item) {
                    setExpandedItem("HRMS" + hrmsMenu.indexOf(item));
                }

                item = accountingMenu.find(item => item.link === path);

                if (item) {
                    setExpandedItem("Accounting" + accountingMenu.indexOf(item));
                }

                item = taskMenu.find(item => item.link === path);

                if (item) {
                    setExpandedItem("Projects & Tasks" + taskMenu.indexOf(item));
                }

                item = masterMenu.find(item => item.link === path);

                if (item) {
                    setExpandedItem("Company Master" + masterMenu.indexOf(item));
                }
            }
        }
    }, [window?.location?.pathname]);

    const updateMenu = () => {
        const hrmsMenuItems = [
            {
                "label": "Employees", "icon": "employees", "link": "Employees", "children": null
            },
            { "label": "Leads", "icon": "leads", "link": "Leads", "children": null },
            {
                "label": "Training", "icon": "trainings", "children": [
                    { "label": "Courses", "link": "Courses", "children": null },
                    { "label": "Course Groups", "link": "CourseGroups", "children": null },
                ]
            },
            { "label": "Meetings/Events", "icon": "meetings", "link": "Meetings", "children": null },
            { "label": "Announcements", "icon": "announcements", "link": "Announcements", "children": null },
            { "label": "Challenges", "icon": "challenges", "link": "Challenges", "children": null },
            { "label": "Joint Field Work", "icon": "field_work", "link": "JointFieldWorks", "children": null },
            { "label": "Reports", "icon": "reports", "link": "Reports", "children": null },
            // { "label": "Joint Field Works", "icon": "field_work", "link": "JointFieldWorks", "children": null },
            {
                "label": "Masters", "icon": "master", "children": [
                    { "label": "KPI Categories", "link": "KpiCategories", "children": null },
                    { "label": "Groups", "link": "Groups", "children": null },
                    { "label": "Announcement Types", "link": "AnnouncementTypes", "children": null },
                    { "label": "Lead Sources", "link": "LeadSources", "children": null },
                    { "label": "Policies", "link": "Policies", "children": null },
                    { "label": "Meeting Types", "link": "MeetingTypes", "children": null },
                    { "label": "Roles", "icon": "Roles", "link": "Roles", "children": null },
                ]
            },
            // {
            //     "label": "Masters", "icon": "Master", "children": [
            //         { "label": "Departments", "icon": "Departments", "link": "Departments", "children": null },
            //         { "label": "Designations", "icon": "Designations", "link": "Designations", "children": null },
            //         { "label": "Locations", "icon": "WorkLocations", "link": "Locations", "children": null },
            //         { "label": "Teams", "icon": "Teams", "link": "Teams", "children": null },
            //         { "label": "Roles", "icon": "Roles", "link": "Roles", "children": null },
            //     ]
            // },
        ];

        function filterBasedOnPermissions(menuItems) {
            return menuItems.filter(item => {
                if (item.children) {
                    item.children = filterBasedOnPermissions(item.children);
                    return item.children.length;
                } else {
                    return permissions[item.link] && permissions[item.link].view;
                    //return true;
                }
            });
        }

        const filteredHrmsMenuItems = filterBasedOnPermissions(hrmsMenuItems);
        setHrmsMenu(filteredHrmsMenuItems);
    }

    useEffect(() => {
        updateMenu();
    }, [permissions]);

    const getOrganizationSettings = async () => {
        const data = await api.customRoute("getOrganizationPrefs", {
            module_name: "Organization"
        });

        setPrefs(data);
    };

    useEffect(() => {
        getOrganizationSettings()
    }, [])

    const renderMenu = (menu, type) => {
        return menu.map((item, index) => {
            return <ExpandableSection
                key={index.toString()}
                expanded={expandedItem == type + index}
                onPress={() => {
                    if (expandedItem == type + index) {
                        setExpandedItem(null);
                    } else {
                        setExpandedItem(type + index);
                        if (item.link) {
                            navigationFn(item.link, {});
                        }
                    }
                }}
                sectionHeader={<View flex centerV marginB-0>
                    {!item.link ?
                        <View style={[styles.MenuHead, expandedItem == type + index ? styles.highlightMenuItem : {}]}>
                            <Image
                                //  source={{ uri: `https://inkapps.pages.dev/icons/${item.icon}.png` }}
                                assetGroup='taqwa'
                                assetName={item.icon}
                                style={{ width: 20, height: 20 }} />
                            {!isSideBarCollapsed && <Text style={[styles.MenuText, expandedItem == type + index ? styles.highlightMenuItemText : {}]} white>{item.label}</Text>}
                        </View> :

                        <TouchableOpacity style={[styles.MenuHead, expandedItem == type + index ? styles.highlightMenuItem : {}, isSideBarCollapsed ? { marginRight: 5 } : {}]} onPress={() => {
                            navigationFn(item.link, {});
                        }}>
                            {item.icon ? <Image
                                // source={{ uri: `https://inkapps.pages.dev/icons/${item.icon}.png` }}
                                assetGroup='taqwa'
                                assetName={item.icon}
                                style={{ width: 20, height: 20 }} /> : <></>}
                            {!isSideBarCollapsed && <Text style={[styles.MenuText]} white>{item.label}</Text>}
                        </TouchableOpacity>}
                </View>}
            >
                {item.children && item.children.map((child, childIndex) => {
                    return <View style={{ paddingLeft: 20 }} key={childIndex.toString()}>
                        <TouchableOpacity
                            onPress={() => {
                                navigationFn(child.link, child.params);
                            }}
                            key={childIndex.toString()} style={{ flexDirection: 'row', alignItems: 'center', padding: 10, marginLeft: 0, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                            <Text style={{ flex: 1 }} white>{child.label}</Text>
                        </TouchableOpacity>
                    </View>
                })}

                {item.custom && <item.custom />}
            </ExpandableSection>
        })
    }

    const mainHeadings = (name) => {
        return <View row flex centerV spread>
            <Text tabHeading>{name}</Text>
            <Icon name={showHRMS ? 'chevron-up' : 'chevron-down'} width={16} height={16} fill='#979899' />
        </View>;
    }

    const renderCardData = [
        // {
        //     // component: (permissions?.Invoices?.view) ? <Card
        //     component: (true) ? <Card
        //         onPress={() => {
        //             navigationFn("MyCalendar")
        //         }}
        //         center
        //         style={{
        //             height: 100,
        //             width: '100%',
        //         }}>
        //         <Image assetGroup="HomeIcons" assetName="Attendance" style={{ width: 60, height: 60 }} />
        //         <View marginT-10>
        //             <Text homeCardText center>My Calendar</Text>
        //         </View>
        //     </Card> : null
        // },
        {
            // component: (permissions?.Invoices?.view) ? <Card
            component: (true) ? <Card
                onPress={() => {
                    navigationFn("ActivityScreen")
                }}
                style={{
                    ...styles.MenuHead,
                    backgroundColor: 'transparent',
                    elevation: 0,
                }}>
                <View marginT-10>
                    <Text style={[styles.MenuText]} white>Daily Activity</Text>
                </View>
            </Card> : null
        },
        {
            component: (true) ? <Card
                onPress={() => {
                    navigationFn("Leads", {

                    });
                }}
                style={{
                    ...styles.MenuHead,
                    backgroundColor: 'transparent',
                    elevation: 0,
                }}>
                <View marginT-10>
                    <Text style={[styles.MenuText]} white>Leads</Text>
                </View>
            </Card> : null
        },
        {
            component: (true) ? <Card
                onPress={() => {
                    navigationFn("MeetingScreen", {
                        showMeetings: true,
                    });
                }}
                style={{
                    ...styles.MenuHead,
                    backgroundColor: 'transparent',
                    elevation: 0,
                }}>
                <View marginT-10>
                    <Text style={[styles.MenuText]} white>Meetings</Text>
                </View>
            </Card> : null
        },
        {
            component: (true) ? <Card
                onPress={() => {
                    navigationFn("TrainingScreen", {

                    });
                }}
                style={{
                    ...styles.MenuHead,
                    backgroundColor: 'transparent',
                    elevation: 0,
                }}>
                <View marginT-10>
                    <Text style={[styles.MenuText]} white>Trainings</Text>
                </View>
            </Card> : null
        },
        {
            component: (true) ? <Card
                onPress={() => {
                    navigationFn("Announcements", {

                    });
                }}
                style={{
                    ...styles.MenuHead,
                    backgroundColor: 'transparent',
                    elevation: 0,
                }}>
                <View marginT-10>
                    <Text style={[styles.MenuText]} white>Announcements</Text>
                </View>
            </Card> : null
        }, {
            component: (true) ? <Card
                onPress={() => {
                    navigationFn("Challenges", {

                    });
                }}
                style={{
                    ...styles.MenuHead,
                    backgroundColor: 'transparent',
                    elevation: 0,
                }}>
                <View marginT-10>
                    <Text style={[styles.MenuText]} white>Challenges</Text>
                </View>
            </Card> : null
        }, {
            component: (true) ? <Card
                onPress={() => {
                    navigationFn("JointFieldWorks", {

                    });
                }}
                style={{
                    ...styles.MenuHead,
                    backgroundColor: 'transparent',
                    elevation: 0,
                }}>
                <View marginT-10>
                    <Text style={[styles.MenuText]} white>Joint Field Work</Text>
                </View>
            </Card> : null
        }, {
            component: (true) ? <Card
                onPress={() => {
                    navigationFn("GoalsScreen", {

                    });
                }}
                style={{
                    ...styles.MenuHead,
                    backgroundColor: 'transparent',
                    elevation: 0,
                }}>
                <View marginT-10>
                    <Text style={[styles.MenuText]} white>Goals and KPIs</Text>
                </View>
            </Card> : null
        }, {
            component: (true) ? <Card
                onPress={() => {
                    navigationFn("EmployeeDocumentsScreen", {

                    });
                }}
                style={{
                    ...styles.MenuHead,
                    backgroundColor: 'transparent',
                    elevation: 0,
                }}>
                <View marginT-10>
                    <Text style={[styles.MenuText]} white>Documents</Text>
                </View>
            </Card> : null
        }
    ];

    return (<View useSafeArea style={{ height: '90%' }}>
        <ScrollView>
            <View marginT-20 row={getSideBarPosition == 'top'}>
                <View>
                    <TouchableOpacity
                        onPress={() => {
                            navigationFn("dashboard", {});
                        }}
                        style={{ flexDirection: 'row', alignItems: 'center', padding: 10 }}>
                        <View row centerV>
                            {Platform.OS == 'web' ? <Image
                                // source={{ uri: `https://inkapps.pages.dev/icons/3d-house.png` }}
                                assetGroup='taqwa'
                                assetName='dashboard'
                                style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} /> : <View width={15}>
                            </View>}
                            {!isSideBarCollapsed && <Text tabHeading style={{ flex: 1 }} white>
                                {Platform.OS == 'web' ? "Dashboard" : "Home"}
                            </Text>}
                        </View>
                    </TouchableOpacity>
                </View>

                {/* <View>
                    <TouchableOpacity
                        onPress={() => {
                            navigationFn("dashboard", {});
                        }}
                        style={{ flexDirection: 'row', alignItems: 'center', padding: 10 }}>
                        <View row centerV>
                            <Image source={{ uri: `https://inkapps.pages.dev/icons/3d-bell.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                            {!isSideBarCollapsed && <Text tabHeading style={{ flex: 1 }}>Notifications</Text>}
                        </View>
                    </TouchableOpacity>
                </View> */}

                {/* <ExpandableSection
                    expanded={showGYM}
                    onPress={() => {
                        setShowGYM(!showGYM);
                        setShowHRMS(false);
                        setShowAccounting(false);
                        setShowTasks(false);
                    }}
                    sectionHeader={<>
                        <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                        <View row centerV padding-10 style={showHRMS ? { backgroundColor: '#f7f7f7' } : {}}>
                            <Image source={{ uri: `https://inkapps.pages.dev/icons/3d-HR.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                            {!isSideBarCollapsed && mainHeadings("GYM Management")}
                        </View>
                    </>}
                >
                    <View row={getSideBarPosition == 'top'} style={{ marginLeft: 25, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                        {renderMenu(gymMenu)}
                    </View>
                </ExpandableSection> */}

                {Platform.OS == 'web' && renderMenu(hrmsMenu, "HRMS")}

                {Platform.OS != 'web' && renderCardData.map((item, index) => {
                    return item.component;
                })}
            </View>
        </ScrollView>

        <View row spread style={{
            position: 'relative',
            top: 10,
            marginBottom: 10
        }}>
            <View>
                <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                <TouchableOpacity
                    onPress={async () => {
                        if (Platform.OS != 'web') {
                            await AsyncStorage.removeItem('token');
                            await AsyncStorage.removeItem('globalData');
                        } else {
                            localStorage.removeItem('token');
                            localStorage.removeItem('globalData');
                        }

                        setToken(null);
                        navigationFn("login", {});
                    }}
                    style={{ flexDirection: 'row', alignItems: 'center', padding: 10 }}>
                    <View row>
                        <Image source={{ uri: `https://inkapps.pages.dev/icons/logout.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                    </View>
                </TouchableOpacity>
            </View>
            <View flex right>
                <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                <View>
                    {Platform.OS == 'web' && <TouchableOpacity
                        onPress={() => {
                            navigationFn("OrganizationSettings", {});
                        }}
                        style={{ flexDirection: 'row', alignItems: 'center', padding: 10 }}>
                        <View row centerV>
                            {!isSideBarCollapsed && <Text tabHeading white style={{ flex: 1 }}>Organization Settings</Text>}

                            <Image source={{ uri: `https://inkapps.pages.dev/icons/org_settings.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                        </View>
                    </TouchableOpacity>}
                </View>
            </View>

        </View>
    </View>);
})

const styles = StyleSheet.create({
    MenuText: {
        marginLeft: 8, fontSize: 14, letterSpacing: 0.6,
    },
    MenuHead: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 20,
        // marginRight: 15,
        marginTop: 5,
        // borderTopRightRadius: 5,
        // borderBottomRightRadius: 5,
        paddingVertical: 8,
    },
    highlightMenuItem: {
        backgroundColor: 'rgb(20, 48, 137)',
    },
    highlightMenuItemText: {
        // color: 'rgb(41, 69, 156)',
    }
}); 