// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, TextInput, ActivityIndicator } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, TouchableOpacity, KeyboardAwareScrollView } from 'react-native-ui-lib';
import DynamicForm, { CustomModal, CustomNumberInput, CustomTextField, DangerButton, Label, PrimaryButton, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { App } from "antd"
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { Icon } from "react-native-eva-icons"
import moment from "moment"
import { set } from "mobx"
import { HeaderResearch } from "./PaperListingScreen"

const isDesktop = Dimensions.get('window').width >= 768;

const PaperChatScreen = observer(function PaperChatScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const permissions = GlobalMasterStore((state) => state.permissions);
    const setPermissions = GlobalMasterStore((state) => state.setPermissions);

    const token = GlobalMasterStore((state) => state.token);
    const setToken = GlobalMasterStore((state) => state.setToken);
    const { route } = props;
    let queryParams = {};

    let params = route?.params ? route?.params : useParams();

    if (Platform.OS === 'web') {
        const fullUrl = new URL(window.location.href).search;
        const queryStringStart = fullUrl.indexOf('?');
        const queryString = fullUrl.substring(queryStringStart);

        const queries = queryString.substring(1);

        queries.split('&').forEach(pair => {
            const [key, value] = pair.split('=');
            queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
        });

        if (!params.ignoreQueryParams) {
            params = { ...params, ...queryParams }
        }
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const [search, setSearch] = useState(queryParams.search || "");
    const [password, setPassword] = useState("");
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [sendingOTP, setSendingOTP] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [forgotEmail, setForgotEmail] = useState("");
    const [forgotOTP, setForgotOTP] = useState("");
    const [forgotPassword, setForgotPassword] = useState("");
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const [loading, setLoading] = useState(false);
    const [refreshCount, setRefreshCount] = useState(0);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [sortColumn, setSortColumn] = useState("");
    const [sortDirection, setSortDirection] = useState("");
    const [paper, setPaper] = useState(null);
    const [cartModal, setCartModal] = useState(false);
    const cart = GlobalMasterStore((state) => state.cart);
    const setCart = GlobalMasterStore((state) => state.setCart);
    const [chatModal, setChatModal] = useState(false);
    const [citationModal, setCitationModal] = useState(false);
    const [citationText, setCitationText] = useState("");
    const [chatPaperId, setChatPaperId] = useState(params.id);
    // const selectedTopic = JSON.parse(localStorage.getItem('selectedTopic') || {});
    const [selectedTopic, setSelectedTopic] = useState(JSON.parse(localStorage.getItem('selectedTopic') || {}));
    const [aiThinking, setAIThinking] = useState(false);

    const onQueryParams = (params) => {
        if (params.pageIndex) {
            setPageIndex(params.pageIndex);
        }
        if (params.pageSize) {
            setPageSize(params.pageSize);
        }
        if (params.sortColumn || params.sortColumn == null) {
            setSortColumn(params.sortColumn);
        }
        if (params.sortDirection || params.sortDirection == null) {
            setSortDirection(params.sortDirection);
        }
    }

    const onCheckboxChange = (newSet) => {
        setIds([...newSet]);
    }

    const getPapersById = async () => {
        const response = await api.customRoute('Papers/action', {
            action: 'getPaper',
            id: params.id,
        });

        const paper = response.data;

        setPaper(response.data);
    }

    useEffect(() => {
        getPapersById();
    }, []);

    const removeLastSentenceIfIncomplete = (text) => {
        if (!text) return text;
        if (text.endsWith(".")) return text;

        const lastDotIndex = text.lastIndexOf(".");
        return text.substring(0, lastDotIndex + 1);
    }

    return (<View center style={{
        backgroundColor: "#fff",
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    }}>
        <Image assetGroup="images" assetName="research_bg" style={{
            position: 'absolute',
            width: Dimensions.get('window').width,
            height: Dimensions.get('window').height,
        }} />

        <SafeAreaView style={{
            width: "100%",
            height: Dimensions.get('window').height,
        }}>
            <KeyboardAwareScrollView style={{
                width: "100%",
                height: Dimensions.get('window').height,
            }}>
                <View centerH flexG padding-20
                    style={{
                        width: "100%",
                        height: Platform.OS == 'web' ? Dimensions.get('window').height : undefined,
                    }}
                >
                    <HeaderResearch navigationFn={navigationFn} />

                    <Card marginT-10 style={{
                        width: '100%',
                        height: Dimensions.get('window').height - 110,
                    }}>
                        <View row style={{
                            width: '100%',
                            height: '100%',
                        }}>
                            <View style={{
                                width: '50%',
                                height: '100%',
                                borderRightColor: '#f0f0f0',
                                borderRightWidth: 1,
                                borderRightStyle: 'solid',
                            }}>
                                <ScrollView style={{
                                    padding: 20,
                                }}>
                                    <div
                                        style={{
                                            flexDirection: "row",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Text h2>{selectedTopic.title}</Text>
                                    </div>
                                    <View style={{
                                        height: 1,
                                        backgroundColor: '#f0f0f0',
                                        width: '100%',
                                        marginVertical: 10,
                                        marginTop: 20
                                    }}></View>
                                    <div
                                        style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            width: '100%'
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                width: '100%'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: 14,
                                                    color: "#2c2c2c",
                                                    fontWeight: "normal",
                                                    textWrap: 'wrap',
                                                    letterSpacing: 0.4,
                                                    width: '100%'
                                                }}
                                            >
                                                <div style={{
                                                    fontFamily: 'SourceSansProSemiBold',
                                                    marginTop: 15
                                                }}>
                                                    Objective
                                                </div>

                                                {aiThinking == "objective" ? <View center style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                                                    <dotlottie-player src="https://lottie.host/514accb3-5931-46c9-98f2-5f3528856fa2/bWeYpvBviv.json" background="transparent" speed="1" style={{ width: 100, height: 100 }} loop autoplay></dotlottie-player>
                                                </View> : removeLastSentenceIfIncomplete(selectedTopic.objective)}

                                                <SecondaryButton
                                                    onPress={async () => {
                                                        setAIThinking("objective");
                                                        const response = await api.customRoute('Papers/action', {
                                                            action: 'generateAIResponse',
                                                            message: `Please generate objective for this topic on which I'm about to right my research paper work title will be  ${selectedTopic.title}, which has methodology of ${selectedTopic.methodology} Please give response directly like 'This is the objective of the topic' do not include anything like 'Here is the objective of the topic' I just want a direct response like 'This is the objective of the topic. It should be of 100 words without any numbers, again repeating with example, 'This is the objective of the topic'`,
                                                        });

                                                        let newObjective = response.data;

                                                        // remove "This is the objective of the topic."
                                                        if (newObjective.startsWith("This is the objective of the topic.")) {
                                                            newObjective = newObjective.replace("This is the objective of the topic.", "").trim();
                                                            newObjective = newObjective.replace("This is the objective of the topic:", "").trim();
                                                        }
                                                        const newSelectedTopic = { ...selectedTopic, objective: newObjective };
                                                        setSelectedTopic(newSelectedTopic);
                                                        localStorage.setItem('selectedTopic', JSON.stringify(newSelectedTopic));
                                                        setAIThinking(false);
                                                    }} style={{
                                                        marginTop: 10,
                                                        width: '100%'
                                                    }}
                                                    label={selectedTopic.objective ? "Regenerate Objective" : "Generate Objective"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            alignItems: "center",
                                            marginBottom: 20,
                                            width: '100%'
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                width: '100%'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: 14,
                                                    color: "#2c2c2c",
                                                    fontWeight: "normal",
                                                    textWrap: 'wrap',
                                                    letterSpacing: 0.4,
                                                    width: '100%'
                                                }}
                                            >
                                                <div style={{
                                                    fontFamily: 'SourceSansProSemiBold',
                                                    marginTop: 15
                                                }}>
                                                    Methodology
                                                </div>

                                                {aiThinking == "methodology" ? <View center style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                                                    <dotlottie-player src="https://lottie.host/514accb3-5931-46c9-98f2-5f3528856fa2/bWeYpvBviv.json" background="transparent" speed="1" style={{ width: 100, height: 100 }} loop autoplay></dotlottie-player>
                                                </View> : removeLastSentenceIfIncomplete(selectedTopic.methodology)}

                                                <SecondaryButton onPress={async () => {
                                                    setAIThinking("methodology");
                                                    const response = await api.customRoute('Papers/action', {
                                                        action: 'generateAIResponse',
                                                        message: `Please generate methodology for this topic on which I'm about to right my research paper work title will be  ${selectedTopic.title} which has objective of ${selectedTopic.objective}, Please give response directly like 'This is the methodology of the topic' do not include anything like 'Here is the methodology of the topic' I just want a direct response like 'This is the methodology of the topic. It should be of 100 words without any numbers, again repeating with example, 'This is the methodology of the topic'`,
                                                    });

                                                    let newMethodology = response.data;

                                                    // remove "This is the methodology of the topic."
                                                    if (newMethodology.startsWith("This is the methodology of the topic.")) {
                                                        newMethodology = newMethodology.replace("This is the methodology of the topic.", "").trim();
                                                        newMethodology = newMethodology.replace("This is the methodology of the topic:", "").trim();
                                                    }
                                                    const newSelectedTopic = { ...selectedTopic, methodology: newMethodology };
                                                    setSelectedTopic(newSelectedTopic);
                                                    localStorage.setItem('selectedTopic', JSON.stringify(newSelectedTopic));
                                                    setAIThinking(false);
                                                }} style={{
                                                    marginTop: 10,
                                                    width: '100%'
                                                }}
                                                    label={selectedTopic.methodology ? "Regenerate Methodology" : "Generate Methodology"}
                                                />
                                            </div>
                                        </div>
                                        {/* <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                width: '100%'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: 14,
                                                    color: "#2c2c2c",
                                                    fontWeight: "normal",
                                                    textWrap: 'wrap',
                                                    letterSpacing: 0.4,
                                                    width: '100%'
                                                }}
                                            >
                                                <div style={{
                                                    fontFamily: 'SourceSansProSemiBold',
                                                    marginTop: 15
                                                }}>
                                                    Keywords
                                                </div>

                                                {aiThinking == "keywords" ? <View center style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                                                    <dotlottie-player src="https://lottie.host/514accb3-5931-46c9-98f2-5f3528856fa2/bWeYpvBviv.json" background="transparent" speed="1" style={{ width: 100, height: 100 }} loop autoplay></dotlottie-player>
                                                </View> : selectedTopic.keywords}

                                                <SecondaryButton onPress={async () => {
                                                    setAIThinking("keywords");
                                                    const response = await api.customRoute('Papers/action', {
                                                        action: 'generateAIResponse',
                                                        message: `Please generate keywords for this topic on which I'm about to right my research paper work title will be  ${selectedTopic.title} which has objective of ${selectedTopic.objective} and methodology of ${selectedTopic.methodology}, Please give response directly like KEYWORD1, KEYWORD2, KEYWORD3, etc do not include anything like 'Here are some keywords' I just want a direct response like 'KEYWORD1, KEYWORD2, KEYWORD3' it should be comma separated without any numbers, again repeating with example, KEYWORD1, KEYWORD2, KEYWORD3`,
                                                    });

                                                    const newKeywords = response.data;
                                                    const newSelectedTopic = { ...selectedTopic, keywords: newKeywords };
                                                    setSelectedTopic(newSelectedTopic);
                                                    localStorage.setItem('selectedTopic', JSON.stringify(newSelectedTopic));
                                                    setAIThinking(false);
                                                }} style={{
                                                    marginTop: 10,
                                                    width: '100%'
                                                }}
                                                    label={selectedTopic.keywords ? "Regenerate Keywords" : "Generate Keywords"}
                                                />
                                            </div>
                                        </div> */}
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                width: '100%'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: 14,
                                                    color: "#2c2c2c",
                                                    fontWeight: "normal",
                                                    textWrap: 'wrap',
                                                    letterSpacing: 0.4,
                                                    width: '100%'
                                                }}
                                            >
                                                <div style={{
                                                    fontFamily: 'SourceSansProSemiBold',
                                                    marginTop: 15
                                                }}>
                                                    Introduction
                                                </div>

                                                {aiThinking == "introduction" ? <View center style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                                                    <dotlottie-player src="https://lottie.host/514accb3-5931-46c9-98f2-5f3528856fa2/bWeYpvBviv.json" background="transparent" speed="1" style={{ width: 100, height: 100 }} loop autoplay></dotlottie-player>
                                                </View> : removeLastSentenceIfIncomplete(selectedTopic.introduction)}

                                                <SecondaryButton onPress={async () => {
                                                    setAIThinking("introduction");
                                                    const response = await api.customRoute('Papers/action', {
                                                        action: 'generateAIResponse',
                                                        message: `Please generate introduction for this topic on which I'm about to right my research paper work title will be  ${selectedTopic.title} which has objective of ${selectedTopic.objective} and methodology of ${selectedTopic.methodology}, Please give response directly like 'This is the introduction of the topic' do not include anything like 'Here is the introduction of the topic' I just want a direct response like 'This is the introduction of the topic. It should be of 100 words without any numbers, again repeating with example, 'This is the introduction of the topic'`,
                                                    });

                                                    let newIntroduction = response.data;

                                                    // remove "This is the introduction of the topic."
                                                    if (newIntroduction.startsWith("This is the introduction of the topic.")) {
                                                        newIntroduction = newIntroduction.replace("This is the introduction of the topic.", "").trim();
                                                        newIntroduction = newIntroduction.replace("This is the introduction of the topic:", "").trim();
                                                    }
                                                    const newSelectedTopic = { ...selectedTopic, introduction: newIntroduction };
                                                    setSelectedTopic(newSelectedTopic);
                                                    localStorage.setItem('selectedTopic', JSON.stringify(newSelectedTopic));
                                                    setAIThinking(false);
                                                }} style={{
                                                    marginTop: 10,
                                                    width: '100%'
                                                }}
                                                    label={selectedTopic.introduction ? "Regenerate Introduction" : "Generate Introduction"}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: 14,
                                                    color: "#2c2c2c",
                                                    fontWeight: "normal",
                                                    textWrap: 'wrap',
                                                    letterSpacing: 0.4
                                                }}
                                            >
                                                <div style={{
                                                    fontFamily: 'SourceSansProSemiBold',
                                                    marginTop: 15
                                                }}>
                                                    Research references
                                                </div>

                                                {cart && cart.length > 0 && cart.map((paper, i) =>
                                                    <View key={i} style={{
                                                        width: "100%",
                                                    }}>
                                                        <Text style={{
                                                            fontSize: 14,
                                                            color: '#2c2c2c',
                                                            textWrap: 'wrap',
                                                            marginBottom: 5,
                                                        }}>
                                                            {i + 1}. {paper.citation}
                                                        </Text>
                                                    </View>)}
                                            </div>
                                        </div>
                                    </div>
                                </ScrollView>
                            </View>

                            <View style={{
                                width: '50%',
                                height: '100%',
                            }}>
                                <ChatComponent selectedTopic={selectedTopic} />
                            </View>
                        </View>
                    </Card>

                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>

        <CustomModal
            visible={citationModal}
            onClose={() => {
                setCitationModal(false);
            }}
            title="Citation"
            width={isDesktop ? 500 : "100%"}
            drawer={true}
        >
            <View padding-20>
                <Text>{citationText}</Text>
                <SecondaryButton style={{
                    marginTop: 10,
                }} onPress={() => {
                    navigator.clipboard.writeText(citationText);
                    message.success("Citation copied to clipboard!");
                }}>
                    <View row centerV>
                        <Icon name="copy-outline" width={16} height={16} fill="#20303C" />
                        <Text marginL-10>Copy</Text>
                    </View>
                </SecondaryButton>
            </View>
        </CustomModal>

        <CustomModal
            visible={cartModal}
            onClose={() => {
                setCartModal(false);
            }}
            title="Saved Papers"
            width={isDesktop ? 500 : "100%"}
            drawer={true}
        >
            <View padding-20>
                <CartComponent cart={cart} setCart={setCart} />
            </View>
        </CustomModal>

        <CustomModal
            visible={chatModal}
            onClose={() => {
                setChatModal(false);
            }}
            title="Chat"
            width={isDesktop ? 500 : "100%"}
            drawer={true}
        >
            <View padding-20>
                <ChatComponent id={chatPaperId} />
            </View>
        </CustomModal>
    </View >)
})

export default PaperChatScreen

const $container = {
    flex: 1,
}

export const CartComponent = ({ cart, setCart }) => {
    if (!cart || cart.length == 0) return <View style={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    }}>
        <Text style={{
            fontSize: 14,
            color: '#000',
        }}>No papers saved!</Text>
    </View>;

    return <>
        {cart && cart.length > 0 && cart.map((paper, i) => <View marginB-10 key={i} style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
        }}>
            <View style={{
                // flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                width: "100%"
            }}>
                <View style={{
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                }}>
                    <Text style={{
                        fontSize: 14,
                        fontFamily: 'SourceSansProSemiBold',
                        color: '#414223',
                        textWrap: 'wrap',
                    }}>{paper.title}</Text>

                    <Text marginB-10>Future Scope Of Work 1</Text>

                    {/* <Text>{JSON.stringify(paper.future_scope_of_works)}</Text> */}

                    {paper.future_scope_of_works && paper.future_scope_of_works.length > 0 && paper.future_scope_of_works.map((scope, i) => <Text key={i} style={{
                        fontSize: 14,
                        color: '#8b959c',
                        textWrap: 'wrap',
                        marginBottom: 5,
                    }}>
                        {i + 1}. {scope}</Text>)}

                    <View row>
                        <SecondaryButton style={{
                            marginTop: 10,
                            marginRight: 10,
                        }} onPress={() => {
                            // setCart(cart.filter(item => item._id != paper._id));
                            navigationFn("research/paper", {
                                id: paper._id,
                            });
                        }}>
                            <View row centerV>
                                <Icon name="file-text-outline" width={16} height={16} fill="#20303C" />
                                <Text color="#20303C" marginL-10>Select Paper</Text>
                            </View>
                        </SecondaryButton>
                        <SecondaryButton style={{
                            marginTop: 10,
                        }} onPress={() => {
                            setCart(cart.filter(item => item._id != paper._id));
                        }}>
                            <View row centerV>
                                <Icon name="shopping-bag-outline" width={16} height={16} fill="#b60505" />
                                <Text color="#b60505" marginL-10>Remove</Text>
                            </View>
                        </SecondaryButton>
                    </View>


                </View>
            </View>

        </View>)}
    </>
}

const ChatComponent = ({ selectedTopic }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [aiThinking, setAIThinking] = useState(false);

    const getAIResponse = async (message) => {
        setAIThinking(true);
        const response = await api.customRoute('Papers/action', {
            action: 'getAIResponseForTopic',
            message: message,
            selectedTopic: selectedTopic,
        });

        const messageData = {
            id: Date.now(),
            sender: 'AI',
            message: response.data,
            type: 'text',
            timestamp: new Date().toISOString(),
        };

        setAIThinking(false);
        setMessages((prevMessages) => [messageData, ...prevMessages]);
    }

    const sendMessage = async (text) => {
        if (newMessage.trim() || text) {
            const messageData = {
                id: Date.now(),
                sender: 'John', // Replace with the actual sender
                message: text ? text : newMessage,
                type: 'text',
                timestamp: new Date().toISOString(),
            };
            setMessages((prevMessages) => [messageData, ...prevMessages]);
            setNewMessage('');
            getAIResponse(newMessage || text);
        }
    };

    const sendAttachment = async (type) => {
        try {
            let result;
            if (type === 'image') {
                result = await ImagePicker.launchImageLibraryAsync({
                    mediaTypes: ImagePicker.MediaTypeOptions.Images,
                    allowsEditing: true,
                    aspect: [4, 3],
                    quality: 1,
                });
            } else if (type === 'document') {
                result = await DocumentPicker.getDocumentAsync({
                    type: '*/*',
                    copyToCacheDirectory: true,
                });
            } else if (type === 'audio') {
                result = await AudioPicker.getAudioAsync();
            }

            if (!result.cancelled) {
                const messageData = {
                    id: Date.now(),
                    sender: 'John', // Replace with the actual sender
                    message: result.uri,
                    type,
                    timestamp: new Date().toISOString(),
                };
                setMessages((prevMessages) => [...prevMessages, messageData]);
            }
        } catch (error) {
            console.error('Error sending attachment:', error);
        }
    };

    const renderMessage = ({ item }) => {
        const isMyMessage = item.sender === 'John';

        return (
            <View style={{ flexDirection: isMyMessage ? 'row-reverse' : 'row', marginVertical: 8 }}>
                <View
                    style={{
                        backgroundColor: isMyMessage ? '#DCF8C6' : '#E8E8E8',
                        borderRadius: 8,
                        padding: 8,
                        maxWidth: '70%',
                    }}
                >
                    {item.type === 'text' && <Text>{item.message}</Text>}
                    {item.type === 'image' && <Image source={{ uri: item.message }} style={{ width: 200, height: 200 }} />}
                    {item.type === 'document' && <Text>Document: {item.message}</Text>}
                    {item.type === 'audio' && <Text>Audio: {item.message}</Text>}
                    <Text style={{ fontSize: 10, color: 'gray', marginTop: 4 }}>
                        {moment(item.timestamp).format('h:mm a')}
                    </Text>
                </View>
            </View>
        );
    };

    useEffect(() => {
        if (!selectedTopic) return;
        const chatHistory = JSON.parse(localStorage.getItem('chatHistory_' + selectedTopic.title)) || [];
        setMessages(chatHistory);
    }, [selectedTopic.title]);

    useEffect(() => {
        if (!selectedTopic) return;
        localStorage.setItem('chatHistory_' + selectedTopic.title, JSON.stringify(messages));
    }, [messages]);


    return (
        <View style={{ padding: 16, height: Dimensions.get('window').height - 120 }}>
            {messages.length != 0 && <FlatList
                style={{
                    flex: 1,
                }}
                data={messages}
                renderItem={renderMessage}
                keyExtractor={(item) => item.id.toString()}
                inverted
            />}

            {messages.length == 0 && <View center style={{
                flex: 1
            }}>
                <Image source={{ uri: "https://img.freepik.com/free-vector/floating-robot_78370-3669.jpg?w=740&t=st=1711256081~exp=1711256681~hmac=2ea19b1030eae67a8f9e4778b86e184d2f7338eec087e8d4f9c5d077f9b3ca9f" }} style={{
                    width: "100%",
                    height: 300,
                    resizeMode: 'contain',
                }} />
            </View>}

            {aiThinking && <View center style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                <dotlottie-player src="https://lottie.host/514accb3-5931-46c9-98f2-5f3528856fa2/bWeYpvBviv.json" background="transparent" speed="1" style={{ width: 100, height: 100 }} loop autoplay></dotlottie-player>
            </View>}

            {messages.length == 0 && <>
                <TouchableOpacity
                    style={{
                        backgroundColor: '#DCF8C6',
                        padding: 12,
                        borderRadius: 8,
                        marginBottom: 8,
                    }}
                    onPress={() => {
                        sendMessage('How should I start my research paper?');
                    }}
                >
                    <Text>How should I start my research paper?</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={{
                        backgroundColor: '#DCF8C6',
                        padding: 12,
                        borderRadius: 8,
                        marginBottom: 8,
                    }}
                    onPress={() => {
                        sendMessage("Please suggest me some headings for this research paper.");
                    }}
                >
                    <Text>Please suggest me some headings for this research paper.</Text>
                </TouchableOpacity>
            </>}

            <View marginT-20 style={{ flexDirection: 'row', alignItems: 'center' }}>

                <CustomTextField
                    value={newMessage}
                    placeholder="Type a message..."
                    onChange={(value) => {
                        setNewMessage(value);
                    }}
                    suffixComponent={<PrimaryButton onPress={sendMessage} style={{ height: 38, marginLeft: 10 }}>
                        <View row>
                            <Icon name="paper-plane-outline" width={16} height={16} fill="white" />
                        </View>
                    </PrimaryButton>}
                />
            </View>
        </View>
    );
};