import React, { useEffect, useState } from "react";
import { View, Card, Text, Button, TouchableOpacity, Image } from "react-native-ui-lib";
import { Col, CustomCheckbox, CustomDatePicker, CustomNumberInput, CustomSelect, CustomTextField, CustomUpload, DangerButton, Label, PrimaryButton, Row, SecondaryButton } from "../../../mycomponents/DynamicForm";
import { Alert, Platform, Touchable } from "react-native";
import { addButton } from "../../hrms/shiftModel";
import { Icon } from "react-native-eva-icons";
import { RND } from "../../accountancy/transaction";
import ReportScreenSingle from "../../../screens/MainScreens/CRUD/ReportScreenSingle";
import ListingScreen from "../../../screens/MainScreens/CRUD/ListingScreen";
import CalendarStrip from 'react-native-calendar-strip';
import FormScreen from "../../../screens/MainScreens/CRUD/FormScreen";
import { api } from "../../../services/api";
import moment from "moment";
import { Image as WebImageView, message } from 'antd';
import { DailyActivityCard, ErrorMessage } from "../../../screens/MainScreens/Custom/Internal_Pages/taqwa/DailyActivityScreen";
import { SalesActivityCard } from "../../../screens/MainScreens/Custom/Internal_Pages/taqwa/SalesActivityScreen";
import { ScrollView } from "react-native-gesture-handler";

export const employeefields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const MembershipPaymentsComponent = ({ field, useForm, tKey, tIndex }) => {
        const employee_id = useForm((state) => state.formObject['_id']);

        const props = {
            moduleName: 'MembershipPaymentFees',
            hideWrapper: true,
            extraFilters: { employee_id: employee_id ?? null },
            hideViews: true,
        }

        return <View w-100 flex>
            <ListingScreen {...props} title={" "} default_values={{
                employee_id: employee_id,
                is_default_values: true
            }} />
        </View>
    };

    const DailyActivityComponent = ({ field, useForm, tKey, tIndex }) => {
        const employee_id = useForm((state) => state.formObject['_id']);
        const [date, setDate] = useState(new Date());
        const [viewOnly, setViewOnly] = useState(true);
        const [isEdit, setIsEdit] = useState(false);
        const [id, setId] = useState(null);
        const [data, setData] = useState(null);

        useEffect(() => {
            console.log('employee_id', employee_id);
            console.log('date', date);
            const getData = async () => {
                const responseData = await api.get("DailyActivities", {
                    page: 1,
                    limit: 1,
                    sortColumn: null,
                    sortDirection: null,
                    filters: [],
                    tabFilter: { employee_id: employee_id, date: { $gte: moment(date).startOf('day').toDate(), $lte: moment(date).endOf('day').toDate() } },
                    search: null,
                    groupBy: null
                });

                const data = responseData?.data?.data?.[0] ?? null;

                if (data) {
                    setData(data);
                    setId(data._id);
                } else {
                    setId(null);
                }
            };
            getData();
        }, [date]);

        return <View w-100 flex>
            <ScrollView>
                <View row spread>
                    <CustomDatePicker
                        value={date}
                        onChange={(value) => {
                            setDate(value);
                        }}
                        placeholder="Select Date"
                        label="Select Date"
                    />
                </View>

                {id ? <View marginT-10>
                    <DailyActivityCard data={data} id={id} OpenModalCustom={OpenModalCustom} />
                </View> : <View marginT-50 center>
                    <ErrorMessage />
                    <Text marginT-20 center>There is no activity for this date</Text>
                    <Text marginT-10 center>Click on the button below to add an activity</Text>

                    <View marginT-20 center>
                        <PrimaryButton
                            label="Add Activity"
                            onPress={() => {
                                OpenModalCustom({
                                    newModule: "DailyActivities",
                                    field_key: 'daily_activities',
                                    array: true,
                                    params: {
                                        employee_id: employee_id,
                                        date: date,
                                        is_default_values: true
                                    }
                                })
                            }}
                        />
                    </View>
                </View>}
                <View marginB-100></View>
            </ScrollView>
        </View>
    };

    const SalesActivityComponent = ({ field, useForm, tKey, tIndex }) => {
        const employee_id = useForm((state) => state.formObject['_id']);
        const [date, setDate] = useState(new Date());
        const [viewOnly, setViewOnly] = useState(true);
        const [isEdit, setIsEdit] = useState(false);
        const [id, setId] = useState(null);
        const [data, setData] = useState(null);

        useEffect(() => {
            const getData = async () => {
                const responseData = await api.get("SalesActivities", {
                    page: 1,
                    limit: 1,
                    sortColumn: null,
                    sortDirection: null,
                    filters: [],
                    tabFilter: { employee_id: employee_id, date: { $gte: moment(date).startOf('month').toDate(), $lte: moment(date).endOf('month').toDate() } },
                    search: null,
                    groupBy: null
                });

                const data = responseData?.data?.data?.[0] ?? null;

                if (data) {
                    setData(data);
                    setId(data._id);
                } else {
                    setId(null);
                }
            };
            getData();
        }, [date]);

        return <View w-100 flex>
            <View row spread>
                <CustomDatePicker
                    pickerType="month"
                    value={date}
                    onChange={(value) => {
                        setDate(value);
                    }}
                    placeholder="Select Date"
                    label="Select Date"
                />
            </View>

            {id ? <View marginT-10>
                <SalesActivityCard data={data} id={id} OpenModalCustom={OpenModalCustom} />
            </View> : <View marginT-50 center>
                <ErrorMessage />
                <Text marginT-20 center>There is no activity for this date</Text>
                <Text marginT-10 center>Click on the button below to add an activity</Text>

                <View marginT-20 center>
                    <PrimaryButton
                        label="Add Activity"
                        onPress={() => {
                            OpenModalCustom({
                                newModule: "SalesActivities",
                                field_key: 'daily_activities',
                                array: true,
                                params: {
                                    employee_id: employee_id,
                                    date: date,
                                    is_default_values: true
                                }
                            })
                        }}
                    />
                </View>
            </View>}
        </View>
    };

    const AttendanceComponent = ({ field, useForm, tKey, tIndex }) => {
        const employee_id = useForm((state) => state.formObject['_id']);
        const [dates, setDates] = useState([
            moment().startOf('week').toDate(),
            moment().endOf('week').toDate(),
        ]);
        const [attendanceType, setAttendanceType] = useState('Course');
        const [updateKey, setUpdateKey] = useState(null);

        useEffect(() => {
            setUpdateKey(Math.random());
        }, [dates, attendanceType]);

        return <View w-100 flex>
            <View row spread>
                <View style={{
                    width: 150,
                }}>
                    <CustomSelect
                        value={attendanceType}
                        onChange={(value) => {
                            setAttendanceType(value);
                        }}
                        placeholder="Select Attendance Type"
                        label="Select Attendance Type"
                        options={[
                            { label: 'Course', value: 'Course' },
                            { label: 'Meeting', value: 'Meeting' },
                        ]}
                    />
                </View>

                <View>
                    <CustomDatePicker
                        value={dates}
                        onChange={(value) => {
                            setDates(value);
                        }}
                        placeholder="Select Date"
                        label="Select Date"
                        range={true}
                    />
                </View>
            </View>
            {updateKey != null && <>{attendanceType == 'Meeting' ? <ListingScreen
                key={updateKey}
                {...{
                    moduleName: 'MeetingAttendances',
                    hideWrapper: true,
                    extraFilters: {
                        'employee_id': employee_id ?? null,
                        'meeting.date': {
                            $gte: dates[0],
                            $lte: dates[1],
                        }
                    },
                    hideViews: true,
                    hideMainHeader: true,
                }}
                attendanceType={attendanceType}
                title={" "}
                hiddenColumns={["employee.name", "employee.name"]}
                disabledClone={true}
                disableDelete={true}
                default_values={{
                    employee_id: employee_id,
                    is_default_values: true
                }} /> :
                <ListingScreen
                    key={updateKey}
                    {...{
                        moduleName: 'CourseSessionAttendances',
                        hideWrapper: true,
                        extraFilters: {
                            'employee_id': employee_id ?? null,
                            'course_session.date': {
                                $gte: dates[0],
                                $lte: dates[1],
                            }
                        },
                        hideViews: true,
                        hideMainHeader: true,
                    }}
                    attendanceType={attendanceType}
                    title={" "}
                    disabledClone={true}
                    disableDelete={true}
                    default_values={{
                        employee_id: employee_id,
                        is_default_values: true
                    }} />}
            </>}
        </View>
    };

    const CoursesEnrolComponent = ({ field, useForm, tKey, tIndex }) => {
        const employee_id = useForm((state) => state.formObject['_id']);

        const props = {
            moduleName: 'Courses',
            hideWrapper: true,
            extraFilters: {
                registered_employee_ids: {
                    $in: [employee_id]
                }
            },
            hideViews: true,
            hideMainHeader: true,
        }

        return <View w-100 flex>
            <ListingScreen {...props} title={" "} default_values={{
                employee_id: employee_id,
                is_default_values: true
            }} />
        </View>
    };

    const GoalsComponent = ({ field, useForm, tKey, tIndex }) => {
        const employee_id = useForm((state) => state.formObject['_id']);

        const props = {
            moduleName: 'Goals',
            hideWrapper: true,
            extraFilters: {
                employee_id: employee_id,
            },
            hideViews: true,
            //hideMainHeader: true,
        }

        return <View w-100 flex>
            <ListingScreen {...props} title={" "} default_values={{
                employee_id: employee_id,
                is_default_values: true
            }} />
        </View>
    };

    const KPIComponent = ({ field, useForm, tKey, tIndex }) => {
        const employee_id = useForm((state) => state.formObject['_id']);

        return <View w-100 flex>
            <ReportScreenSingle
                moduleName={"EmployeeKPIDetails"}
                hideWrapper={true}
                noMono={true}
                hideHeader={true}
                params={{
                    quickFilterKey: 'employee_id',
                    quickFilterValue: employee_id,
                    hideQuickFilter: true,
                }}
                route={{
                    params: {
                        quickFilterKey: 'employee_id',
                        quickFilterValue: employee_id,
                        hideQuickFilter: true,
                        name: "EmployeeKPIDetails",
                    }
                }}
            />
        </View>
    };

    const addressColumns = [
        {
            "field": "postal_code",
            "label": "Postal Code",
            "editable": true,
            "type": "text",
            "visible": true,
            "order": 1
        },
        {
            "field": "block_no",
            "label": "Block No",
            "editable": true,
            "type": "text",
            "visible": true,
            "order": 2
        }, {
            "field": "street_no",
            "label": "Street No",
            "editable": true,
            "type": "text",
            "visible": true,
            "order": 3
        }, {
            "field": "unit_no",
            "label": "Unit No",
            "editable": true,
            "type": "text",
            "visible": true,
            "order": 4
        }, {
            "field": "type_of_accomodation",
            "label": "Type of Accommodation",
            "editable": true,
            "type": "autocomplete",
            "options": [
                { label: "Apartment", value: "Apartment" },
                { label: "Dormitory", value: "Dormitory" },
                { label: "House", value: "House" },
                { label: "HDB", value: "HDB" },
            ],
            "visible": true,
            "order": 5
        }
    ];

    addressColumns && addressColumns.map((column) => {
        column.Header = column.label;
        column.accessor = column.field;
    });

    const emergencyContactColumns = [
        {
            "field": "contact_name",
            "label": "Contact Name",
            "editable": true,
            "type": "text",
            "visible": true,
            "order": 1
        },
        {
            "field": "contact_number",
            "label": "Contact Number",
            "editable": true,
            "type": "text",
            "visible": true,
            "order": 2
        }, {
            "field": "email_id",
            "label": "Email ID",
            "editable": true,
            "type": "text",
            "visible": true,
            "order": 3
        }, {
            "field": "relation",
            "label": "Relation",
            "editable": true,
            "type": "autocomplete",
            "options": [
                { label: "Father", value: "Father" },
                { label: "Mother", value: "Mother" },
                { label: "Spouse", value: "Spouse" },
                { label: "Sibling", value: "Sibling" },
                { label: "Child", value: "Child" },
            ],
            "visible": true,
            "order": 4
        }
    ];

    emergencyContactColumns && emergencyContactColumns.map((column) => {
        column.Header = column.label;
        column.accessor = column.field;
    });

    const formFields = [
        {
            key: 'image_custom',
            type: 'custom',
            visible: view ? false : true,
            width: '100%',
            component: ({ field, useForm, tKey, tIndex }) => {
                const image = useForm((state) => state.formObject['image']);
                const face_auth_image_url = useForm((state) => state.formObject['face_auth_image_url']);
                const setFormObject = useForm((state) => state.setFormObject);

                return <View row>
                    <View
                        left
                        style={{
                            width: 120,
                        }}>
                        {Label({ label: 'Profile Picture' })}
                        <CustomUpload
                            value={image}
                            onChange={(value) => {
                                setFormObject({
                                    image: value,
                                });
                            }}
                            placeholder="Select Image"
                            label="Select Image"
                            moduleName={moduleName}
                            width="100%"
                            mimeType="image/*"
                        />
                    </View>

                    {/* <View
                        left
                        style={{
                            width: 120,
                        }}>
                        {Label({ label: 'Face Auth Image' })}
                        <CustomUpload
                            value={face_auth_image_url}
                            onChange={(value) => {
                                setFormObject({
                                    face_auth_image_url: value,
                                });
                            }}
                            placeholder="Select Face Auth Image"
                            label="Select Face Auth Image"
                            moduleName={moduleName}
                            width="100%"
                            mimeType="image/*"
                        />
                    </View> */}
                </View>
            },
            tab: 'Personal Details'
        },
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter name',
            visible: true,
            width: '100%',
            tab: 'Personal Details'
        },
        {
            label: 'Nickname',
            key: 'nickname',
            type: 'text',
            placeholder: 'Enter name',
            visible: true,
            width: '100%',
            tab: 'Personal Details'
        },
        {
            label: 'Email',
            key: 'email',
            type: 'text',
            placeholder: 'Enter email',
            visible: true,
            width: '100%',
            tab: 'Personal Details'
        },
        {
            label: 'Contact Number',
            key: 'contact_number',
            type: 'text',
            placeholder: 'Enter contact number',
            visible: true,
            width: '100%',
            tab: 'Personal Details',
            showCountryCode: true
        },

        {
            label: 'Gender',
            key: 'gender',
            type: 'select',
            placeholder: 'Select gender',
            visible: true,
            width: '100%',
            options: [
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
            ],
            tab: 'Personal Details'
        },

        {
            label: 'Date of Birth',
            key: 'date_of_birth',
            type: 'date',
            placeholder: 'Enter date of birth',
            visible: true,
            width: '100%',
            tab: 'Personal Details',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                // update age 
                setObj({
                    age: Math.floor((new Date() - new Date(value)) / 1000 / 60 / 60 / 24 / 365),
                    updateVisiblity: Math.random()
                })
            }
        },
        {
            label: 'Age',
            key: 'age',
            type: 'number',
            placeholder: 'Enter Age',
            visible: true,
            width: '100%',
            moduleName: moduleName,
            tab: 'Personal Details',
            value: value?.date_of_birth ? Math.floor((new Date() - new Date(value?.date_of_birth)) / 1000 / 60 / 60 / 24 / 365) : null,
        },

        {
            label: 'Residency Status',
            key: 'residency_status',
            type: 'select',
            placeholder: 'Select residency status',
            visible: true,
            width: '100%',
            tab: 'Personal Details',
            options: [
                { label: 'Citizen', value: 'Citizen' },
                { label: 'Permanent Resident', value: 'Permanent Resident' },
                { label: 'Foreigner', value: 'Foreigner' },
            ],
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                setObj({
                    updateVisiblity: Math.random(),
                })
            }
        },

        {
            label: 'NRIC No',
            key: 'national_id',
            type: 'text',
            placeholder: 'Enter national ID',
            visible: (obj) => obj.residency_status == 'Citizen' || obj.residency_status == 'Permanent Resident',
            width: '100%',
            tab: 'Personal Details'
        },

        {
            label: 'FIN No',
            key: 'national_id',
            type: 'text',
            placeholder: 'Enter national ID',
            visible: (obj) => obj.residency_status == 'Foreigner',
            width: '100%',
            tab: 'Personal Details'
        },

        {
            label: 'Race',
            key: 'race',
            type: 'autocomplete',
            placeholder: 'Enter race',
            visible: true,
            width: '100%',
            tab: 'Personal Details',
            options: [
                { label: 'Chinese', value: 'Chinese' },
                { label: 'Malay', value: 'Malay' },
                { label: 'Indian', value: 'Indian' },
                { label: 'Myanmarese', value: 'Myanmarese' },
                { label: 'Burmese', value: 'Burmese' },
                { label: 'Filipino', value: 'Filipino' },
                { label: 'Eurasian', value: 'Eurasian' },
            ],
        },
        {
            label: 'Religion',
            key: 'religion',
            type: 'autocomplete',
            placeholder: 'Enter religion',
            visible: true,
            width: '100%',
            tab: 'Personal Details',
            options: [
                { label: 'Buddhism', value: 'Buddhism' },
                { label: 'Christianity', value: 'Christianity' },
                { label: 'Hinduism', value: 'Hinduism' },
                { label: 'Islam', value: 'Islam' },
                { label: 'Sikhism', value: 'Sikhism' },
            ],
        },

        {
            label: 'Marital Status',
            key: 'marital_status',
            type: 'select',
            placeholder: 'Select marital status',
            visible: true,
            width: '100%',
            tab: 'Personal Details',
            options: [
                { label: 'Single', value: 'Single' },
                { label: 'Married', value: 'Married' },
                { label: 'Widowed', value: 'Widowed' },
                { label: 'Separated', value: 'Separated' },
                { label: 'Divorced', value: 'Divorced' },
            ],
        },

        {
            label: '',
            key: 'addresses',
            type: 'divider',
            heading: 'Address',
            visible: true,
            width: '100%',
            tab: 'Personal Details',
        },

        {
            label: 'Addresses',
            key: 'addresses',
            type: 'table',
            visible: true,
            width: '100%',
            tab: 'Personal Details',
            editable: true,
            columns: addressColumns,
            actions: view ? false : true,
            actionFunctions: (row) => ([{
                label: 'Delete',
                key: 'delete',
                fn: ({ data, updateData, row, row_index }) => {
                    let newData = [...data];
                    newData.splice(row_index, 1);
                    updateData(newData);
                }
            }]),
            value: []
        },

        addButton("Add Address", "add_address", "addresses", {}, "Personal Details", view ? false : true),

        {
            label: 'Emergency Contacts',
            key: 'emergency_contacts',
            type: 'table',
            visible: true,
            width: '100%',
            tab: 'Personal Details',
            editable: true,
            columns: emergencyContactColumns,
            actions: view ? false : true,
            actionFunctions: (row) => ([{
                label: 'Delete',
                key: 'delete',
                fn: ({ data, updateData, row, row_index }) => {
                    let newData = [...data];
                    newData.splice(row_index, 1);
                    updateData(newData);
                }
            }]),
            value: []
        },

        addButton("Add Emergency Contact", "add_emergency_contact", "emergency_contacts", {}, "Personal Details", view ? false : true),

        {
            label: 'Role',
            key: 'role_id',
            type: 'select',
            placeholder: 'Enter roles',
            // visible: (obj) => {
            //     const role = response.Roles.find((item) => item._id == obj.role_id);
            //     return role?.name?.toLowerCase() == 'Admin';
            // },
            visible: true,
            width: '100%',
            tab: 'Employment Details',
            options: response.Roles || [],
            // creatableAction: (props) => {
            //     const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };

            //     OpenModalCustom({ newModule: "Roles", field_key: 'role_ids', array: true, params })
            // },
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                setObj({
                    updateVisiblity: Math.random(),
                })
            },
            value: response.fsc_role_id
        },

        {
            label: 'Contract Start Date',
            key: 'joining_date',
            type: 'date',
            placeholder: 'Enter contract start date',
            visible: (obj) => {
                const role = response.Roles.find((item) => item._id == obj.role_id);
                return role?.name?.toLowerCase() == 'fsc' || role?.name?.toLowerCase() == 'group leader' || role?.name?.toLowerCase() == 'director';
            },
            width: '100%',
            tab: 'Employment Details',
            value: new Date()
        },

        {
            label: 'Contract End Date',
            key: 'last_working_date',
            type: 'date',
            placeholder: 'Enter contract end date',
            visible: (obj) => {
                const role = response.Roles.find((item) => item._id == obj.role_id);
                return role?.name?.toLowerCase() == 'fsc' || role?.name?.toLowerCase() == 'group leader' || role?.name?.toLowerCase() == 'director';
            },
            width: '100%',
            tab: 'Employment Details',
            value: null,
        },

        // {
        //     label: 'Group Leader',
        //     key: 'group_leader_id',
        //     type: 'select',
        //     placeholder: 'Select group leader',
        //     visible: (obj) => {
        //         const role = response.Roles.find((item) => item._id == obj.role_id);
        //         return role?.name?.toLowerCase() == 'fsc';
        //     },
        //     width: '100%',
        //     options: (response.Employees || []).map((item) => {
        //         return { label: item.name, value: item._id }
        //     }),
        //     tab: 'Employment Details'
        // },

        {
            label: 'Group',
            key: 'group_id',
            type: 'select',
            placeholder: 'Select group',
            visible: (obj) => {
                const role = response.Roles.find((item) => item._id == obj.role_id);
                return role?.name?.toLowerCase() == 'fsc';
            },
            width: '100%',
            options: (response.Groups || []).map((item) => {
                return { label: item.name, value: item._id }
            }),
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };

                OpenModalCustom({ newModule: "Groups", field_key: 'group_id', params })
            },
            tab: 'Employment Details'
        },

        {
            label: 'FSC Type',
            key: 'fsc_type',
            type: 'select',
            placeholder: 'Select FSC type',
            visible: (obj) => {
                const role = response.Roles.find((item) => item._id == obj.role_id);
                return role?.name?.toLowerCase() == 'fsc' || role?.name?.toLowerCase() == 'group leader' || role?.name?.toLowerCase() == 'director';
            },
            width: '100%',
            tab: 'Employment Details',
            options: [
                { label: 'Junior (PFT)', value: 'Junior (PFT)' },
                { label: 'Executive (CFT)', value: 'Executive (CFT)' },
                { label: 'Senior (Group Leader)', value: 'Senior (Group Leader)' },
            ],
        },

        {
            label: 'Turnstile Access Number',
            key: 'turnstile_access_number',
            type: 'text',
            placeholder: 'Enter turnstile access number',
            visible: (obj) => {
                const role = response.Roles.find((item) => item._id == obj.role_id);
                return role?.name?.toLowerCase() == 'fsc' || role?.name?.toLowerCase() == 'group leader' || role?.name?.toLowerCase() == 'director';
            },
            width: '100%',
            tab: 'Employment Details',
        },

        {
            label: 'MAS Representative Number',
            key: 'mas_representative_number',
            type: 'text',
            placeholder: 'Enter MAS representative number',
            visible: (obj) => {
                const role = response.Roles.find((item) => item._id == obj.role_id);
                return role?.name?.toLowerCase() == 'fsc' || role?.name?.toLowerCase() == 'group leader' || role?.name?.toLowerCase() == 'director';
            },
            width: '100%',
            tab: 'Employment Details',
        },

        {
            label: 'Membership Status',
            key: 'membership_status',
            type: 'select',
            placeholder: 'Select membership status',
            visible: (obj) => {
                const role = response.Roles.find((item) => item._id == obj.role_id);
                return role?.name?.toLowerCase() == 'fsc' || role?.name?.toLowerCase() == 'group leader' || role?.name?.toLowerCase() == 'director';
            },
            width: '100%',
            tab: 'Employment Details',
            options: [
                { label: 'Paid', value: 'Paid' },
                { label: 'Unpaid', value: 'Unpaid' },
            ],
            value: 'Paid'
        },

        {
            label: 'Account Status',
            key: 'account_status',
            type: 'select',
            placeholder: 'Select account status',
            visible: true,
            width: '100%',
            tab: 'Employment Details',
            options: [
                { label: 'Active', value: 'Active' },
                { label: 'Inactive', value: 'Inactive' },
            ],
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                if (value == 'Inactive') {
                    setObj({
                        last_working_date: new Date(),
                        updateVisiblity: Math.random(),
                    })
                } else {
                    setObj({
                        last_working_date: null,
                        updateVisiblity: Math.random(),
                    })
                }
            },
            value: 'Active'
        },

        {
            label: "Is Trainer",
            key: "is_trainer",
            type: "checkbox",
            placeholder: "Please select is trainer",
            visible: true,
            width: '100%',
            tab: 'Employment Details',
            value: false
        },

        {
            key: 'image_custom_2',
            type: 'custom',
            visible: view ? false : true,
            width: '100%',
            component: ({ field, useForm, tKey, tIndex }) => {
                const image = useForm((state) => state.formObject['image']);
                const face_auth_image_url = useForm((state) => state.formObject['face_auth_image_url']);
                const setFormObject = useForm((state) => state.setFormObject);

                return <View row>
                    <View
                        left
                        style={{
                            width: 120,
                        }}>
                        {Label({ label: 'Face Auth Image' })}
                        <CustomUpload
                            value={face_auth_image_url}
                            onChange={(value) => {
                                setFormObject({
                                    face_auth_image_url: value,
                                });
                            }}
                            placeholder="Select Face Auth Image"
                            label="Select Face Auth Image"
                            moduleName={moduleName}
                            width="100%"
                            mimeType="image/*"
                        />
                    </View>
                </View>
            },
            tab: 'Employment Details'
        },

        {
            label: "Enable Face Matching",
            key: "enable_face_auth",
            type: "checkbox",
            placeholder: "Please select enable face auth",
            visible: true,
            width: '100%',
            tab: 'Employment Details',
            value: false,
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const face_auth_image_url = getObj().face_auth_image_url;

                if (!face_auth_image_url) {
                    setObj({
                        enable_face_auth: false,
                        updateVisiblity: Math.random(),
                    })

                    if (Platform.OS == 'web') {
                        message.info('Please upload face auth image first');
                    } else {
                        Alert.alert('Please upload face auth image first');
                    }
                }

            }
        },

        {
            label: "Lock Face Auth Image",
            key: "lock_face_auth_image",
            type: "checkbox",
            placeholder: "Lock face auth image?",
            visible: (connectedObj) => connectedObj.enable_face_auth,
            width: '100%',
            tab: 'Employment Details',
            value: false
        },

        {
            type: 'custom',
            visible: (connectedObj) => {
                if (view && (connectedObj.role?.name?.toLowerCase() == 'fsc' || connectedObj.role?.name?.toLowerCase() == 'group leader' || connectedObj.role?.name?.toLowerCase() == 'director')) {
                    return true;
                } else {
                    return false;
                }
            },
            width: '100%',
            tab: 'Membership Payments',
            component: MembershipPaymentsComponent,
        },

        {
            type: 'custom',
            visible: (connectedObj) => {
                if (view && (connectedObj.role?.name?.toLowerCase() == 'fsc' || connectedObj.role?.name?.toLowerCase() == 'group leader' || connectedObj.role?.name?.toLowerCase() == 'director')) {
                    return true;
                } else {
                    return false;
                }
            },
            width: '100%',
            tab: 'Daily Activity',
            component: DailyActivityComponent,
        },

        {
            type: 'custom',
            visible: (connectedObj) => {
                if (view && (connectedObj.role?.name?.toLowerCase() == 'fsc' || connectedObj.role?.name?.toLowerCase() == 'group leader' || connectedObj.role?.name?.toLowerCase() == 'director')) {
                    return true;
                } else {
                    return false;
                }
            },
            width: '100%',
            tab: 'Sales Activity',
            component: SalesActivityComponent,
        },

        {
            type: 'custom',
            visible: (connectedObj) => {
                if (view && (connectedObj.role?.name?.toLowerCase() == 'fsc' || connectedObj.role?.name?.toLowerCase() == 'group leader' || connectedObj.role?.name?.toLowerCase() == 'director')) {
                    return true;
                } else {
                    return false;
                }
            },
            width: '100%',
            tab: 'Attendance',
            component: AttendanceComponent,
        },

        // {
        //     type: 'custom',
        //     visible: (connectedObj) => {
        //         if (view && connectedObj.role_id == response.fsc_role_id) {
        //             return true;
        //         } else {
        //             return false;
        //         }
        //     },
        //     width: '100%',
        //     tab: 'Performance KPI',
        //     component: () => <></>,
        // },

        // {
        //     type: 'custom',
        //     visible: (connectedObj) => {
        //         if (view && connectedObj.role_id == response.fsc_role_id) {
        //             return true;
        //         } else {
        //             return false;
        //         }
        //     },
        //     width: '100%',
        //     tab: 'Goal Incentives',
        //     component: () => <></>,
        // },

        {
            type: 'custom',
            visible: (connectedObj) => {
                if (view && (connectedObj.role?.name?.toLowerCase() == 'fsc' || connectedObj.role?.name?.toLowerCase() == 'group leader' || connectedObj.role?.name?.toLowerCase() == 'director')) {
                    return true;
                } else {
                    return false;
                }
            },
            width: '100%',
            tab: 'Training History',
            component: CoursesEnrolComponent,
        },
        {
            type: 'custom',
            visible: (connectedObj) => {
                if (view && (connectedObj.role?.name?.toLowerCase() == 'fsc' || connectedObj.role?.name?.toLowerCase() == 'group leader' || connectedObj.role?.name?.toLowerCase() == 'director')) {
                    return true;
                } else {
                    return false;
                }
            },
            width: '100%',
            tab: 'KPI',
            component: KPIComponent,
        },
        {
            type: 'custom',
            visible: (connectedObj) => {
                if (view && (connectedObj.role?.name?.toLowerCase() == 'fsc' || connectedObj.role?.name?.toLowerCase() == 'group leader' || connectedObj.role?.name?.toLowerCase() == 'director')) {
                    return true;
                } else {
                    return false;
                }
            },
            width: '100%',
            tab: 'Goals',
            component: GoalsComponent,
        }
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '100%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const CustomViewHeader = (props) => {
    return <View padding-15 margin-10 marginB-0 paddingB-10 style={{ borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0, position: 'relative', height: 150 }}>
        <View spread row top>
            <View row >
                <Image source={{
                    uri: props?.connectedObject?.image ? props?.connectedObject?.image : 'https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png'
                }} style={{ width: 100, height: 100, borderRadius: 50 }} />
                <View marginL-10 marginT-10>
                    <Text white style={{
                        fontFamily: 'SourceSansProSemiBold'
                    }}>{props?.connectedObject?.name}</Text>
                    <Text white>{props?.connectedObject?.email}</Text>
                    <Text white>Role - {props?.connectedObject?.role?.name}</Text>
                    <View style={{
                        backgroundColor: props?.connectedObject?.account_status == 'Active' ? 'green' : 'red',
                        padding: 5,
                        borderRadius: 5,
                        marginTop: 5,
                        width: 100,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Text white>{props?.connectedObject?.account_status}</Text>
                    </View>
                </View>
            </View>

            {props.HeaderExtra}
        </View>
        <Image assetGroup="taqwa" assetName="employee_bg" style={{
            width: '100%',
            height: 150,
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            borderRadius: 10,
            borderBottomEndRadius: 0,
            borderBottomStartRadius: 0,
        }} />
    </View>;
}

export const employeeMain = {
    fields: employeefields,
    mobileCard: null,
    CustomViewHeader: CustomViewHeader,
    customHeaderHeight: 150,
    customColumnRender: {
        // name: (value, row, column) => {

        //     return <View row centerV>
        //         {Platform.OS == 'web' && <WebImageView src={row.image || 'https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png'} style={{
        //             width: 30,
        //             height: 30,
        //             resizeMode: 'cover',
        //             borderRadius: 50,
        //             marginRight: 10
        //         }} />}
        //         <View>
        //             <Text
        //                 monoTableBody={false}
        //                 monoTableHeading={false}
        //                 ellipsizeMode={'tail'} numberOfLines={1} style={[{
        //                     maxWidth: '100%',
        //                     fontFamily: 'SourceSansProSemiBold'
        //                 }]}>
        //                 {value}
        //             </Text>
        //             <Text
        //                 monoTableBody={false}
        //                 monoTableHeading={false}
        //                 ellipsizeMode={'tail'} numberOfLines={1} style={[{
        //                     maxWidth: '100%',
        //                     fontFamily: 'SourceSansProSemiBold',
        //                     fontSize: 12,
        //                     color: '#878686'
        //                 }]}>
        //                 {row.email}
        //             </Text>
        //         </View>

        //     </View>
        // },
        status: (value, row, column) => {
            let color = '#007bff';

            if (row.status) {
                if (row.status == 'Draft') {
                    color = '#909090';
                } else if (row.status == 'Pending') {
                    color = '#ffa600';
                } else if (row.status == 'Delivered') {
                    color = '#007bff';
                }
            }

            const tinycolor = require("tinycolor2");
            const lighterColor = tinycolor(color).lighten(45).toString();

            return <View left>
                <View paddingH-8 paddingV-3 style={{ backgroundColor: lighterColor, borderRadius: 5 }}>
                    <Text
                        monoTableBody={false}
                        monoTableHeading={false}
                        style={[
                            column.customCellStyle,
                            { maxWidth: '100%' },
                            { color: color },
                            { fontFamily: 'SourceSansProSemiBold' }
                        ]} ellipsizeMode={'tail'} numberOfLines={1}>
                        {value}
                    </Text>
                </View>
            </View>
        }
    }
}
