import React, { useEffect, useState } from 'react';
import { View, Text, Button, Colors, TextField, Card, Image, TouchableOpacity } from 'react-native-ui-lib';
import { StyleSheet } from 'react-native';
import { CustomModal, CustomNumberInput, PrimaryButton, SecondaryButton } from '../../../../../mycomponents/DynamicForm';
import { api } from '../../../../../services/api';
import axios from 'axios';
import { GlobalMasterStore } from '../../../../../models/PermissionStore';
import { Table } from 'antd';
import moment from 'moment';

const WalletTopup = ({ onTopup }) => {
    const [amount, setAmount] = useState('4.99');
    const [viewTransactionHistory, setViewTransactionHistory] = useState(false);
    const [transactionHistory, setTransactionHistory] = useState([]);

    const predefinedAmounts = [
        { amount: 4.99, coins: '100' },
        { amount: 9.99, coins: '500' },
        { amount: 25.00, coins: '1500' },
    ];

    const email = GlobalMasterStore((state) => state.globalData.email);

    useEffect(() => {
        if (document.getElementById('razorpay-script')) {
            return;
        }

        const script = document.createElement('script')
        script.id = 'razorpay-script'
        script.src = 'https://checkout.razorpay.com/v1/checkout.js'

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    const handleTopup = async () => {
        try {
            const response = await api.customRoute('shop/generateOrderIdRazorPay', {
                amount: parseFloat(amount) * 100 * 85,
                currency: 'INR',
            });
            console.log('PaymentInitate', response)
            const data = response.data;

            const options = {
                key: data.key_id,
                currency: 'INR',
                amount: parseFloat(amount) * 100 * 85, // Convert to INR
                order_id: data.id,
                handler: function (response) {
                    axios.post('https://researchist-api.inkapps.io/add-points-to-email-by-password-post', {
                        email: email,
                        points: amount,
                        increase: true,
                        password: '4375rjgdofn4uhf4978',
                    }).then(() => {
                        window.location.reload();
                    });
                },
            };

            const rzp = new Razorpay(options);
            rzp.open();
        } catch (error) {
            console.error('Error initiating payment:', error);
        }
    };

    useEffect(() => {
        if(viewTransactionHistory) {
            api.customRoute('get-token-history', {
                email: email,
            }).then((response) => {
                console.log('Transaction History:', response);
                
                setTransactionHistory(response);
            });
        }
    }, [viewTransactionHistory]);

    return (
        <View style={styles.container}>
            <View style={styles.card}>

                <View marginT-10 style={styles.amountGrid} m>
                    {predefinedAmounts.map((preset, index) => (
                        <TouchableOpacity key={index}
                            style={{
                                flex: 1,
                            }}
                            onPress={() => setAmount(preset.amount.toString())}
                        >
                            <View center flex style={{
                                backgroundColor: amount == preset.amount.toString() ? Colors.grey60 : 'white',
                                padding: 10,
                                borderRadius: 5,
                            }} onPress={() => setAmount(preset.amount.toString())}>
                                <Image
                                    source={{
                                        uri: 'https://cdn-icons-png.flaticon.com/128/17301/17301413.png',
                                    }}
                                    style={{ width: 50, height: 50 }}
                                />
                                <Text style={styles.amountButtonLabel} marginT-5>{preset.coins} Coins</Text>

                                <Text style={styles.amountButtonLabel}>${preset.amount}</Text>
                            </View>
                        </TouchableOpacity>
                    ))}
                </View>

                <PrimaryButton
                    label={`Top up $${amount}`}
                    onPress={handleTopup}
                />

                <SecondaryButton
                    style={{ marginTop: 10 }}
                    label={`View Transaction History`}
                    onPress={() => setViewTransactionHistory(true)}
                />

                <CustomModal
                    visible={viewTransactionHistory}
                    onClose={() => setViewTransactionHistory(false)}
                    title="Transaction History"
                >   
                    <View marginT-20>
                        <Table dataSource={transactionHistory} columns={[
                            {
                                title: 'Date',
                                dataIndex: 'createdAt',
                                key: 'createdAt',
                                render: (text) => moment(text).format('DD/MM/YYYY hh:mm A'),
                            },
                            {
                                title: 'Reason',
                                dataIndex: 'reason',
                                key: 'reason',
                            },
                            {
                                title: 'Cost',
                                dataIndex: 'cost',
                                key: 'cost',
                            }
                        ]} />
                    </View>
                </CustomModal>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    card: {
        width: '100%',
        maxWidth: '100%',
        marginTop: 20,
    },
    title: {
        marginBottom: 20,
        textAlign: 'center',
    },
    input: {
        borderBottomWidth: 1,
        borderColor: '#000000',
        marginBottom: 20,
    },
    amountGrid: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    amountButton: {
        width: '23%',
        marginBottom: 10,
    },
    amountButtonLabel: {
        fontSize: 14,
        fontFamily: 'SourceSansProSemibold',
    },
    topupButton: {
        marginTop: 20,
    },
});

export default WalletTopup;