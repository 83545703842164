import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const paperfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Upload Paper',
            key: 'pdf_url',
            type: 'upload',
            placeholder: 'Upload Paper',
            visible: true,
            width: '100%',
            mimeType: "application/pdf",
            moduleName: moduleName,
            multiple: false,
            value: '',
        },
        {
            label: 'Title',
            key: 'title',
            type: 'text',
            placeholder: 'Enter title',
            visible: true,
            width: '100%',
            required: true,
        },
        {
            label: 'Description',
            key: 'description',
            type: 'textarea',
            placeholder: 'Enter Description',
            visible: true,
            width: '100%',
        },
        {
            label: 'Objective',
            key: 'objective',
            type: 'textarea',
            placeholder: 'Enter Objective',
            visible: true,
            width: '100%',
        },
        {
            label: 'Insights',
            key: 'insights',
            type: 'array',
            placeholder: 'Enter Insights',
            visible: true,
            width: '100%',
        },
        {
            label: 'Conclusions',
            key: 'conclusions',
            type: 'array',
            placeholder: 'Enter Conclusions',
            visible: true,
            width: '100%',
        },
        {
            label: 'Future Scope of Works',
            key: 'future_scope_of_works',
            type: 'array',
            placeholder: 'Enter Future Scope of Works',
            visible: true,
            width: '100%',
        },
        {
            label: 'Authors',
            key: 'authors',
            type: 'array',
            placeholder: 'Enter Authors',
            visible: true,
            width: '100%',
        },
        {
            label: 'Publishers',
            key: 'publishers',
            type: 'array',
            placeholder: 'Enter Publishers',
            visible: true,
            width: '100%',
        },
        {
            label: 'Year of Publication',
            key: 'year_of_publication',
            type: 'number',
            placeholder: 'Enter Year of Publication',
            visible: true,
            width: '100%',
        },
        {
            label: 'Citation',
            key: 'citation',
            type: 'text',
            placeholder: 'Enter Citation',
            visible: true,
            width: '100%',
        },
        {
            label: 'Keywords',
            key: 'keywords',
            type: 'array',
            placeholder: 'Enter Keywords',
            visible: true,
            width: '100%',
        },
        {
            label: 'Use Case',
            key: 'use_case',
            type: 'text',
            placeholder: 'Enter Use Case',
            visible: true,
            width: '100%',
        },
    ];

    return formFields;
}

export const paperMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const paperMain = {
    fields: paperfields,
    mobileCard: null
}