// employee_id: { type: Types.ObjectId, default: null, ref: 'Employees' },
// employee: { type: mongoose.Schema.Types.Mixed, default: {} },

// leader_id: { type: Types.ObjectId, default: null, ref: 'Employees' },
// leader: { type: mongoose.Schema.Types.Mixed, default: {} },

// lead_id: { type: Types.ObjectId, default: null, ref: 'Leads' },
// lead: { type: mongoose.Schema.Types.Mixed, default: {} },

// date: { type: Date, required: false },

// met_before: { type: Boolean, default: false },
// current_status: { type: String },
// meeting_objective: { type: String },

// client_needs: { type: String },
// current_situation: { type: String },
// recommended_solution: { type: String },
// why_recommended: { type: String },

// good_practices: { type: String },
// things_to_improve: { type: String },
// overall_remarks: { type: String },

// selfie: { type: String },

import React, { useEffect } from "react";
import { Platform } from "react-native";
import { View, Card, Text } from "react-native-ui-lib";

export const jointFieldWorkfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const employee_id = globalData?.employee_id;
    const is_fsc = employee_id == value?.employee_id;
    const is_group_leader = employee_id == value?.leader_id;

    let formFields = [
        {
            label: 'FSC',
            key: 'employee_id',
            type: 'select',
            placeholder: 'Employee',
            visible: true,
            width: '100%',
            options: response?.Employees || [],
            tab: "FSC's Form",
            tab: "General Details"
        },
        {
            label: 'Group Leader',
            key: 'leader_id',
            type: 'select',
            placeholder: 'Leader',
            visible: true,
            width: '100%',
            options: response?.GroupLeaders || [],
            tab: "FSC's Form",
            tab: "General Details"
        },
        {
            label: 'Lead',
            key: 'lead_id',
            type: 'select',
            placeholder: 'Lead',
            visible: true,
            width: '100%',
            options: response?.Leads || [],
            tab: "General Details"
        },
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Date',
            visible: true,
            width: '100%',
            tab: "General Details"
        },
        {
            label: 'Met Before',
            key: 'met_before',
            type: 'checkbox',
            placeholder: 'Met Before',
            visible: true,
            width: '100%',
            tab: "FSC's Form"
        },
        {
            label: 'Current Status',
            key: 'current_status',
            type: 'select',
            placeholder: 'Current Status',
            visible: true,
            width: '100%',
            options: [
                { label: 'Trust bridging', value: 'Trust bridging' },
                { label: 'Dream building', value: 'Dream building' }
            ],
            tab: "FSC's Form"
        },
        {
            label: 'Meeting Objective',
            key: 'meeting_objective',
            type: 'textarea',
            placeholder: 'Meeting Objective',
            visible: true,
            width: '100%',
            tab: "FSC's Form"
        },
        {
            label: 'Client Needs',
            key: 'client_needs',
            type: 'textarea',
            placeholder: 'Client Needs',
            visible: true,
            width: '100%',
            tab: "FSC's Form"
        },
        {
            label: 'Current Situation',
            key: 'current_situation',
            type: 'textarea',
            placeholder: 'Current Situation',
            visible: true,
            width: '100%',
            tab: "FSC's Form"
        },
        {
            label: 'Recommended Solution',
            key: 'recommended_solution',
            type: 'textarea',
            placeholder: 'Recommended Solution',
            visible: true,
            width: '100%',
            tab: "FSC's Form"
        },
        {
            label: 'Why Recommended',
            key: 'why_recommended',
            type: 'textarea',
            placeholder: 'Why Recommended',
            visible: true,
            width: '100%',
            tab: "Leader's Remarks"
        },
        {
            label: 'Good Practices',
            key: 'good_practices',
            type: 'editor',
            placeholder: 'Good Practices',
            visible: true,
            width: '100%',
            tab: "Leader's Remarks"
        },
        {
            label: 'Things to Improve',
            key: 'things_to_improve',
            type: 'editor',
            placeholder: 'Things to Improve',
            visible: true,
            width: '100%',
            tab: "Leader's Remarks"
        },
        {
            label: 'Overall Remarks',
            key: 'overall_remarks',
            type: 'textarea',
            placeholder: 'Overall Remarks',
            visible: true,
            width: '100%',
            tab: "Leader's Remarks"
        },
        {
            label: 'Selfie',
            key: 'selfie',
            type: 'upload',
            placeholder: 'Selfie',
            visible: true,
            width: '100%',
            mimeType: 'image/*',
            moduleName: moduleName,
            tab: "Leader's Remarks"
        }
    ];

    if (Platform.OS != 'web') {
        if (!is_fsc) {
            formFields = formFields.filter((field) => field.tab != "FSC's Form");
        } else {
            formFields.forEach((field) => {
                if (field.tab == "General Details") {
                    field.disabled = true;
                }
            });
        }

        if (!is_group_leader) {
            formFields = formFields.filter((field) => field.tab != "Leader's Remarks");
        }
    }

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '100%'
            });
        });
    }

    return formFields;
}

export const jointFieldWorkMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const jointFieldWorkMain = {
    fields: jointFieldWorkfields,
    mobileCard: null
}