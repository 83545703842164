export const SalesActivityExpandable = [
    {
        label: 'Accident Plan',
        fields: [
            {
                label: 'Cases',
                key: 'accident_plan_cases',
                type: 'number',
                placeholder: 'Enter Cases',
                visible: true,
                width: '100%',
            },
            {
                label: 'ANP',
                key: 'accident_plan_anp',
                type: 'number',
                placeholder: 'Enter ANP',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYC',
                key: 'accident_plan_fyc',
                type: 'number',
                placeholder: 'Enter FYC',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYP',
                key: 'accident_plan_fyp',
                type: 'number',
                placeholder: 'Enter FYP',
                visible: true,
                width: '100%',
                currency: true,
            },
        ]
    }, {
        label: 'Hospital Plan',
        fields: [
            {
                label: 'Cases',
                key: 'hospital_plan_cases',
                type: 'number',
                placeholder: 'Enter Cases',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'ANP',
                key: 'hospital_plan_anp',
                type: 'number',
                placeholder: 'Enter ANP',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYC',
                key: 'hospital_plan_fyc',
                type: 'number',
                placeholder: 'Enter FYC',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYP',
                key: 'hospital_plan_fyp',
                type: 'number',
                placeholder: 'Enter FYP',
                visible: true,
                width: '100%',
                currency: true,
            },
        ]
    }, {
        label: 'Regular Plan',
        fields: [
            {
                label: 'Cases',
                key: 'regular_plan_cases',
                type: 'number',
                placeholder: 'Enter Cases',
                visible: true,
                width: '100%',
            },
            {
                label: 'ANP',
                key: 'regular_plan_anp',
                type: 'number',
                placeholder: 'Enter ANP',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYC',
                key: 'regular_plan_fyc',
                type: 'number',
                placeholder: 'Enter FYC',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYP',
                key: 'regular_plan_fyp',
                type: 'number',
                placeholder: 'Enter FYP',
                visible: true,
                width: '100%',
                currency: true,
            },
        ]
    }, {
        label: 'Investment Plan (SP)',
        fields: [
            {
                label: 'Cases',
                key: 'investment_plan_cases',
                type: 'number',
                placeholder: 'Enter Cases',
                visible: true,
                width: '100%',
            },
            {
                label: 'IANP',
                key: 'investment_plan_ianp',
                type: 'number',
                placeholder: 'Enter IANP',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYC',
                key: 'investment_plan_fyc',
                type: 'number',
                placeholder: 'Enter FYC',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYP',
                key: 'investment_plan_fyp',
                type: 'number',
                placeholder: 'Enter FYP',
                visible: true,
                width: '100%',
                currency: true,
            },
        ]
    }, {
        label: 'Careshield Plan',
        fields: [
            {
                label: 'Cases',
                key: 'careshield_plan_cases',
                type: 'number',
                placeholder: 'Enter Cases',
                visible: true,
                width: '100%',
            },
            {
                label: 'ANP',
                key: 'careshield_plan_anp',
                type: 'number',
                placeholder: 'Enter ANP',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYC',
                key: 'careshield_plan_fyc',
                type: 'number',
                placeholder: 'Enter FYC',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYP',
                key: 'careshield_plan_fyp',
                type: 'number',
                placeholder: 'Enter FYP',
                visible: true,
                width: '100%',
                currency: true,
            },
        ]
    }, {
        label: 'MDRT',
        fields: [
            {
                label: 'YTD FYCC',
                key: 'mdrt_ytd_fycc',
                type: 'number',
                placeholder: 'Enter YTD FYCC',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'YTD Income',
                key: 'mdrt_ytd_income',
                type: 'number',
                placeholder: 'Enter YTD Income',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'YTD FYP',
                key: 'mdrt_ytd_fyp',
                type: 'number',
                placeholder: 'Enter YTD FYP',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'Month To Date',
                key: 'mdrt_month_to_date',
                type: 'number',
                placeholder: 'Enter Month To Date',
                visible: true,
                width: '100%',
                currency: true,
            },
        ]
    }, {
        label: 'Total CASES',
        fields: [
            {
                label: 'Total CASES',
                key: 'total_cases',
                type: 'number',
                placeholder: 'Enter Total CASES',
                visible: true,
                width: '100%',
            },
            {
                label: 'Total ANP',
                key: 'total_anp',
                type: 'number',
                placeholder: 'Enter Total ANP',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'Total FYC',
                key: 'total_fyc',
                type: 'number',
                placeholder: 'Enter Total FYC',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'Total FYP',
                key: 'total_fyp',
                type: 'number',
                placeholder: 'Enter Total FYP',
                visible: true,
                width: '100%',
                currency: true,
            },
        ]
    }, {
        label: 'Year To Date',
        fields: [
            {
                label: 'CASES',
                key: 'year_to_date_cases',
                type: 'number',
                placeholder: 'Enter CASES',
                visible: true,
                width: '100%',
            },
            {
                label: 'CONVENTION ANP',
                key: 'year_to_date_convention_anp',
                type: 'number',
                placeholder: 'Enter CONVENTION ANP',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYC',
                key: 'year_to_date_fyc',
                type: 'number',
                placeholder: 'Enter FYC',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYP',
                key: 'year_to_date_fyp',
                type: 'number',
                placeholder: 'Enter FYP',
                visible: true,
                width: '100%',
                currency: true,
            },
        ]
    },
];

import React, { useEffect, useState } from "react";
import { View, Card, Text, ExpandableSection } from "react-native-ui-lib";
import { CustomNumberInput, Label } from "../../../mycomponents/DynamicForm";
import { DailyActivityCard } from "../../../screens/MainScreens/Custom/Internal_Pages/taqwa/DailyActivityScreen";
import { Platform } from "react-native";
// globalData, setGlobalData, message, navigationFn
export const dailyActivityfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData, message, navigationFn) => {

    const SalesActivityComponent = ({ field, useForm, tKey, tIndex }) => {
        const employee_id = useForm((state) => state.formObject['_id']);
        const [date, setDate] = useState(new Date());
        const [isEdit, setIsEdit] = useState(false);
        const [id, setId] = useState(null);
        const [expandedSection, setExpandedSection] = useState(null);

        const obj = useForm((state) => state.formObject);
        const setFormObject = useForm((state) => state.setFormObject);

        return <>
            <View style={{
                width: '100%',
            }}>
                {SalesActivityExpandable.map((item, index) => {
                    return <ExpandableSection
                        key={index.toString()}
                        sectionHeader={
                            <View flex centerV marginB-0 style={{
                                width: '100%',
                                marginTop: Platform.OS != 'web' ? 0 : 10,
                            }}>
                                <Text h1 style={{
                                    marginTop: Platform.OS != 'web' ? 0 : 10,
                                }}>{item.label}</Text>
                                {Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
                                    <View
                                        style={{
                                            borderStyle: 'dashed',
                                            borderWidth: 1,
                                            borderColor: '#d9d9d9',
                                            margin: -2,
                                            marginTop: 10,
                                        }}>
                                        <View style={{ height: 20, width: 200 }} />
                                    </View>
                                </View> : <View style={{
                                    borderBottomColor: '#d9d9d9',
                                    borderBottomWidth: 1,
                                    width: '100%',
                                    marginBottom: 0,
                                    marginTop: 10,
                                    borderStyle: 'dashed',
                                }} />}
                            </View>
                        }
                        expanded={expandedSection === index}
                        onPress={() => {
                            if (expandedSection === index) {
                                setExpandedSection(null);
                            } else {
                                setExpandedSection(index);
                            }
                        }}
                    >
                        {item.fields.map((field, field_index) => {
                            if (field.type === 'number') {
                                return <View key={field_index.toString()}>
                                    <View row={Platform.OS != 'web'} centerV style={{
                                        marginBottom: Platform.OS != 'web' ? 0 : 10,
                                    }}>
                                        <View centerV style={{ flexBasis: '40%' }}>
                                            {Label({ label: field.label }, view)}
                                        </View>

                                        <View row style={{ flexBasis: '60%' }}>
                                            {!view ? <CustomNumberInput
                                                value={obj[field.key]}
                                                onChange={(value) => {
                                                    setFormObject({ [field.key]: value });
                                                }}
                                                placeholder={field.placeholder}
                                                currency={field.currency}
                                            /> : <Text>{obj[field.key] >= 0 || obj[field.key] <= 0 ? `${field.currency ? "S$ " : ""}` + obj[field.key] : ''}</Text>}
                                        </View>
                                    </View>
                                    {Platform.OS != 'web' && <View style={{ width: '100%', height: 1, backgroundColor: '#f0f0f0', marginVertical: 0, marginBottom: field_index === item.fields.length - 1 ? 10 : 0 }} />}
                                </View>
                            }
                        }
                        )}
                    </ExpandableSection>
                })}
            </View>
        </>;
    };

    const formFields = [
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Enter Date',
            visible: true,
            width: '100%',
            value: new Date(),
            tab: 'Daily Activity',
        },
        {
            label: 'Employee',
            key: 'employee_id',
            type: 'select',
            placeholder: 'Select Employee',
            visible: false,
            width: '100%',
            options: response.Employees ? response.Employees.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
            value: globalData?.employee_id,
        },
        {
            label: 'Appointments Seen',
            key: 'appointments_seen',
            type: 'number',
            placeholder: 'Enter Appointments Seen',
            visible: true,
            width: '100%',
            tab: 'Daily Activity',
        },
        {
            label: 'New Appointments',
            key: 'new_appointments',
            type: 'number',
            placeholder: 'Enter New Appointments',
            visible: true,
            width: '100%',
            tab: 'Daily Activity',
        },
        {
            label: 'Existing Leads Collected',
            key: 'existing_leads_collected',
            type: 'number',
            placeholder: 'Enter Existing Leads Collected',
            visible: true,
            width: '100%',
            tab: 'Daily Activity',
            currency: true,
        },
        {
            label: 'Referral Leads Collected',
            key: 'referral_leads_collected',
            type: 'number',
            placeholder: 'Enter Referral Leads Collected',
            visible: true,
            width: '100%',
            tab: 'Daily Activity',
            currency: true,
        },
        {
            label: 'Talk To',
            key: 'talk_to',
            type: 'number',
            placeholder: 'Enter Talk To',
            visible: true,
            width: '100%',
            tab: 'Daily Activity',
        },
        {
            label: 'Total Case Close',
            key: 'total_case_close',
            type: 'number',
            placeholder: 'Enter Total Case Close',
            visible: true,
            width: '100%',
            tab: 'Daily Activity',
        },
        {
            label: 'Total Life Case Close',
            key: 'total_life_case_close',
            type: 'number',
            placeholder: 'Enter Total Life Case Close',
            visible: true,
            width: '100%',
            tab: 'Daily Activity',
        },
        {
            label: 'First Year Commission (FYC)',
            key: 'first_year_commission',
            type: 'number',
            placeholder: 'Enter First Year Commission (FYC)',
            visible: true,
            width: '100%',
            tab: 'Daily Activity',
            currency: true,
        },
        {
            label: 'Daily Remarks',
            key: 'daily_remarks',
            type: 'textarea',
            placeholder: 'Enter Daily Remarks',
            visible: true,
            width: '100%',
            tab: 'Daily Activity',
        },
        // {
        //     key: 'SalesActivityComponent',
        //     type: 'custom',
        //     visible: true,
        //     width: '100%',
        //     tab: 'Sales Activity',
        //     component: SalesActivityComponent,
        // },
        // {
        //     label: 'Sales Remarks',
        //     key: 'sales_remarks',
        //     type: 'textarea',
        //     placeholder: 'Enter Sales Remarks',
        //     visible: true,
        //     width: '100%',
        //     tab: 'Sales Activity',
        // },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '100%'
            });
        });
    }

    return formFields;
}

export const dailyActivityMain = {
    fields: dailyActivityfields,
    mobileCard: null
}