import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const membershipPaymentFeefields = (response) => {
    const formFields = [
        {
            label: 'Employee',
            key: 'employee_id',
            type: 'select',
            placeholder: 'Select Employee',
            visible: false,
            width: '100%',
            required: true,
            options: response.Employees ? response.Employees.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
        },
        {
            label: 'Payment Date',
            key: 'payment_date',
            type: 'date',
            placeholder: 'Enter Payment Date',
            visible: true,
            width: '100%',
            value: new Date(),
        },
        {
            label: 'Membership Start Date',
            key: 'membership_start_date',
            type: 'date',
            placeholder: 'Enter Membership Start Date',
            visible: true,
            width: '100%',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const membership_duration = getObj()['membership_duration'];
                const duration_string = "" + membership_duration;

                console.log("duration_string", duration_string);

                const endsWithOne = duration_string[duration_string.length - 1] == 1;
                const endsWithTwo = duration_string[duration_string.length - 1] == 2;

                if (endsWithOne) {
                    const subscription_duration = membership_duration - 1;
                    const end_date = new Date(value);
                    const days = subscription_duration / 86400000;
                    const months = days / 30;
                    end_date.setMonth(end_date.getMonth() + months);
                    // setFormObjectArray(tKey, tIndex, 'end_date', end_date.toISOString());
                    setObj({
                        membership_end_date: end_date,
                        updateVisiblity: Math.random()
                    })
                } else if (endsWithTwo) {
                    // this is for years
                    const subscription_duration = membership_duration - 2;
                    const end_date = new Date(value);
                    const days = subscription_duration / 86400000;
                    const years = days / 365;
                    end_date.setFullYear(end_date.getFullYear() + years);
                    // setFormObjectArray(tKey, tIndex, 'end_date', end_date.toISOString());
                    setObj({
                        membership_end_date: end_date,
                        updateVisiblity: Math.random()
                    })
                } else {
                    const subscription_duration = membership_duration;
                    const end_date = new Date(value);
                    // const days = subscription_duration / 86400000;
                    end_date.setTime(end_date.getTime() + subscription_duration);
                    // setFormObjectArray(tKey, tIndex, 'end_date', end_date.toISOString());
                    setObj({
                        membership_end_date: end_date,
                        updateVisiblity: Math.random()
                    })
                }
            }
        },
        {
            label: 'Membership Duration',
            key: 'membership_duration',
            type: 'duration',
            duration_options: ["months", "years"],
            placeholder: 'Enter Membership Duration',
            visible: true,
            width: '100%',
            value: (365 * 24 * 60 * 60 * 1000) + 2,
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const membership_start_date = getObj()['membership_start_date'];
                // const duration_string = value.toString();
                const duration = getObj()['membership_duration'];
                const duration_string = "" + duration;

                console.log("duration_string", duration_string);

                const endsWithOne = duration_string[duration_string.length - 1] == 1;
                const endsWithTwo = duration_string[duration_string.length - 1] == 2;

                if (endsWithOne) {
                    const subscription_duration = duration - 1;
                    const end_date = new Date(membership_start_date);
                    const days = subscription_duration / 86400000;
                    const months = days / 30;
                    end_date.setMonth(end_date.getMonth() + months);
                    // setFormObjectArray(tKey, tIndex, 'end_date', end_date.toISOString());
                    setObj({
                        membership_end_date: end_date,
                        updateVisiblity: Math.random()
                    })
                } else if (endsWithTwo) {
                    // this is for years
                    const subscription_duration = duration - 2;
                    const end_date = new Date(membership_start_date);
                    const days = subscription_duration / 86400000;
                    const years = days / 365;
                    end_date.setFullYear(end_date.getFullYear() + years);
                    // setFormObjectArray(tKey, tIndex, 'end_date', end_date.toISOString());
                    setObj({
                        membership_end_date: end_date,
                        updateVisiblity: Math.random()
                    })
                } else {
                    const subscription_duration = duration;
                    const end_date = new Date(membership_start_date);
                    // const days = subscription_duration / 86400000;
                    end_date.setTime(end_date.getTime() + subscription_duration);
                    // setFormObjectArray(tKey, tIndex, 'end_date', end_date.toISOString());
                    setObj({
                        membership_end_date: end_date,
                        updateVisiblity: Math.random()
                    })
                }
            }
        },
        {
            label: 'Membership End Date',
            key: 'membership_end_date',
            type: 'date',
            placeholder: 'Enter Membership End Date',
            visible: true,
            width: '100%',
            disabled: true,
        },
        {
            label: 'Payment Amount',
            key: 'payment_amount',
            type: 'number',
            placeholder: 'Enter Payment Amount',
            visible: true,
            width: '100%',
        },
        {
            label: 'Payment Method',
            key: 'payment_type_id',
            type: 'select',
            placeholder: 'Enter Payment Method',
            visible: true,
            width: '100%',
            options: response.PaymentTypes ? response.PaymentTypes.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const membershipPaymentFeeMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const membershipPaymentFeeMain = {
    fields: membershipPaymentFeefields,
    mobileCard: null
}