// title: { type: String, required: true },
// notification_type_id: { type: Types.ObjectId, default: null, ref: 'AnnouncementTypes' }, 
// notification_type: { type: mongoose.Schema.Types.Mixed, default: {} }, 
// description: { type: String, required: false }, 
// publish_date: { type: Date, required: false }, 
// expiry_date: { type: Date, required: false }, 
// share_notification_via: { type: String, enum: ['Email', 'Notification', 'Both'], default: 'Both' }, 
// send_to: { type: String, enum: ['Employees', 'Lead'], default: 'Employees' },

// employees: [{ type: mongoose.Schema.Types.ObjectId, required: false }],
// leads: [{ type: mongoose.Schema.Types.ObjectId, required: false }],

import React, { useEffect } from "react";
import { View, Card, Text, Image, TouchableOpacity } from "react-native-ui-lib";
import ListingScreen from "../../../screens/MainScreens/CRUD/ListingScreen";
import moment from "moment";
import Svg, { Ellipse, G, Path, Defs, ClipPath } from "react-native-svg"
import RenderHtml from 'react-native-render-html';
import { Dimensions, Platform } from "react-native";
import { CustomCheckbox, CustomNumberInput, CustomTextField, DangerButton, SecondaryButton } from "../../../mycomponents/DynamicForm";


export const notificationfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const formFields = [
        {
            label: 'Title',
            key: 'title',
            type: 'text',
            placeholder: 'Enter Title',
            visible: true,
            width: '100%',
            required: true,
            tab: "General",
        },
    ];


    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '100%'
            });
        });
    }

    return formFields;
}

export const notificationMobileCard = ({ item, index, getFieldValue, onRowClick, valueText, columns, DeleteCard, actionFunctions, SidebarStatus }) => {
    // ignore columns 
    const ignoreColumns = ["expiry_date", "createdAt"]
    const newColumns = columns.filter((item) => !ignoreColumns.includes(item.field));

    return <Card flex style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: 10 }}>
        <TouchableOpacity padding-10 flex onPress={onRowClick ? () => {
            onRowClick && onRowClick(item);
        } : null}>

            <View marginB-10 row spread style={{ width: '100%' }}>
                <View center backgroundColor="#9ec1e9" style={{ width: 60, height: 60, borderRadius: 10 }}>
                    <Text style={{ fontSize: 24, color: 'white' }}>{moment(item.publish_date).format('DD')}</Text>
                    <Text style={{ fontSize: 12, color: 'white' }}>{moment(item.publish_date).format('ddd')}</Text>
                </View>

                <View flex marginL-10>
                    <View row>
                        <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                            {newColumns[0] ? getFieldValue(item, newColumns[0].field, newColumns[0]) : ""}
                        </Text>
                        <View marginL-10>
                            {item.approvalStatus == 'Pending' && <Text style={{ color: '#ffc93a' }}>(Pending)</Text>}
                            {item.approvalStatus == 'Rejected' && <Text style={{ color: '#cc1010' }}>(Rejected)</Text>}
                        </View>
                    </View>

                    <View>
                        <View flex>
                            {newColumns.length > 2 ? <View row marginB-6 flex>
                                <View>
                                    <Text lightCardTitle>{newColumns[1] ? newColumns[1].label : ""}: </Text>
                                </View>
                                <View flex>
                                    {valueText(newColumns[1])}
                                </View>
                            </View> : null}
                            {newColumns.length > 3 ? <View row marginB-6 flex>
                                <View>
                                    <Text lightCardTitle>{newColumns[2] ? newColumns[2].label : ""}: </Text>
                                </View>
                                <View flex>
                                    {valueText(newColumns[2])}
                                </View>
                            </View> : null}
                        </View>
                    </View>
                </View>
            </View>

            {newColumns.length > 4 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
            {newColumns.length > 4 ? <View style={{ width: '100%' }}>
                {newColumns.length > 4 ? <View row flex>
                    <View>
                        <Text lightCardTitle>{newColumns[3] ? newColumns[3].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(newColumns[3])}
                    </View>
                </View> : null}
                {newColumns.length > 5 ? <View row flex>
                    <View>
                        <Text lightCardTitle>{newColumns[4] ? newColumns[4].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(newColumns[4])}
                    </View>
                </View> : null}
            </View> : null}

            {newColumns.length > 6 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
            {newColumns.length > 6 ? <View style={{ width: '100%' }}>
                {newColumns.length > 6 ? <View row flex>
                    <View>
                        <Text lightCardTitle>{newColumns[5] ? newColumns[5].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(newColumns[5])}
                    </View>
                </View> : null}
                {newColumns.length > 7 ? <View row flex>
                    <View>
                        <Text lightCardTitle>{newColumns[6] ? newColumns[6].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(newColumns[6])}
                    </View>
                </View> : null}
            </View> : null}

            {newColumns.length > 8 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
            {newColumns.length > 8 ? <View row spread style={{ width: '100%' }}>
                {newColumns.length > 8 ? <View row flex>
                    <View>
                        <Text lightCardTitle>{newColumns[7] ? newColumns[7].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(newColumns[7])}
                    </View>
                </View> : null}
                {newColumns.length > 9 ? <View row flex>
                    <View>
                        <Text lightCardTitle>{newColumns[8] ? newColumns[8].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(newColumns[8])}
                    </View>
                </View> : null}
            </View> : null}

        </TouchableOpacity>
        {/* {(actionFunctions && actionFunctions(item)?.length > 0) ? <TouchableOpacity>
            <SidebarStatus color={"#397CF2"}
                text={item.status}
                actionFunctions={actionFunctions} item={item} radius={10} />
        </TouchableOpacity> : null} */}
    </Card>;
}

export const notificationMain = {
    fields: notificationfields,
    mobileCard: notificationMobileCard,
}