// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, DeviceEventEmitter, TouchableOpacity } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, ProgressBar } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomDatePicker, CustomModal, CustomNumberInput, CustomSelect, CustomTextAreaField, CustomTextField, CustomTextFieldWithScanner, CustomUpload, DangerButton, Heading, Label, PrimaryButton, Row, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import { Icon } from "react-native-eva-icons"
import { CustomSelectItemForContacts, TransactionPDFPreview, invoiceItemAdjustment } from "../../../../../models/accountancy/transaction"
import { create } from 'zustand'
import { FlashList } from "@shopify/flash-list";
import { GlobalModalStore } from "../../../../../models/ModalStore"
import { App } from 'antd';
import * as Sharing from 'expo-sharing';
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import CalendarStrip from 'react-native-calendar-strip';
import moment from "moment"
import Svg, { Circle, Path, Rect } from "react-native-svg"
import { Agenda } from 'react-native-calendars';
import { AgendaIcon, DateIcon, InviteIcon } from "../../../../../models/taqwa/Meeting/Meetings"
import RenderHtml from 'react-native-render-html';
import FaceAuth from "../main/FaceAuth/FaceAuth"
import * as Location from 'expo-location';
import EventsScreen from "./EventsScreen"

const useFormObject = create((set, get) => ({
    formObject: {},
    setFormObject: (keyValue) => set((state) => ({ formObject: { ...state.formObject, ...keyValue } })),
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
        if (typeof field_key === 'object') {
            const newFormObject = { ...state.formObject };
            newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
            return { formObject: newFormObject };
        } else {
            const newFormObject = { ...state.formObject };
            // newFormObject[array_key][array_index][field_key] = value;
            const newArray = newFormObject[array_key];
            newArray[array_index][field_key] = value;
            newFormObject[array_key] = newArray;
            return { formObject: newFormObject };
        }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
}));

const DateSvg = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <Path
            fill="#33A9FF"
            d="M6.81 18.756h10.373c1.635 0 2.486-.844 2.486-2.456V7.132c0-1.612-.851-2.456-2.486-2.456H6.81c-1.627 0-2.478.836-2.478 2.456V16.3c0 1.612.851 2.456 2.478 2.456Zm-.008-1.5c-.625 0-.972-.323-.972-.986V9.339c0-.663.347-.987.972-.987H17.19c.625 0 .971.324.971.987v6.93c0 .664-.346.988-.971.988H6.8Zm3.744-6.305h.445c.27 0 .361-.083.361-.346v-.445c0-.271-.09-.354-.361-.354h-.445c-.271 0-.362.083-.362.354v.445c0 .263.09.346.362.346Zm2.463 0h.445c.264 0 .354-.083.354-.346v-.445c0-.271-.09-.354-.354-.354h-.445c-.27 0-.361.083-.361.354v.445c0 .263.09.346.361.346Zm2.456 0h.445c.271 0 .361-.083.361-.346v-.445c0-.271-.09-.354-.361-.354h-.445c-.263 0-.354.083-.354.354v.445c0 .263.09.346.354.346ZM8.09 13.377h.437c.271 0 .362-.083.362-.354v-.445c0-.263-.09-.346-.362-.346H8.09c-.271 0-.361.083-.361.346v.445c0 .271.09.354.361.354Zm2.456 0h.445c.27 0 .361-.083.361-.354v-.445c0-.263-.09-.346-.361-.346h-.445c-.271 0-.362.083-.362.346v.445c0 .271.09.354.362.354Zm2.463 0h.445c.264 0 .354-.083.354-.354v-.445c0-.263-.09-.346-.354-.346h-.445c-.27 0-.361.083-.361.346v.445c0 .271.09.354.361.354Zm2.456 0h.445c.271 0 .361-.083.361-.354v-.445c0-.263-.09-.346-.361-.346h-.445c-.263 0-.354.083-.354.346v.445c0 .271.09.354.354.354ZM8.09 15.795h.437c.271 0 .362-.083.362-.346v-.445c0-.271-.09-.354-.362-.354H8.09c-.271 0-.361.083-.361.354v.445c0 .263.09.346.361.346Zm2.456 0h.445c.27 0 .361-.083.361-.346v-.445c0-.271-.09-.354-.361-.354h-.445c-.271 0-.362.083-.362.354v.445c0 .263.09.346.362.346Zm2.463 0h.445c.264 0 .354-.083.354-.346v-.445c0-.271-.09-.354-.354-.354h-.445c-.27 0-.361.083-.361.354v.445c0 .263.09.346.361.346Z"
        />
    </Svg>
)

const TrainingScreen = observer(function TrainingScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    let queryParams = { ...(props?.route?.params || {}) };

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const goBack = () => {
        if (Platform.OS === 'web') {
            window.history.back();
        } else {
            navigation.goBack();
        }
    }

    const moduleName = "DailyActivities";
    const { fields, dataTabs, preferencesFields, ExtraSaveComponent } = api.getForm(moduleName);
    const params = {};

    const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
    const globalData = GlobalMasterStore((state) => state.globalData);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const employee = GlobalMasterStore((state) => state.employee);

    const [refreshCount, setRefreshCount] = useState(0);
    const [segmentIndex, setSegmentIndex] = useState(0);
    const [showCourseModal, setShowCourseModal] = useState(false);
    const [courseDetails, setCourseDetails] = useState({});

    const [courses, setCourses] = useState([]);
    const [allCourses, setAllCourses] = useState([]);

    const getMyCourses = async () => {
        const response = await api.customRoute('Courses/action', {
            action: 'getMyCourses',
            employee_id: employee._id,
        });

        if (response.success) {
            setCourses(response.data);
        }
    };

    const getAllCourses = async () => {
        const response = await api.customRoute('Courses/action', {
            action: 'getAllCourses',
            employee_id: employee._id,
        });

        if (response.success) {
            setAllCourses(response.data);
        }
    };

    useEffect(() => {
        getMyCourses();
        getAllCourses();
    }, []);

    const truncateUpto200Char = (text) => {
        // 200 characters
        if (text?.length > 200) {
            return text.substring(0, 200) + "...";
        } else {
            return text;
        }
    }

    return (
        <View style={{
            flex: 1
        }}>
            <SegmentedControl
                selectedIndex={segmentIndex}
                outlineColor={"rgb(41, 69, 156)"} activeColor={"rgb(41, 69, 156)"} borderRadius={4} segments={[{ label: 'Up Next' }, { label: 'My Courses' }, { label: 'All Courses' }]}
                segmentsStyle={{
                    // flex: 1,
                    width: "33.33%",
                    // borderColor: 'rgb(41, 69, 156)',
                    // borderWidth: 1,
                }}
                style={{
                    width: "100%",
                }}
                onChangeIndex={(index) => {
                    console.log("index", index);
                    setSegmentIndex(index);
                }} />

            {segmentIndex == 0 && <View flex>
                <EventsScreen
                    key={refreshCount}
                    route={
                        {
                            params: {
                                showTraining: true,
                            }
                        }
                    } />
            </View>}

            {segmentIndex == 1 && <View marginT-10 flex>
                <ScrollView>
                    <View paddingH-15>
                        {courses.map((course, index) => {
                            return <Card key={index.toString()} marginB-10 marginT-10 padding-10 onPress={() => {
                                setCourseDetails(course);
                                setShowCourseModal(true)
                            }}>
                                <Image assetGroup="taqwa" assetName="CourseBG" style={{
                                    width: '100%',
                                    height: 100,
                                    borderRadius: 5,
                                }} />
                                <View row spread marginT-10>
                                    <Text h1>{course.name}</Text>
                                    <View backgroundColor="#007C89" paddingH-10 center style={{
                                        borderRadius: 5,
                                    }}>
                                        <Text white style={{
                                            fontSize: 10,
                                            fontFamily: 'SourceSansProSemiBold',
                                            letterSpacing: 1,
                                            textTransform: 'uppercase'
                                        }}>{course.course_group.name}</Text>
                                    </View>
                                </View>
                                <View row spread marginT-10 marginB-10 style={{
                                    opacity: 0.5,
                                }}>
                                    {Platform.OS === 'web' ? <div dangerouslySetInnerHTML={{ __html: truncateUpto200Char(course.description) }} /> : <RenderHtml contentWidth={Dimensions.get('window').width - 40} source={{ html: truncateUpto200Char(course.description) }} />}
                                </View>
                                <View style={{
                                    position: 'relative',
                                    width: '100%',
                                }}>
                                    <ProgressBar progress={course.progress_percent} progressColor="#29459C" style={{
                                        height: 20
                                    }} />
                                    <Text style={{
                                        position: 'absolute',
                                        right: 5,
                                        textAlign: 'center',
                                        textAlignVertical: 'center',
                                        color: '#ffffff',
                                        fontFamily: 'SourceSansProSemiBold',
                                    }}>{course.progress_percent}%</Text>
                                </View>

                            </Card>
                        })}
                    </View>
                </ScrollView>
            </View>}

            {segmentIndex == 2 && <View marginT-10 flex>
                <ScrollView>
                    <View paddingH-15>
                        {allCourses.map((course, index) => {
                            return <Card key={index.toString()} marginB-10 marginT-10 padding-10 onPress={() => {
                                setCourseDetails(course);
                                setShowCourseModal(true)
                            }}>
                                <Image assetGroup="taqwa" assetName="CourseBG" style={{
                                    width: '100%',
                                    height: 100,
                                    borderRadius: 5,
                                }} />
                                <View row spread marginT-10>
                                    <Text h1>{course.name}</Text>
                                    <View backgroundColor="#007C89" paddingH-10 center style={{
                                        borderRadius: 5,
                                    }}>
                                        <Text white style={{
                                            fontSize: 10,
                                            fontFamily: 'SourceSansProSemiBold',
                                            letterSpacing: 1,
                                            textTransform: 'uppercase'
                                        }}>{course.course_group.name}</Text>
                                    </View>
                                </View>
                                <View row spread marginT-10 marginB-10 style={{
                                    opacity: 0.5,
                                }}>
                                    {Platform.OS === 'web' ? <div dangerouslySetInnerHTML={{ __html: truncateUpto200Char(course.description) }} /> : <RenderHtml contentWidth={Dimensions.get('window').width - 40} source={{ html: truncateUpto200Char(course.description) }} />}
                                </View>
                                {course.is_registered ? <View style={{
                                    position: 'relative',
                                    width: '100%',
                                }}>
                                    <ProgressBar progress={course.progress_percent} progressColor="#29459C" style={{
                                        height: 20
                                    }} />
                                    <Text style={{
                                        position: 'absolute',
                                        right: 5,
                                        textAlign: 'center',
                                        textAlignVertical: 'center',
                                        color: '#ffffff',
                                        fontFamily: 'SourceSansProSemiBold',
                                    }}>{course.progress_percent}%</Text>
                                </View> : <PrimaryButton label="Register Now" onPress={async () => {
                                    const response = await api.customRoute('Courses/action', {
                                        action: 'registerCourse',
                                        employee_id: employee._id,
                                        course_id: course._id,
                                    });

                                    if (response.success) {
                                        if (Platform.OS === 'web') {
                                            message.success("Course registered successfully");
                                        } else {
                                            Alert.alert("Success", "Course registered successfully");
                                        }
                                        getAllCourses();
                                        getMyCourses();
                                    } else {
                                        if (Platform.OS === 'web') {
                                            message.error(response.message || "Something went wrong");
                                        } else {
                                            Alert.alert("Error", response.message || "Something went wrong");
                                        }
                                    }
                                }} />}
                            </Card>
                        })}
                    </View>
                </ScrollView>
            </View>}

            <CustomModal
                visible={showCourseModal}
                onClose={() => {
                    setShowCourseModal(false);
                    setCourseDetails({});
                }}
                title={courseDetails?.name || "Loading..."}
            >
                <View marginT-20 style={{
                    position: 'relative',
                }}>


                    {courseDetails?.name ? <Card padding-20 style={{
                        height: Dimensions.get('window').height - 100,
                    }}>
                        <ScrollView>
                            <View row marginB-10>
                                <View>
                                    {Platform.OS === 'web' ? <div dangerouslySetInnerHTML={{ __html: (courseDetails.description) }} /> : <RenderHtml contentWidth={Dimensions.get('window').width - 40} source={{ html: (courseDetails.description) }} />}
                                </View>
                            </View>

                            <Text h1>Course Sessions</Text>

                            {courseDetails?.CourseSessions?.map((item, index) => {
                                return <View key={index.toString()} marginT-10 row>
                                    <View>
                                        <View style={{
                                            width: 40,
                                            height: 40,
                                            borderRadius: 20,
                                            backgroundColor: '#F5F9FF',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderWidth: 1,
                                            borderColor: '#E8F1FF',
                                        }}>
                                            <Text darkCardTitle>{index + 1}</Text>
                                        </View>
                                    </View>
                                    <View marginL-10>
                                        <View row spread>
                                            <Text darkCardTitle>{item.name}</Text>
                                        </View>
                                        {/* <View row spread>
                                            {Platform.OS === 'web' ? <div dangerouslySetInnerHTML={{ __html: (item.description) }} /> : <RenderHtml contentWidth={Dimensions.get('window').width - 40} source={{ html: (item.description) }} />}
                                        </View> */}
                                        <View row marginT-10 centerV>
                                            <View>
                                                <DateSvg />
                                            </View>
                                            <View marginL-5>
                                                <Text style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 14 }}>
                                                    {moment(item.date).format('DD MMM YYYY hh:mm A')} - {moment(item.date).add(item.duration, 'ms').format('hh:mm A')}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            })}

                        </ScrollView>
                    </Card> : null}
                </View>
            </CustomModal>
        </View>
    )
})

export default TrainingScreen