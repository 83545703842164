// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Dimensions, Alert } from "react-native"
import { api } from "../services/api"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, KeyboardAwareScrollView, TouchableOpacity, Dialog, PanningProvider, Incubator } from 'react-native-ui-lib';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useStores } from "../models"
import { Sidebar } from "./SideBar"
import { Icon } from "react-native-eva-icons"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { GlobalMasterStore } from "../models/PermissionStore"
import { StatusBar } from "expo-status-bar";

const MobileDrawer = observer(function MobileDrawer(props) {
    const { layoutStore: { sideBarOpen, toggleSideBarOpen, closeSideBar } } = useStores();

    const navigation = useNavigation();
    const [screenName, setScreenName] = useState(null);
    const [name, setName] = useState(null);

    const setToken = GlobalMasterStore((state) => state.setToken);
    const globalData = GlobalMasterStore((state) => state.globalData);

    useEffect(() => {
        const organization_id = globalData?.organization_id;
        const organizations = globalData?.organizations;
        const organization = organizations?.find((o) => o.id == organization_id);
        setName(organization?.name);
    }, [globalData]);

    const navigationFn = (path, params) => {
        navigation.push(path, params);
    }

    useEffect(() => {
        return () => {
            if (sideBarOpen) {
                closeSideBar();
            }
        }
    }, []);

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('state', (e) => {
            if (e.data.state?.routes?.length > 0) {
                setScreenName(e.data.state.routes[e.data.state.routes.length - 1].name);
            }
        });

        return () => unsubscribe();
    }, [navigation]);

    return (<>
        <View useSafeArea style={{
            // marginTop: Platform.OS == 'android' ? 15 : 0,

        }}>
            <Incubator.Dialog
                left
                useSafeArea
                height={1800}
                visible={sideBarOpen}
                onDismiss={() => {
                    closeSideBar();
                }}
                direction={PanningProvider.Directions.LEFT}
                containerStyle={{
                    backgroundColor: '#29459C',
                    height: Dimensions.get('window').height,
                    maxHeight: Dimensions.get('window').height,
                    width: Dimensions.get('window').width * 0.85,
                }}
                overlayBackgroundColor="transparent"
            >
                <Sidebar />
            </Incubator.Dialog>

            <View row spread centerV padding-10 style={{
                backgroundColor: "#29459c",
            }}>
                <View style={{
                    width: 100
                }}>
                    <TouchableOpacity padding-10 onPress={() => {
                        toggleSideBarOpen();
                    }}>
                        <Icon name="menu-outline" width={16} height={16} fill={props.darkBg ? "white" : "white"} />
                    </TouchableOpacity>
                </View>


                {/* <Text text90 style={{
                    color: props.darkBg ? "white" : "white",
                }}> {name}  </Text> */}
                {/* cover */}
                <Image cover assetGroup={"images"} assetName="logo" style={{ width: 180, height: 36, maxHeight: 36 }} resizeMethod="resize" resizeMode="contain" />

                <View right style={{
                    width: 100
                }}>
                    {screenName != 'dashboard' ? <TouchableOpacity padding-10 onPress={() => {
                        if (navigation.canGoBack()) {
                            navigation.goBack();
                        } else {
                            navigationFn("dashboard", {});
                        }
                    }}>
                        <View row centerV>
                            <View marginR-5>
                                <Icon name="arrow-back-outline" width={16} height={16} fill={props.darkBg ? "white" : "white"} />
                            </View>
                            <Text style={{
                                color: props.darkBg ? "white" : "white",
                            }}>
                                Back
                            </Text>
                        </View>
                    </TouchableOpacity> : <TouchableOpacity padding-10 onPress={async () => {
                        navigationFn("NotificationScreen", {});
                    }}>
                        <View row centerV>
                            <View marginR-5>
                                {/* <Image source={{ uri: `https://inkapps.pages.dev/icons/logout.png` }} style={{ width: 20, height: 20, marginLeft: 10 }} /> */}
                                <Icon name="bell" width={20} height={20} fill={props.darkBg ? "white" : "white"} />
                            </View>
                        </View>
                    </TouchableOpacity>}
                </View>
            </View>
        </View>
    </>);
});

export default MobileDrawer;