import React, { useEffect, useState } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import ListingScreen from "../../../screens/MainScreens/CRUD/ListingScreen";
import moment from "moment";
import { CustomDatePicker, CustomSelect } from "../../../mycomponents/DynamicForm";

export const coursefields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter Name',
            visible: true,
            width: '100%',
            required: true,
            tab: 'General',
        },
        {
            label: 'Description',
            key: 'description',
            type: 'textarea',
            placeholder: 'Enter Description',
            visible: true,
            width: '100%',
            tab: 'General',
        },
        {
            label: 'Course Group',
            key: 'course_group_id',
            type: 'select',
            placeholder: 'Select Course Group',
            visible: true,
            width: '100%',
            required: true,
            options: response.CourseGroups ? response.CourseGroups.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };

                OpenModalCustom({ newModule: "CourseGroups", field_key: 'course_group_id', params })
            },
            tab: 'General',
        },
        {
            label: 'Type',
            key: 'type',
            type: 'select',
            placeholder: 'Select Type',
            visible: true,
            width: '100%',
            options: [
                { label: 'Internal', value: 'Internal' },
                { label: 'External', value: 'External' },
            ],
            tab: 'General',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                setObj({
                    updateVisiblity: Math.random(),
                })
            }
        },
        {
            label: 'Trainer',
            key: 'trainer_id',
            type: 'select',
            placeholder: 'Select Trainer',
            visible: (connectedObj) => connectedObj.type == 'Internal',
            width: '100%',
            options: response.Trainers ? response.Trainers.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
            tab: 'General',
        },
        {
            label: 'External Trainer Name',
            key: 'external_trainer_name',
            type: 'text',
            placeholder: 'Enter Trainer',
            visible: (connectedObj) => connectedObj.type == 'External',
            width: '100%',
            tab: 'General',
        },
        {
            label: 'Status',
            key: 'status',
            type: 'select',
            placeholder: 'Select Status',
            visible: true,
            width: '100%',
            options: [
                { label: 'Draft', value: 'Draft' },
                { label: 'Published', value: 'Published' },
            ],
            tab: 'General',
        },
        {
            type: 'custom',
            visible: (connectedObj) => {
                if (view) {
                    return true;
                } else {
                    return false;
                }
            },
            width: '100%',
            tab: 'Sessions',
            component: ({ field, useForm, tKey, tIndex }) => {
                const course_id = useForm((state) => state.formObject['_id']);
                const type = useForm((state) => state.formObject['type']);
                const trainer_id = useForm((state) => state.formObject['trainer_id']);
                const external_trainer_name = useForm((state) => state.formObject['external_trainer_name']);

                const props = {
                    moduleName: 'CourseSessions',
                    hideWrapper: true,
                    extraFilters: { course_id: course_id ?? null },
                    hideViews: true,
                }

                return <View w-100 flex>
                    <ListingScreen {...props}
                        title={" "}
                        default_values={{
                            course_id: course_id,
                            type: type,
                            trainer_id: trainer_id,
                            external_trainer_name: external_trainer_name,
                            is_default_values: true
                        }} />
                </View>
            },
        },
        {
            type: 'custom',
            visible: (connectedObj) => {
                if (view) {
                    return true;
                } else {
                    return false;
                }
            },
            width: '100%',
            tab: 'Attendance',
            component: ({ field, useForm, tKey, tIndex }) => {
                const course_id = useForm((state) => state.formObject['_id']);
                const [dates, setDates] = useState([moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
                const [course_session_id, setCourseSessionId] = useState(null);
                const [reloadKey, setReloadKey] = useState(null);

                const extraFilters = {
                    'course_id': course_id ?? null,
                }

                if (course_session_id) {
                    extraFilters['course_session_id'] = course_session_id;
                }

                if (dates && dates.length > 0) {
                    extraFilters['course_session.date'] = {
                        $gte: dates[0],
                        $lte: dates[1],
                    };
                }

                useEffect(() => {
                    setReloadKey(Math.random());
                }, [course_session_id, dates]);

                return <View w-100 flex>
                    <View row spread>
                        <View style={{
                            width: 150,
                        }}>
                            <CustomSelect
                                value={course_session_id}
                                onChange={(value) => {
                                    setCourseSessionId(value);
                                }}
                                placeholder="Select Course Session"
                                label="Select Course Session"
                                options={[...(response.CourseSessions || []).map((item) => {
                                    return { label: item.name, value: item._id }
                                })]}
                            />
                        </View>

                        <View>
                            <CustomDatePicker
                                value={dates}
                                onChange={(value) => {
                                    setDates(value);
                                }}
                                placeholder="Select Date"
                                label="Select Date"
                                range={true}
                            />
                        </View>
                    </View>

                    {reloadKey != null && <ListingScreen
                        key={reloadKey}
                        {...{
                            moduleName: 'CourseSessionAttendances',
                            hideWrapper: true,
                            extraFilters: extraFilters,
                            hideViews: true,
                            hideMainHeader: true,
                        }}
                        title={" "}
                        disabledClone={true}
                        disableDelete={true}
                        default_values={{
                            course_id: course_id,
                            is_default_values: true
                        }}
                        route={{
                            name: 'CourseSessionAttendances',
                            params: {
                                moduleName: 'CourseSessionAttendances',
                                hideWrapper: true,
                                extraFilters: extraFilters,
                                hideViews: true,
                                hideMainHeader: true,
                            }
                        }}
                    />}
                </View>
            },
        },
        {
            label: "FSC's",
            key: 'registered_employee_ids',
            type: 'select',
            placeholder: 'Select FSCs',
            visible: true,
            width: '100%',
            required: true,
            moduleName: moduleName,
            multiple: true,
            tab: 'General',
            options: response.FSCs ? response.FSCs.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
        },
    ];

    if (view) {
        formFields.forEach((field) => {
            if (field.tab == 'General') {
                field.visible = false;
            }
        });
    }

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const courseMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const CustomViewHeader = (props) => {
    // padding: 15px 15px 10px;
    // margin: 10px 10px 0px;
    // background-color: rgb(244, 247, 249);
    // border-radius: 10px 10px 0px 0px;

    return <View padding-15 margin-10 marginB-0 paddingB-10 backgroundColor="rgb(244, 247, 249)" style={{ borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0, position: 'relative', height: 100 }}>
        <View spread row top>
            <View row >
                <View marginT-10>
                    <Text style={{
                        fontFamily: 'SourceSansProSemiBold',
                        fontSize: 22
                    }}>{props?.connectedObject?.name}</Text>
                    <View row centerV>
                        <View style={{
                            backgroundColor: props?.connectedObject?.status == 'Published' ? 'green' : 'gray',
                            padding: 5,
                            paddingHorizontal: 20,
                            borderRadius: 5,
                            marginTop: 5,
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: 10
                        }}>
                            <Text white>{props?.connectedObject?.status}</Text>
                        </View>
                        <View row>
                            <Text darkCardTitle>Created At - </Text>
                            <Text darkCardTitle>{moment(props?.connectedObject?.createdAt).format('DD MMM YYYY hh:mm A')}</Text>
                        </View>
                    </View>
                </View>
            </View>

            {props.HeaderExtra}
        </View>
    </View>;
}

export const courseMain = {
    fields: coursefields,
    CustomViewHeader: CustomViewHeader,
    mobileCard: null
}