// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, ActivityIndicator } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, TouchableOpacity, KeyboardAwareScrollView, RadioButton, RadioGroup } from 'react-native-ui-lib';
import DynamicForm, { CustomCheckbox, CustomEditorField, CustomModal, CustomNumberInput, CustomSelect, CustomTextAreaField, CustomTextField, DangerButton, Label, PrimaryButton, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { App, Input, Popconfirm, Segmented, Steps, Table, Tag, Alert as AlertBox, Timeline, Select } from "antd"
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { Icon } from "react-native-eva-icons"
import { HeaderResearch, PaperDetails } from "./PaperListingScreen"
import { Path, Svg } from "react-native-svg"
import ReactQuill, { Quill } from "react-quill"

import { saveAs } from 'file-saver';
import {
    AlignmentType,
    Document,
    HeadingLevel,
    Packer,
    Paragraph,
    TextRun,
    TableOfContents
} from "docx";

class DocumentCreator {
    create(content) {
        const document = new Document({
            sections: [
                {
                    children: [
                        new Paragraph({
                            text: "Research Outline",
                            heading: HeadingLevel.TITLE,
                            alignment: AlignmentType.CENTER
                        }),
                        new TableOfContents("Table of Contents"),
                        ...this.createContent(content)
                    ]
                }
            ]
        });

        return document;
    }

    createContent(content) {
        return content.flatMap((chapter, chapterIndex) => [
            new Paragraph({
                text: chapter.chapter_title,
                heading: HeadingLevel.HEADING_1,
                spacing: { before: 400, after: 200 }
            }),
            ...chapter.heading.flatMap((heading, headingIndex) => {
                const paragraphs = [
                    new Paragraph({
                        text: heading,
                        heading: HeadingLevel.HEADING_2,
                        spacing: { before: 300, after: 120 }
                    })
                ];

                if (chapter.content && chapter.content[headingIndex]) {
                    const contentParagraphs = this.parseContent(chapter.content[headingIndex].content);
                    paragraphs.push(...contentParagraphs);
                }

                return paragraphs;
            })
        ]);
    }

    parseContent(htmlContent) {
        // Replace HTML entities and line breaks
        let content = htmlContent
            .replace(/&nbsp;/g, ' ')
            .replace(/<br\s*\/?>/gi, '\n')
            .replace(/<\/p><p>/gi, '\n\n')
            .replace(/\\\\+/g, '\n')
            .replace(/<[^>]+>/g, '');

        // Split content into paragraphs
        return content.split(/\n+/).map(paragraph =>
            new Paragraph({
                children: [new TextRun(paragraph.trim())],
                spacing: { before: 100, after: 100 }
            })
        );
    }
}

const ExportButton = ({ content }) => {
    const generateDocx = () => {
        const documentCreator = new DocumentCreator();
        const doc = documentCreator.create(content);

        Packer.toBlob(doc).then(blob => {
            saveAs(blob, "research_outline.docx");
            console.log("Document created successfully");
        });
    };

    return (
        <PrimaryButton onPress={generateDocx} label={"Export"} />
    );
};

const WorksWritingScreen = observer(function WorksWritingScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const permissions = GlobalMasterStore((state) => state.permissions);
    const setPermissions = GlobalMasterStore((state) => state.setPermissions);

    const token = GlobalMasterStore((state) => state.token);
    const setToken = GlobalMasterStore((state) => state.setToken);

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const [search, setSearch] = useState("");
    const [password, setPassword] = useState("");
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [sendingOTP, setSendingOTP] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [forgotEmail, setForgotEmail] = useState("");
    const [forgotOTP, setForgotOTP] = useState("");
    const [forgotPassword, setForgotPassword] = useState("");
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const email = GlobalMasterStore((state) => state.globalData.email);
    const [selectedPaper, setSelectedPaper] = useState(null);
    const [showPaperModal, setShowPaperModal] = useState(false);
    const [tags, setTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);

    const [papers, setPapers] = useState([]);
    const [filteredPapers, setFilteredPapers] = useState([]);
    const [works, setWorks] = useState([]);
    const [showNewWorkModal, setShowNewWorkModal] = useState(false);
    const [workName, setWorkName] = useState("");
    const [selectedWork, setSelectedWork] = useState(null);
    const [currentWork, setCurrentWork] = useState(null);
    const [selectedSegment, setSelectedSegment] = useState("Research Writing");
    const [selectedChapterIndex, setSelectedChapterIndex] = useState(null);
    const [selectedHeadingIndex, setSelectedHeadingIndex] = useState(null);
    const [currentStep, setCurrentStep] = useState(4);
    const [showAddFromFolderModal, setShowAddFromFolderModal] = useState(false);
    const [folders, setFolders] = useState([]);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [showAddToFolderModal, setShowAddToFolderModal] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [searchingInspirations, setSearchingInspirations] = useState(false)
    const [searchingTopics, setSearchingTopics] = useState(false)
    const [generatingTopicDetails, setGeneratingTopicDetails] = useState(false)
    const [newTopic, setNewTopic] = useState("");
    const [generatingResearchOutline, setGeneratingResearchOutline] = useState(false);
    const saveWorkTimerRef = useRef(null);
    const [showAiFeedbackModal, setShowAiFeedbackModal] = useState(false);

    const [aiFeedback, setAiFeedback] = useState("");
    const [aiFeedbackQuery, setAiFeedbackQuery] = useState("");
    const [aiThinking, setAiThinking] = useState(false);

    const [showAiTopicFeedbackModal, setShowAiTopicFeedbackModal] = useState(false);
    const [aiTopicFeedback, setAiTopicFeedback] = useState("");
    const [aiTopicFeedbackQuery, setAiTopicFeedbackQuery] = useState("");
    const [aiTopicThinking, setAiTopicThinking] = useState(false);

    const getFolders = async () => {
        const res = await api.customRoute("get-folders", {
            email_id: email,
        });

        setFolders(res.folders);
        setPapers(res.papers);
        setFilteredPapers(res.papers);
    }

    useEffect(() => {
        setFilteredPapers(papers.filter((paper) => paper.paper_details.title.toLowerCase().includes(search.toLowerCase())));
    }, [search]);

    const getWorks = async () => {
        const res = await api.customRoute("all-works", {
            email_id: email,
        });

        setWorks(res.works);
        setCurrentWork(res.works[0]);
    }

    useEffect(() => {
        getWorks();
        getFolders();
    }, [email]);

    const getPaperDetails = async (paper_id) => {
        const res = await api.customRoute("get-paper-details", {
            paper_id: paper_id,
        });

        console.log(res);


        setSelectedPaper({
            ...selectedPaper,
            ...res.paper,
        });
    }

    const searchPapersBasedOnResearchProfiling = async () => {
        if (currentWork) {
            setSearchingInspirations(true);

            try {
                const res = await api.customRoute("search-papers-based-on-research-profiling", {
                    profile: currentWork.questionnaire,
                    email: email,
                });

                const titles = res.selectedTitles;

                setCurrentWork({
                    ...currentWork,
                    inspiration_suggestions: titles,
                    current_step_number: 1,
                });
    
                saveWork({
                    ...currentWork,
                    inspiration_suggestions: titles,
                    current_step_number: 1,
                });
    
                setSearchingInspirations(false);
            } catch (error) {
                if (error.response.status == 402) {
                    if (Platform.OS === 'web') {
                        message.error("Insufficient balance");
                    } else {
                        Alert.alert("Insufficient balance");
                    }
                } else {
                    if (Platform.OS === 'web') {
                        message.error("Something went wrong");
                    } else {
                        Alert.alert("Something went wrong");
                    }
                }

                setSearchingInspirations(false);
                return;
            }
        }
    }

    const generateResearchPaperTopicsBasedOnResearchProfilingAndUserLovedPapers = async () => {
        if (currentWork) {
            setSearchingTopics(true);

            try {
                const res = await api.customRoute("generate-research-paper-topics-based-on-research-profiling-and-user-loved-papers", {
                    profile: currentWork.questionnaire,
                    inspirations: currentWork.inspirations.map((inspiration) => inspiration.title),
                    rejected_inspirations: currentWork.inspiration_suggestions.filter((inspiration) => !currentWork.inspirations.map((inspiration) => inspiration.title).includes(inspiration)).map((inspiration) => inspiration.title),
                    email: email,
                });

                const generated_topic_arrays = res.generated_topic_arrays;

                setCurrentWork({
                    ...currentWork,
                    generated_topics: generated_topic_arrays,
                    current_step_number: 2,
                });

                saveWork({
                    ...currentWork,
                    generated_topics: generated_topic_arrays,
                    current_step_number: 2,
                });

                setSearchingTopics(false);
            } catch (error) {
                if (error.response.status == 402) {
                    if (Platform.OS === 'web') {
                        message.error("Insufficient balance");
                    } else {
                        Alert.alert("Insufficient balance");
                    }
                } else {
                    if (Platform.OS === 'web') {
                        message.error("Something went wrong");
                    } else {
                        Alert.alert("Something went wrong");
                    }
                }

                setSearchingTopics(false);
                return;
            }
        }
    }

    const generateTopicsDetails = async (currentWork) => {
        if (currentWork) {
            setGeneratingTopicDetails(true);

            try {
                const res = await api.customRoute("generate-topic-details", {
                    profile: currentWork.questionnaire,
                    inspirations: currentWork.inspirations.map((inspiration) => inspiration.title),
                    selected_topics: currentWork.selected_topics.map((topic) => topic.title),
                    email: email,
                });

                setCurrentWork({
                    ...currentWork,
                    selected_topics: res.generated_topic_arrays,
                });
    
                saveWork({
                    ...currentWork,
                    selected_topics: res.generated_topic_arrays,
                });
    
                setGeneratingTopicDetails(false);
            } catch (error) {
                if (error.response.status == 402) {
                    if (Platform.OS === 'web') {
                        message.error("Insufficient balance");
                    } else {
                        Alert.alert("Insufficient balance");
                    }
                } else {
                    if (Platform.OS === 'web') {
                        message.error("Something went wrong");
                    } else {
                        Alert.alert("Something went wrong");
                    }
                }

                setGeneratingTopicDetails(false);
                return;
            }
        }
    }

    const aiFeedbackForTopic = async (currentWork) => {
        if (currentWork) {
            setAiThinking(true);

            try {
                const res = await api.customRoute("ai-feedback-for-topic", {
                    profile: currentWork.questionnaire,
                    selected_topic: currentWork.selected_topics[0],
                    email: email,
                });
    
                setAiTopicFeedback(res.answer);
                setAiTopicThinking(false);
            } catch (error) {
                if (error.response.status == 402) {
                    if (Platform.OS === 'web') {
                        message.error("Insufficient balance");
                    } else {
                        Alert.alert("Insufficient balance");
                    }
                } else {
                    if (Platform.OS === 'web') {
                        message.error("Something went wrong");
                    } else {
                        Alert.alert("Something went wrong");
                    }
                }

                setAiTopicThinking(false);
                return;
            }
        }
    }

    const generateResearchOutlineBasedOnResearchProfilingAndUserLovedPapersAndSelectedTopic = async (selected_topic, currentWork) => {
        if (currentWork) {
            setGeneratingResearchOutline(true);

            try {
                const res = await api.customRoute("generate-research-outline-based-on-research-profiling-and-user-loved-papers-and-selected-topic", {
                    profile: currentWork.questionnaire,
                    inspirations: currentWork.inspirations.map((inspiration) => inspiration.title),
                    selected_topic: selected_topic,
                    email: email,
                });
    
                setCurrentWork({
                    ...currentWork,
                    research_outline: res.research_outline,
                    current_step_number: 3,
                });
    
                saveWork({
                    ...currentWork,
                    research_outline: res.research_outline,
                    current_step_number: 3,
                });
    
                setGeneratingResearchOutline(false);
            } catch (error) {
                if (error.response.status == 402) {
                    if (Platform.OS === 'web') {
                        message.error("Insufficient balance");
                    } else {
                        Alert.alert("Insufficient balance");
                    }
                } else {
                    if (Platform.OS === 'web') {
                        message.error("Something went wrong");
                    } else {
                        Alert.alert("Something went wrong");
                    }
                }

                setGeneratingResearchOutline(false);
                return;
            }
        }
    }

    const assistWritingResearchPaper = async (chapter, heading, current_content, user_query, currentWork) => {
        if (currentWork) {
            setAiThinking(true);

            try {
                const res = await api.customRoute("assist-writing-research-paper", {
                    profile: currentWork.questionnaire,
                    research_outline: currentWork.research_outline,
                    chapter: chapter,
                    heading: heading,
                    current_content: current_content,
                    user_query: user_query,
                    email: email,
                });
    
                setAiFeedback(res.answer);
                setAiThinking(false);
            } catch (error) {
                if (error.response.status == 402) {
                    if (Platform.OS === 'web') {
                        message.error("Insufficient balance");
                    } else {
                        Alert.alert("Insufficient balance");
                    }
                } else {
                    if (Platform.OS === 'web') {
                        message.error("Something went wrong");
                    } else {
                        Alert.alert("Something went wrong");
                    }
                }

                setAiThinking(false);
                return;
            }
        }
    }

    const checkStep1Completion = (work) => {
        if (!work.questionnaire?.research_type) {
            return {
                status: false,
                message: "Please select a research type"
            };
        }

        if (!work.questionnaire?.methodology_preference) {
            return {
                status: false,
                message: "Please select a methodology preference"
            };
        }

        if (!work.questionnaire?.research_methods || work.questionnaire?.research_methods.length == 0) {
            return {
                status: false,
                message: "Please select at least one research method"
            };
        }

        if (!work.questionnaire?.research_area) {
            return {
                status: false,
                message: "Please select a research area"
            };
        }

        if (!work.questionnaire?.specific_subfields || work.questionnaire?.specific_subfields.length == 0) {
            return {
                status: false,
                message: "Please enter a specific subfield"
            };
        }

        if (!work.questionnaire?.research_goals || work.questionnaire?.research_goals.length == 0) {
            return {
                status: false,
                message: "Please select at least one research goal"
            };
        }

        searchPapersBasedOnResearchProfiling();

        return {
            status: true,
        };

    }

    const saveWork = async (newWork = false) => {
        const res = await api.customRoute("save-work", {
            work: newWork ? newWork : currentWork
        });
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => {
                return <TouchableOpacity onPress={() => {
                    setSelectedPaper(record);
                    setShowPaperModal(true);
                    getPaperDetails(record.id);
                    setTags(record.tags || []);
                }}>
                    <Text style={{
                        color: "rgb(38, 114, 172)",
                        fontFamily: "SourceSansProSemiBold",
                        letterSpacing: 0.5,
                    }}>
                        {record.title}
                    </Text>
                </TouchableOpacity>
            },
        },
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
            render: (text, record) => {
                return <Text>{record.publication_year}</Text>
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return <View row centerV>
                    <View>
                        <Popconfirm
                            title="Are you sure you want to delete this paper?"
                            onConfirm={async () => {
                                setCurrentWork({
                                    ...currentWork,
                                    inspirations: currentWork.inspirations.filter((inspiration) => inspiration.id !== record.id),
                                });

                                saveWork({
                                    ...currentWork,
                                    inspirations: currentWork.inspirations.filter((inspiration) => inspiration.id !== record.id),
                                });
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 5,
                                paddingHorizontal: 10,
                                marginLeft: 10,
                                borderRadius: 5,
                                borderWidth: 1,
                                borderColor: "#dcdcdc80",
                                cursor: 'pointer',
                            }}>
                                <Icon name="trash-2-outline" width={16} height={16} />
                            </View>
                        </Popconfirm>
                    </View>
                </View>
            },
        },
    ];

    const topic_columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => {
                return <Text style={{
                    fontFamily: "SourceSansProSemiBold",
                    letterSpacing: 0.5,
                }}>
                    {record.title}
                </Text>
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return <View row centerV>
                    <View>
                        <Popconfirm
                            title="Are you sure you want to delete this topic?"
                            onConfirm={async () => {
                                setCurrentWork({
                                    ...currentWork,
                                    selected_topics: currentWork.selected_topics.filter((topic) => topic.title !== record.title),
                                });

                                saveWork({
                                    ...currentWork,
                                    selected_topics: currentWork.selected_topics.filter((topic) => topic.title !== record.title),
                                });
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 5,
                                paddingHorizontal: 10,
                                marginLeft: 10,
                                borderRadius: 5,
                                borderWidth: 1,
                                borderColor: "#dcdcdc80",
                                cursor: 'pointer',
                            }}>
                                <Icon name="trash-2-outline" width={16} height={16} />
                            </View>
                        </Popconfirm>
                    </View>
                </View>
            },
        },
    ];

    // use saveWorkTimerRef to save work after debouncing it for 2 seconds
    useEffect(() => {
        if (saveWorkTimerRef.current) {
            clearTimeout(saveWorkTimerRef.current);
        }

        saveWorkTimerRef.current = setTimeout(() => {
            saveWork();
        }, 2000);

        return () => {
            clearTimeout(saveWorkTimerRef.current);
        }
    }, [currentWork]);

    return (<View center style={{
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    }}>
        <SafeAreaView style={{
            width: "100%",
            height: Dimensions.get('window').height,
        }}>
            <KeyboardAwareScrollView style={{
                width: "100%",
                height: Dimensions.get('window').height,
            }}>
                <View row style={{
                    width: "100%",
                    height: Dimensions.get('window').height,
                }}>
                    <View flexG
                        style={{
                            width: "100%",
                            height: Platform.OS == 'web' ? Dimensions.get('window').height : undefined,
                            position: 'relative',
                            zIndex: 1,
                        }}
                    >
                        <HeaderResearch navigationFn={navigationFn} showPaperCount={true} />

                        <View row style={{
                            flexWrap: 'wrap',
                            padding: 20,
                        }}>
                            <View style={{
                                width: 300
                            }}>

                                {works.length > 0 ? works.map((work, index) => {
                                    return <View key={index} style={{
                                        width: "100%",
                                        backgroundColor: work._id === currentWork ? "#20303c0d" : "transparent",
                                        paddingHorizontal: 10,
                                        borderRadius: 5,
                                    }}>
                                        <View style={{
                                            width: "100%",
                                            height: 1.5,
                                            backgroundColor: "#ccc",
                                            marginTop: 10,
                                            opacity: 0,
                                        }}></View>
                                        <View row centerV>
                                            <TouchableOpacity style={{
                                                flexGrow: 1,
                                            }}
                                                onPress={() => {
                                                    setCurrentWork(work);
                                                }}
                                            >
                                                <View flexG row centerV>
                                                    <Image source={{
                                                        uri: "https://cdn-icons-png.flaticon.com/512/3281/3281289.png"
                                                    }}
                                                        style={{
                                                            width: 20,
                                                            height: 20,
                                                        }}
                                                    />
                                                    <Text marginL-5 style={{
                                                        fontFamily: "SourceSansProSemiBold",
                                                        letterSpacing: 0.5,
                                                    }}>
                                                        {work.name}
                                                    </Text>
                                                </View>
                                            </TouchableOpacity>
                                            <View marginL-5>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        setSelectedWork(work);
                                                        setWorkName(work.name);
                                                        setShowNewWorkModal(true);
                                                    }}
                                                >
                                                    <Icon name="edit-outline" width={16} height={16} />
                                                </TouchableOpacity>
                                            </View>
                                            <View marginL-5>
                                                <Popconfirm
                                                    title="Are you sure you want to delete this work?"
                                                    onConfirm={async () => {
                                                        const res = await api.customRoute("delete-work", {
                                                            email_id: email,
                                                            work_id: work._id,
                                                        });

                                                        if (res.message) {
                                                            if (Platform.OS === 'web') {
                                                                message.success(res.message);
                                                            } else {
                                                                Alert.alert(res.message);
                                                            }

                                                            getWorks();
                                                        } else {
                                                            if (Platform.OS === 'web') {
                                                                message.success("Something went wrong");
                                                            } else {
                                                                Alert.alert("Something went wrong");
                                                            }
                                                        }
                                                    }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Icon name="trash-2-outline" width={16} height={16} />
                                                </Popconfirm>
                                            </View>
                                        </View>

                                        <View style={{
                                            width: "100%",
                                            height: 1.5,
                                            backgroundColor: "#000",
                                            marginTop: 10,
                                            opacity: 0.05,
                                        }}></View>
                                    </View>
                                }) : <View center style={{
                                    width: "100%",
                                    paddingVertical: 20,
                                    borderWidth: 1,
                                    borderColor: "#ccc",
                                    borderStyle: "dashed",
                                }}>
                                    <Text style={{
                                        fontFamily: "SourceSansProSemiBold",
                                        letterSpacing: 0.5,
                                    }}>
                                        No work found
                                    </Text>
                                </View>}

                                <View marginT-40>
                                    <PrimaryButton
                                        label="New Ideation"
                                        onPress={() => {
                                            setShowNewWorkModal(true);
                                        }}
                                    />
                                </View>
                            </View>
                            <View flex paddingL-40>
                                <Card padding-20>
                                    <View center style={{
                                        width: "100%",
                                        borderBottomWidth: 1,
                                        borderBottomColor: "rgba(0, 0, 0, 0.07)",
                                        borderTopWidth: 1,
                                        borderTopColor: "rgba(0, 0, 0, 0.07)",
                                        paddingVertical: 10,
                                    }}>
                                        <Text style={{
                                            fontFamily: "SourceSansProSemiBold",
                                            letterSpacing: 0.5,
                                        }}>
                                            {currentWork ? currentWork.name : "Create or select a work to start"}
                                        </Text>
                                    </View>
                                    {currentWork ? <View>
                                        {/* <Steps
                                            current={currentStep}
                                            size="small"
                                            style={{
                                                width: "100%",
                                            }}
                                            onChange={(current) => {
                                                if (currentWork.current_step_number > current) {
                                                    setCurrentStep(current);
                                                } else {
                                                    if (currentWork.current_step_number == current) {
                                                        setCurrentStep(current);
                                                    } else {
                                                        if (Platform.OS === 'web') {
                                                            message.error("Please complete the previous step first");
                                                        } else {
                                                            Alert.alert("Please complete the previous step first");
                                                        }
                                                    }
                                                }
                                            }}
                                        >
                                            <Steps.Step title="Research Profiling" />
                                            <Steps.Step title="Papers" />
                                            <Steps.Step title="Topic Selection" />
                                            <Steps.Step title="Research Outline" />
                                            <Steps.Step title="Research Writing" />
                                        </Steps> */}



                                        <View>
                                            {currentStep == 0 && <>
                                                <AlertBox type="info" showIcon
                                                    message="Research Profiling"
                                                    description="We will ask you a few questions to understand your research requirements. This will help us in providing you with the best possible research assistance."
                                                />
                                                <View>

                                                    {Label({ marginTop: 10, fontSize: 18, label: "Research Type" })}

                                                    <RadioGroup initialValue={currentWork.questionnaire?.research_type} style={{
                                                        width: "100%",
                                                    }}
                                                        onValueChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_type: value,
                                                                }
                                                            });
                                                        }}>
                                                        <RadioButton size={20} marginT-5 value={"Primary Research"} label="Primary Research" />
                                                        <RadioButton size={20} marginT-5 value={"Secondary Research"} label="Secondary Research" />
                                                    </RadioGroup>

                                                    {Label({ marginTop: 10, fontSize: 18, label: "Methodology Preference" })}

                                                    <RadioGroup initialValue={currentWork.questionnaire?.methodology_preference} style={{
                                                        width: "100%"
                                                    }}
                                                        onValueChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    methodology_preference: value,
                                                                }
                                                            });
                                                        }}>
                                                        <RadioButton size={20} marginT-5 value={"Qualitative"} label="Qualitative" />
                                                        <RadioButton size={20} marginT-5 value={"Quantitative"} label="Quantitative" />
                                                        <RadioButton size={20} marginT-5 value={"Mixed Methods (Qualitative and Quantitative)"} label="Mixed Methods" />
                                                    </RadioGroup>

                                                    {Label({ marginTop: 10, fontSize: 18, label: "Specific Research Methods" })}

                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.research_methods || []).includes("Surveys")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_methods: value ? [...(currentWork.questionnaire?.research_methods || []), "Surveys"] : currentWork.questionnaire.research_methods.filter((method) => method !== "Surveys"),
                                                                }
                                                            });
                                                        }} />
                                                        <View marginL-10>
                                                            <Text>Surveys</Text>
                                                        </View>
                                                    </View>

                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.research_methods || []).includes("Interviews")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_methods: value ? [...(currentWork.questionnaire?.research_methods || []), "Interviews"] : currentWork.questionnaire.research_methods.filter((method) => method !== "Interviews"),
                                                                }
                                                            });
                                                        }} />
                                                        <View marginL-10>
                                                            <Text>Interviews</Text>
                                                        </View>
                                                    </View>

                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.research_methods || []).includes("Experiments")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_methods: value ? [...(currentWork.questionnaire?.research_methods || []), "Experiments"] : currentWork.questionnaire.research_methods.filter((method) => method !== "Experiments"),
                                                                }
                                                            });
                                                        }} />
                                                        <View marginL-10>
                                                            <Text>Experiments</Text>
                                                        </View>
                                                    </View>

                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.research_methods || []).includes("Observational studies")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_methods: value ? [...(currentWork.questionnaire?.research_methods || []), "Observational studies"] : currentWork.questionnaire.research_methods.filter((method) => method !== "Observational studies"),
                                                                }
                                                            });
                                                        }} />
                                                        <View marginL-10>
                                                            <Text>Observational studies</Text>
                                                        </View>
                                                    </View>

                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.research_methods || []).includes("Case studies")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_methods: value ? [...(currentWork.questionnaire?.research_methods || []), "Case studies"] : currentWork.questionnaire.research_methods.filter((method) => method !== "Case studies"),
                                                                }
                                                            });
                                                        }} />
                                                        <View marginL-10>
                                                            <Text>Case studies</Text>
                                                        </View>
                                                    </View>

                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.research_methods || []).includes("Literature review")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_methods: value ? [...(currentWork.questionnaire?.research_methods || []), "Literature review"] : currentWork.questionnaire.research_methods.filter((method) => method !== "Literature review"),
                                                                }
                                                            });
                                                        }} />
                                                        <View marginL-10>
                                                            <Text>Literature review</Text>
                                                        </View>
                                                    </View>

                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.research_methods || []).includes("Meta-analysis")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_methods: value ? [...(currentWork.questionnaire?.research_methods || []), "Meta-analysis"] : currentWork.questionnaire.research_methods.filter((method) => method !== "Meta-analysis"),
                                                                }
                                                            });
                                                        }} />
                                                        <View marginL-10>
                                                            <Text>Meta-analysis</Text>
                                                        </View>
                                                    </View>

                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.research_methods || []).includes("Data mining of existing datasets")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_methods: value ? [...(currentWork.questionnaire?.research_methods || []), "Data mining of existing datasets"] : currentWork.questionnaire.research_methods.filter((method) => method !== "Data mining of existing datasets"),
                                                                }
                                                            });
                                                        }} />

                                                        <View marginL-10>
                                                            <Text>Data mining of existing datasets</Text>
                                                        </View>
                                                    </View>

                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.research_methods || []).includes("Action research")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_methods: value ? [...(currentWork.questionnaire?.research_methods || []), "Action research"] : currentWork.questionnaire.research_methods.filter((method) => method !== "Action research"),
                                                                }
                                                            });
                                                        }} />
                                                        <View marginL-10>
                                                            <Text>Action research</Text>
                                                        </View>
                                                    </View>

                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.research_methods || []).includes("Other")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_methods: value ? [...(currentWork.questionnaire?.research_methods || []), "Other"] : currentWork.questionnaire.research_methods.filter((method) => method !== "Other"),
                                                                }
                                                            });
                                                        }} />
                                                        <View marginL-10>
                                                            <Text>Other</Text>
                                                        </View>
                                                    </View>

                                                    {(currentWork.questionnaire?.research_methods || []).includes("Other") && <View marginT-10>
                                                        <CustomTextField value={currentWork.questionnaire?.research_methods_other} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_methods_other: value,
                                                                }
                                                            });
                                                        }} />
                                                    </View>}

                                                    {Label({ marginTop: 10, fontSize: 18, label: "Research Area" })}

                                                    <RadioGroup initialValue={currentWork.questionnaire?.research_area} style={{
                                                        width: "100%",
                                                    }}
                                                        onValueChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_area: value,
                                                                }
                                                            });
                                                        }}>
                                                        <RadioButton size={20} marginT-5 value={"Life Sciences"} label="Life Sciences" />
                                                        <RadioButton size={20} marginT-5 value={"Social Sciences"} label="Social Sciences" />
                                                        <RadioButton size={20} marginT-5 value={"Physical Sciences"} label="Physical Sciences" />
                                                        <RadioButton size={20} marginT-5 value={"Engineering"} label="Engineering" />
                                                        <RadioButton size={20} marginT-5 value={"Humanities"} label="Humanities" />
                                                        <RadioButton size={20} marginT-5 value={"Interdisciplinary"} label="Interdisciplinary" />
                                                    </RadioGroup>

                                                    {Label({ marginTop: 10, fontSize: 18, label: "Specific Subfield" })}

                                                    {/* <CustomTextField value={currentWork.questionnaire?.specific_subfield} onChange={(value) => {
                                                    setCurrentWork({
                                                        ...currentWork,
                                                        questionnaire: {
                                                            ...(currentWork.questionnaire || {}),
                                                            specific_subfield: value,
                                                        }
                                                    });
                                                }} /> */}


                                                    <Select
                                                        mode="tags"
                                                        style={{ width: '100%' }}
                                                        placeholder="Select a specific subfields"
                                                        onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    specific_subfields: value,
                                                                }
                                                            });
                                                        }}
                                                        options={[]}
                                                        value={currentWork.questionnaire?.specific_subfields || []}
                                                    />


                                                    {/* <CustomSelect
                                                        value={currentWork.questionnaire?.specific_subfield}
                                                        onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    specific_subfield: value,
                                                                }
                                                            });
                                                        }}
                                                        options={[]}
                                                        searchRoute={"https://api.openalex.org/topics?filter=display_name.search:SEARCH_WORD"}
                                                        searchRouteExternal={"https://api.openalex.org/topics?filter=display_name.search:SEARCH_WORD"}
                                                        searchRouteExternalProcessor={(res) => {
                                                            return res.results.map((topic) => ({
                                                                label: topic.display_name,
                                                                value: topic.display_name,
                                                            }));
                                                        }}
                                                    /> */}

                                                    {Label({ marginTop: 10, fontSize: 18, label: "Research Goals" })}

                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.research_goals || []).includes("Exploratory")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_goals: value ? [...(currentWork.questionnaire?.research_goals || []), "Exploratory"] : currentWork.questionnaire.research_goals.filter((goal) => goal !== "Exploratory"),
                                                                }
                                                            });
                                                        }} />
                                                        <View marginL-10>
                                                            <Text>Exploratory</Text>
                                                        </View>
                                                    </View>

                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.research_goals || []).includes("Descriptive")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_goals: value ? [...(currentWork.questionnaire?.research_goals || []), "Descriptive"] : currentWork.questionnaire.research_goals.filter((goal) => goal !== "Descriptive"),
                                                                }
                                                            });
                                                        }} />
                                                        <View marginL-10>
                                                            <Text>Descriptive</Text>
                                                        </View>
                                                    </View>

                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.research_goals || []).includes("Explanatory")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_goals: value ? [...(currentWork.questionnaire?.research_goals || []), "Explanatory"] : currentWork.questionnaire.research_goals.filter((goal) => goal !== "Explanatory"),
                                                                }
                                                            });
                                                        }} />
                                                        <View marginL-10>
                                                            <Text>Explanatory</Text>
                                                        </View>
                                                    </View>

                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.research_goals || []).includes("Predictive")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_goals: value ? [...(currentWork.questionnaire?.research_goals || []), "Predictive"] : currentWork.questionnaire.research_goals.filter((goal) => goal !== "Predictive"),
                                                                }
                                                            });
                                                        }} />
                                                        <View marginL-10>
                                                            <Text>Predictive</Text>
                                                        </View>
                                                    </View>

                                                    {Label({ marginTop: 10, fontSize: 18, label: "Available Resources" })}

                                                    <View style={{
                                                        borderWidth: 1,
                                                        borderColor: "rgba(0, 0, 0, 0.1)",
                                                        borderStyle: "dashed",
                                                        padding: 15
                                                    }}>
                                                        <View>
                                                            {Label({ fontSize: 18, label: "Time frame" })}
                                                            <RadioGroup initialValue={currentWork.questionnaire?.time_frame} style={{
                                                                width: "100%",
                                                            }}
                                                                onValueChange={(value) => {
                                                                    setCurrentWork({
                                                                        ...currentWork,
                                                                        questionnaire: {
                                                                            ...(currentWork.questionnaire || {}),
                                                                            time_frame: value,
                                                                        }
                                                                    });
                                                                }}>
                                                                <RadioButton size={20} marginT-5 value={"Short-term (< 6 months)"} label="Short-term (< 6 months)" />
                                                                <RadioButton size={20} marginT-5 value={"Medium-term (6-12 months)"} label="Medium-term (6-12 months)" />
                                                                <RadioButton size={20} marginT-5 value={"Long-term (> 1 year)"} label="Long-term (> 1 year)" />
                                                            </RadioGroup>
                                                        </View>

                                                        <View marginT-5>
                                                            {Label({ marginTop: 10, fontSize: 18, label: "Funding" })}
                                                            <RadioGroup initialValue={currentWork.questionnaire?.funding} style={{
                                                                width: "100%",
                                                            }}
                                                                onValueChange={(value) => {
                                                                    setCurrentWork({
                                                                        ...currentWork,
                                                                        questionnaire: {
                                                                            ...(currentWork.questionnaire || {}),
                                                                            funding: value,
                                                                        }
                                                                    });
                                                                }}>
                                                                <RadioButton size={20} marginT-5 value={"Self-funded"} label="Self-funded" />
                                                                <RadioButton size={20} marginT-5 value={"Grant-supported"} label="Grant-supported" />
                                                                <RadioButton size={20} marginT-5 value={"Institutional funding"} label="Institutional funding" />
                                                                <RadioButton size={20} marginT-5 value={"Other"} label="Other" />
                                                            </RadioGroup>
                                                        </View>

                                                        {(currentWork.questionnaire?.funding || "") == "Other" && <View marginT-5>
                                                            {Label({ marginTop: 10, fontSize: 18, label: "Other" })}
                                                            <CustomTextField value={currentWork.questionnaire?.funding_other} onChange={(value) => {
                                                                setCurrentWork({
                                                                    ...currentWork,
                                                                    questionnaire: {
                                                                        ...(currentWork.questionnaire || {}),
                                                                        funding_other: value,
                                                                    }
                                                                });
                                                            }} />
                                                        </View>}
                                                    </View>

                                                    <View marginT-5>
                                                        {Label({ marginTop: 10, fontSize: 18, label: "Access to specific equipment or populations" })}
                                                        <CustomTextField value={currentWork.questionnaire?.access_to_equipment} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    access_to_equipment: value,
                                                                }
                                                            });
                                                        }} />
                                                    </View>

                                                    {Label({ marginTop: 10, fontSize: 18, label: "Ethical Considerations" })}
                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.ethical_considerations || []).includes("Involvement of human subjects")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    ethical_considerations: value ? [...(currentWork.questionnaire?.ethical_considerations || []), "Involvement of human subjects"] : currentWork.questionnaire.ethical_considerations.filter((consideration) => consideration !== "Involvement of human subjects"),
                                                                }
                                                            });
                                                        }} />
                                                        <View marginL-10>
                                                            <Text>Involvement of human subjects</Text>
                                                        </View>
                                                    </View>

                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.ethical_considerations || []).includes("Use of sensitive data")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    ethical_considerations: value ? [...(currentWork.questionnaire?.ethical_considerations || []), "Use of sensitive data"] : currentWork.questionnaire.ethical_considerations.filter((consideration) => consideration !== "Use of sensitive data"),
                                                                }
                                                            });
                                                        }} />
                                                        <View marginL-10>
                                                            <Text>Use of sensitive data</Text>
                                                        </View>
                                                    </View>

                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.ethical_considerations || []).includes("Environmental impact")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    ethical_considerations: value ? [...(currentWork.questionnaire?.ethical_considerations || []), "Environmental impact"] : currentWork.questionnaire.ethical_considerations.filter((consideration) => consideration !== "Environmental impact"),
                                                                }
                                                            });
                                                        }} />
                                                        <View marginL-10>
                                                            <Text>Environmental impact</Text>
                                                        </View>
                                                    </View>

                                                    <View row marginT-5>
                                                        <CustomCheckbox value={(currentWork.questionnaire?.ethical_considerations || []).includes("Other")} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    ethical_considerations: value ? [...(currentWork.questionnaire?.ethical_considerations || []), "Other"] : currentWork.questionnaire.ethical_considerations.filter((consideration) => consideration !== "Other"),
                                                                }
                                                            });
                                                        }} />
                                                        <View marginL-10>
                                                            <Text>Other</Text>
                                                        </View>
                                                    </View>

                                                    {(currentWork.questionnaire?.ethical_considerations || []).includes("Other") && <View marginT-10>
                                                        <CustomTextField value={currentWork.questionnaire?.ethical_considerations_other} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    ethical_considerations_other: value,
                                                                }
                                                            });
                                                        }} />
                                                    </View>}

                                                    {Label({ marginTop: 10, fontSize: 18, label: "Research Experience Level" })}
                                                    <RadioGroup initialValue={currentWork.questionnaire?.research_experience_level} style={{
                                                        width: "100%",
                                                    }}
                                                        onValueChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    research_experience_level: value,
                                                                }
                                                            });
                                                        }}>
                                                        <RadioButton size={20} marginT-5 value={"Novice (e.g., Undergraduate student)"} label="Novice (e.g., Undergraduate student)" />
                                                        <RadioButton size={20} marginT-5 value={"Intermediate (e.g., Graduate student, Early career researcher)"} label="Intermediate (e.g., Graduate student, Early career researcher)" />
                                                        <RadioButton size={20} marginT-5 value={"Advanced (e.g., Postdoctoral researcher, Assistant Professor)"} label="Advanced (e.g., Postdoctoral researcher, Assistant Professor)" />
                                                        <RadioButton size={20} marginT-5 value={"Expert (e.g., Associate/Full Professor, Senior Researcher)"} label="Expert (e.g., Associate/Full Professor, Senior Researcher)" />
                                                    </RadioGroup>

                                                    {/* {Label({ marginTop: 10, fontSize: 18, label: "Preferred Output Format" })}

                                                    <View style={{
                                                        borderWidth: 1,
                                                        borderColor: "rgba(0, 0, 0, 0.1)",
                                                        borderStyle: "dashed",
                                                        padding: 15
                                                    }}>
                                                        <View>
                                                            {Label({ fontSize: 18, label: "Sections to include in research proposal" })}
                                                            <View row marginT-5>
                                                                <CustomCheckbox value={(currentWork.questionnaire?.sections_to_include || []).includes("Background")} onChange={(value) => {
                                                                    setCurrentWork({
                                                                        ...currentWork,
                                                                        questionnaire: {
                                                                            ...(currentWork.questionnaire || {}),
                                                                            sections_to_include: value ? [...(currentWork.questionnaire?.sections_to_include || []), "Background"] : currentWork.questionnaire.sections_to_include.filter((section) => section !== "Background"),
                                                                        }
                                                                    });
                                                                }} />
                                                                <View marginL-10>
                                                                    <Text>Background</Text>
                                                                </View>
                                                            </View>

                                                            <View row marginT-5>
                                                                <CustomCheckbox value={(currentWork.questionnaire?.sections_to_include || []).includes("Objectives")} onChange={(value) => {
                                                                    setCurrentWork({
                                                                        ...currentWork,
                                                                        questionnaire: {
                                                                            ...(currentWork.questionnaire || {}),
                                                                            sections_to_include: value ? [...(currentWork.questionnaire?.sections_to_include || []), "Objectives"] : currentWork.questionnaire.sections_to_include.filter((section) => section !== "Objectives"),
                                                                        }
                                                                    });
                                                                }} />
                                                                <View marginL-10>
                                                                    <Text>Objectives</Text>
                                                                </View>
                                                            </View>

                                                            <View row marginT-5>
                                                                <CustomCheckbox value={(currentWork.questionnaire?.sections_to_include || []).includes("Methodology")} onChange={(value) => {
                                                                    setCurrentWork({
                                                                        ...currentWork,
                                                                        questionnaire: {
                                                                            ...(currentWork.questionnaire || {}),
                                                                            sections_to_include: value ? [...(currentWork.questionnaire?.sections_to_include || []), "Methodology"] : currentWork.questionnaire.sections_to_include.filter((section) => section !== "Methodology"),
                                                                        }
                                                                    });
                                                                }} />
                                                                <View marginL-10>
                                                                    <Text>Methodology</Text>
                                                                </View>
                                                            </View>

                                                            <View row marginT-5>
                                                                <CustomCheckbox value={(currentWork.questionnaire?.sections_to_include || []).includes("Expected outcomes")} onChange={(value) => {
                                                                    setCurrentWork({
                                                                        ...currentWork,
                                                                        questionnaire: {
                                                                            ...(currentWork.questionnaire || {}),
                                                                            sections_to_include: value ? [...(currentWork.questionnaire?.sections_to_include || []), "Expected outcomes"] : currentWork.questionnaire.sections_to_include.filter((section) => section !== "Expected outcomes"),
                                                                        }
                                                                    });
                                                                }} />
                                                                <View marginL-10>
                                                                    <Text>Expected outcomes</Text>
                                                                </View>
                                                            </View>

                                                            <View row marginT-5>
                                                                <CustomCheckbox value={(currentWork.questionnaire?.sections_to_include || []).includes("Timeline")} onChange={(value) => {
                                                                    setCurrentWork({
                                                                        ...currentWork,
                                                                        questionnaire: {
                                                                            ...(currentWork.questionnaire || {}),
                                                                            sections_to_include: value ? [...(currentWork.questionnaire?.sections_to_include || []), "Timeline"] : currentWork.questionnaire.sections_to_include.filter((section) => section !== "Timeline"),
                                                                        }
                                                                    });
                                                                }} />
                                                                <View marginL-10>
                                                                    <Text>Timeline</Text>
                                                                </View>
                                                            </View>

                                                            <View row marginT-5>
                                                                <CustomCheckbox value={(currentWork.questionnaire?.sections_to_include || []).includes("Budget")} onChange={(value) => {
                                                                    setCurrentWork({
                                                                        ...currentWork,
                                                                        questionnaire: {
                                                                            ...(currentWork.questionnaire || {}),
                                                                            sections_to_include: value ? [...(currentWork.questionnaire?.sections_to_include || []), "Budget"] : currentWork.questionnaire.sections_to_include.filter((section) => section !== "Budget"),
                                                                        }
                                                                    });
                                                                }} />
                                                                <View marginL-10>
                                                                    <Text>Budget</Text>
                                                                </View>
                                                            </View>

                                                            <View row marginT-5>
                                                                <CustomCheckbox value={(currentWork.questionnaire?.sections_to_include || []).includes("Literature review")} onChange={(value) => {
                                                                    setCurrentWork({
                                                                        ...currentWork,
                                                                        questionnaire: {
                                                                            ...(currentWork.questionnaire || {}),
                                                                            sections_to_include: value ? [...(currentWork.questionnaire?.sections_to_include || []), "Literature review"] : currentWork.questionnaire.sections_to_include.filter((section) => section !== "Literature review"),
                                                                        }
                                                                    });
                                                                }} />
                                                                <View marginL-10>
                                                                    <Text>Literature review</Text>
                                                                </View>
                                                            </View>

                                                        </View>

                                                        <View marginT-5>
                                                            {Label({ marginTop: 10, fontSize: 18, label: "Level of detail in methodology suggestions" })}
                                                            <RadioGroup initialValue={currentWork.questionnaire?.level_of_detail_in_methodology_suggestions} style={{
                                                                width: "100%",
                                                            }}
                                                                onValueChange={(value) => {
                                                                    setCurrentWork({
                                                                        ...currentWork,
                                                                        questionnaire: {
                                                                            ...(currentWork.questionnaire || {}),
                                                                            level_of_detail_in_methodology_suggestions: value,
                                                                        }
                                                                    });
                                                                }}>
                                                                <RadioButton size={20} marginT-5 value={1} label="1 (High-level overview)" />
                                                                <RadioButton size={20} marginT-5 value={2} label="2" />
                                                                <RadioButton size={20} marginT-5 value={3} label="3" />
                                                                <RadioButton size={20} marginT-5 value={4} label="4" />
                                                                <RadioButton size={20} marginT-5 value={5} label="5 (In-depth description)" />
                                                            </RadioGroup>
                                                        </View>
                                                    </View> */}

                                                    <View marginT-5>
                                                        {Label({ marginTop: 10, fontSize: 18, label: "Any specific constraints or limitations for your research" })}
                                                        <CustomTextField value={currentWork.questionnaire?.specific_constraints_or_limitations} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    specific_constraints_or_limitations: value,
                                                                }
                                                            });
                                                        }} />
                                                    </View>

                                                    <View marginT-5>
                                                        {Label({ marginTop: 10, fontSize: 18, label: "Are you interested in exploring interdisciplinary approaches?" })}

                                                        <RadioGroup initialValue={currentWork.questionnaire?.interested_in_interdisciplinary_approaches} style={{
                                                            width: "100%",
                                                        }}
                                                            onValueChange={(value) => {
                                                                setCurrentWork({
                                                                    ...currentWork,
                                                                    questionnaire: {
                                                                        ...(currentWork.questionnaire || {}),
                                                                        interested_in_interdisciplinary_approaches: value,
                                                                    }
                                                                });
                                                            }}>
                                                            <RadioButton size={20} marginT-5 value={"Yes"} label="Yes" />
                                                            <RadioButton size={20} marginT-5 value={"No"} label="No" />
                                                            <RadioButton size={20} marginT-5 value={"Maybe"} label="Maybe" />
                                                        </RadioGroup>
                                                    </View>

                                                    {currentWork.questionnaire?.interested_in_interdisciplinary_approaches == "Maybe" && <View marginT-5>
                                                        {Label({ marginTop: 10, fontSize: 18, label: "Please elaborate" })}
                                                        <CustomTextField value={currentWork.questionnaire?.interested_in_interdisciplinary_approaches_maybe} onChange={(value) => {
                                                            setCurrentWork({
                                                                ...currentWork,
                                                                questionnaire: {
                                                                    ...(currentWork.questionnaire || {}),
                                                                    interested_in_interdisciplinary_approaches_maybe: value,
                                                                }
                                                            });
                                                        }} />
                                                    </View>}

                                                    <View marginT-20>
                                                        <PrimaryButton
                                                            label="Next"
                                                            onPress={() => {
                                                                const step_1_complated = checkStep1Completion(currentWork);
                                                                if (step_1_complated.status) {
                                                                    saveWork({
                                                                        ...currentWork,
                                                                        current_step_number: 1,
                                                                    });
                                                                    setCurrentStep(1);
                                                                } else {
                                                                    if (Platform.OS === 'web') {
                                                                        message.error(step_1_complated.message);
                                                                    } else {
                                                                        Alert.alert(step_1_complated.message);
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </View>


                                                </View>
                                            </>}

                                            {currentStep == 1 && <>
                                                <AlertBox type="info" showIcon
                                                    message="Papers"
                                                    description="In this step, you can add your papers that matches your research interest. This will help us in understanding your research requirements better."
                                                />

                                                <View marginT-10>
                                                    <Table columns={columns} dataSource={currentWork.inspirations || []} />

                                                    {currentWork.inspirations?.length >= 5 ? <View marginT-10>
                                                        <PrimaryButton
                                                            label="Proceed to topic selection"
                                                            onPress={() => {
                                                                generateResearchPaperTopicsBasedOnResearchProfilingAndUserLovedPapers();
                                                                setCurrentStep(2);
                                                            }}
                                                        />
                                                    </View> : <View marginT-10>
                                                        <View backgroundColor="rgb(209, 37, 37)" style={{
                                                            borderRadius: 5,
                                                            paddingVertical: 5,
                                                            paddingHorizontal: 10,
                                                        }}>
                                                            <Text style={{
                                                                fontSize: 14,
                                                                color: "white",
                                                                textAlign: 'center',
                                                            }}>Please add at least 5 papers to proceed to the next step</Text>
                                                        </View>
                                                    </View>}

                                                    <View marginT-20>
                                                        <SecondaryButton
                                                            label="Add From Library"
                                                            onPress={() => {
                                                                setShowAddFromFolderModal(true);
                                                            }}
                                                        />
                                                    </View>
                                                    {/* <View marginV-25 style={{
                                                        position: "relative",
                                                        width: "100%",
                                                    }}>
                                                        <View center>
                                                            <View style={{
                                                                backgroundColor: "white",
                                                                marginBottom: 10,
                                                                marginTop: 20,
                                                                paddingHorizontal: 20,
                                                            }}>
                                                                <Text style={{
                                                                    fontSize: 12,
                                                                    fontWeight: 'bold',
                                                                    color: "#20303c",
                                                                    textTransform: "uppercase",
                                                                    textAlign: "center",
                                                                    letterSpacing: 2,
                                                                }}>OR</Text>
                                                            </View>
                                                        </View>

                                                        <View style={{
                                                            width: "100%",
                                                            height: 1,
                                                            backgroundColor: "#20303c",
                                                            position: "absolute",
                                                            top: 28,
                                                            left: 0,
                                                            zIndex: -1,
                                                            opacity: 0.05,
                                                        }}></View>
                                                    </View> */}

                                                    <View marginT-20>
                                                        <View style={{
                                                            position: "relative",
                                                            width: "100%",
                                                        }}>
                                                            <View center>
                                                                <View style={{
                                                                    backgroundColor: "white",
                                                                    marginBottom: 10,
                                                                    marginTop: 20,
                                                                    paddingHorizontal: 20,
                                                                }}>
                                                                    <Text style={{
                                                                        fontSize: 12,
                                                                        fontWeight: 'bold',
                                                                        color: "#20303c",
                                                                        textTransform: "uppercase",
                                                                        textAlign: "center",
                                                                        letterSpacing: 2,
                                                                    }}>Researchist's Recommended Papers Based on Your Research Profile</Text>
                                                                </View>
                                                            </View>

                                                            <View style={{
                                                                width: "100%",
                                                                height: 1,
                                                                backgroundColor: "#20303c",
                                                                position: "absolute",
                                                                top: 28,
                                                                left: 0,
                                                                zIndex: -1,
                                                                opacity: 0.05,
                                                            }}></View>
                                                        </View>

                                                        {!searchingInspirations ? <View marginT-20>
                                                            {currentWork.inspiration_suggestions?.length > 0 && <View>
                                                                {(currentWork.inspiration_suggestions).filter((suggestion) => !currentWork.inspirations.map((inspiration) => inspiration.title).includes(suggestion.title))
                                                                    .map((suggestion, index) => {
                                                                        return <View key={index} marginB-10>
                                                                            <View row>
                                                                                <View flex-1>
                                                                                    <TouchableOpacity onPress={() => {
                                                                                        setSelectedPaper(suggestion);
                                                                                        setShowPaperModal(true);
                                                                                        getPaperDetails(suggestion.id);
                                                                                        setTags(suggestion.tags || []);
                                                                                    }}>
                                                                                        <Text style={{
                                                                                            color: "rgb(38, 114, 172)",
                                                                                            letterSpacing: 0.5,
                                                                                            fontSize: 14,
                                                                                            fontFamily: 'SourceSansProSemiBold'
                                                                                        }}>{suggestion.title}</Text>
                                                                                    </TouchableOpacity>
                                                                                </View>
                                                                                <View>
                                                                                    <TouchableOpacity onPress={() => {
                                                                                        setCurrentWork({
                                                                                            ...currentWork,
                                                                                            inspirations: [...(currentWork.inspirations || []), suggestion],
                                                                                        });

                                                                                        saveWork({
                                                                                            ...currentWork,
                                                                                            inspirations: [...(currentWork.inspirations || []), suggestion],
                                                                                        });
                                                                                        setShowAddFromFolderModal(false);
                                                                                    }}>
                                                                                        <Icon name="plus" width={20} height={20} />
                                                                                    </TouchableOpacity>
                                                                                </View>
                                                                            </View>
                                                                        </View>
                                                                    })}
                                                            </View>}
                                                        </View> : <View centerH marginT-20>
                                                            {/* <ActivityIndicator size="large" color="#0000ff" /> */}

                                                            {/* <dotlottie-player src="https://lottie.host/514accb3-5931-46c9-98f2-5f3528856fa2/bWeYpvBviv.json" background="transparent" speed="1" style={{ width: 100, height: 100 }} loop autoplay></dotlottie-player> */}

                                                            <Image assetGroup="images" assetName="animated_logo" style={{
                                                                width: 180,
                                                                height: 70,
                                                            }} resizeMode="contain" />
                                                        </View>}


                                                    </View>
                                                </View>
                                            </>}

                                            {currentStep == 2 && <>
                                                <AlertBox type="info" showIcon
                                                    message="Topic Selection"
                                                    description={`In this step, we will provide you various topics based on your research interest.
                                                        You can add or remove topics that you are interested in. 
                                                     For 3 selected topics we will provide you complete details like background, research objectives, proposed methodology, expected outcomes, potential challenges and mitigation strategies, so that you can select the best topic for your research.`}
                                                />

                                                {!currentWork.top_3_topic_selected ? <View marginT-10>
                                                    <Table columns={topic_columns} dataSource={currentWork.selected_topics || []} />

                                                    {currentWork.selected_topics?.length == 1 ? <View marginT-10>
                                                        <PrimaryButton
                                                            label="Proceed"
                                                            onPress={() => {
                                                                setCurrentWork({
                                                                    ...currentWork,
                                                                    top_3_topic_selected: true,
                                                                });

                                                                saveWork({
                                                                    ...currentWork,
                                                                    top_3_topic_selected: true,
                                                                })

                                                                generateTopicsDetails({
                                                                    ...currentWork,
                                                                    top_3_topic_selected: true,
                                                                });
                                                            }}
                                                        />
                                                    </View> : <View marginT-10>
                                                        <View backgroundColor="rgb(209, 37, 37)" style={{
                                                            borderRadius: 5,
                                                            paddingVertical: 5,
                                                            paddingHorizontal: 10,
                                                        }}>
                                                            <Text style={{
                                                                fontSize: 14,
                                                                color: "white",
                                                                textAlign: 'center',
                                                            }}>Please select exactly 1 topic to proceed to the next step</Text>
                                                        </View>
                                                    </View>}

                                                    <View marginT-20>
                                                        <View style={{
                                                            position: "relative",
                                                            width: "100%",
                                                        }}>
                                                            <View center>
                                                                <View style={{
                                                                    backgroundColor: "white",
                                                                    marginBottom: 10,
                                                                    marginTop: 20,
                                                                    paddingHorizontal: 20,
                                                                }}>
                                                                    <Text style={{
                                                                        fontSize: 12,
                                                                        fontWeight: 'bold',
                                                                        color: "#20303c",
                                                                        textTransform: "uppercase",
                                                                        textAlign: "center",
                                                                        letterSpacing: 2,
                                                                    }}>Researchist's Recommended Topics</Text>
                                                                </View>
                                                            </View>

                                                            <View style={{
                                                                width: "100%",
                                                                height: 1,
                                                                backgroundColor: "#20303c",
                                                                position: "absolute",
                                                                top: 28,
                                                                left: 0,
                                                                zIndex: -1,
                                                                opacity: 0.05,
                                                            }}></View>
                                                        </View>

                                                        {!searchingTopics ? <View marginT-20>
                                                            {currentWork.generated_topics?.length > 0 && <View>
                                                                {(currentWork.generated_topics).filter((topic) => !currentWork.selected_topics.map((inspiration) => inspiration.title).includes(topic.title))
                                                                    .map((topic, index) => {
                                                                        return <View key={index} marginB-10>
                                                                            <View row>
                                                                                <View flex-1>
                                                                                    <Text style={{
                                                                                        fontSize: 14,
                                                                                        fontFamily: 'SourceSansProSemiBold'
                                                                                    }}>{topic.title} (Trend Score - {topic.trend_score})</Text>
                                                                                </View>
                                                                                <View>
                                                                                    <TouchableOpacity onPress={() => {
                                                                                        setCurrentWork({
                                                                                            ...currentWork,
                                                                                            selected_topics: [...(currentWork.selected_topics || []), topic],
                                                                                        });

                                                                                        saveWork({
                                                                                            ...currentWork,
                                                                                            selected_topics: [...(currentWork.selected_topics || []), topic],
                                                                                        });
                                                                                        setShowAddFromFolderModal(false);
                                                                                    }}>
                                                                                        <Icon name="plus" width={20} height={20} />
                                                                                    </TouchableOpacity>
                                                                                </View>
                                                                            </View>
                                                                        </View>
                                                                    })}
                                                            </View>}
                                                        </View> : <View centerH marginT-20>
                                                            {/* <ActivityIndicator size="large" color="#0000ff" /> */}

                                                            {/* <dotlottie-player src="https://lottie.host/514accb3-5931-46c9-98f2-5f3528856fa2/bWeYpvBviv.json" background="transparent" speed="1" style={{ width: 100, height: 100 }} loop autoplay></dotlottie-player> */}

                                                            <Image assetGroup="images" assetName="animated_logo" style={{
                                                                width: 180,
                                                                height: 70,
                                                            }} resizeMode="contain" />
                                                        </View>}

                                                        <View marginV-25 style={{
                                                            position: "relative",
                                                            width: "100%",
                                                        }}>
                                                            <View center>
                                                                <View style={{
                                                                    backgroundColor: "white",
                                                                    marginBottom: 10,
                                                                    marginTop: 20,
                                                                    paddingHorizontal: 20,
                                                                }}>
                                                                    <Text style={{
                                                                        fontSize: 12,
                                                                        fontWeight: 'bold',
                                                                        color: "#20303c",
                                                                        textTransform: "uppercase",
                                                                        textAlign: "center",
                                                                        letterSpacing: 2,
                                                                    }}>OR</Text>
                                                                </View>
                                                            </View>

                                                            <View style={{
                                                                width: "100%",
                                                                height: 1,
                                                                backgroundColor: "#20303c",
                                                                position: "absolute",
                                                                top: 28,
                                                                left: 0,
                                                                zIndex: -1,
                                                                opacity: 0.05,
                                                            }}></View>
                                                        </View>


                                                        <View row spread>
                                                            <View flex marginR-5>
                                                                <CustomTextField
                                                                    value={newTopic}
                                                                    onChange={(value) => {
                                                                        setNewTopic(value);
                                                                    }}
                                                                />
                                                            </View>
                                                            <PrimaryButton
                                                                label="Add Your Own Topic"
                                                                onPress={() => {
                                                                    setCurrentWork({
                                                                        ...currentWork,
                                                                        selected_topics: [...(currentWork.selected_topics || []), {
                                                                            title: newTopic,
                                                                        }],
                                                                    });

                                                                    saveWork({
                                                                        ...currentWork,
                                                                        selected_topics: [...(currentWork.selected_topics || []), {
                                                                            title: newTopic,
                                                                        }],
                                                                    });

                                                                    setNewTopic("");
                                                                }}
                                                            />
                                                        </View>


                                                    </View>
                                                </View> :
                                                    <View marginT-10>
                                                        {generatingTopicDetails ? <View centerH marginT-20>
                                                            {/* <ActivityIndicator size="large" color="#0000ff" /> */}

                                                            {/* <dotlottie-player src="https://lottie.host/514accb3-5931-46c9-98f2-5f3528856fa2/bWeYpvBviv.json" background="transparent" speed="1" style={{ width: 100, height: 100 }} loop autoplay></dotlottie-player> */}

                                                            <Image assetGroup="images" assetName="animated_logo" style={{
                                                                width: 180,
                                                                height: 70,
                                                            }} resizeMode="contain" />
                                                        </View> : <View>
                                                            <View right>
                                                                <PrimaryButton
                                                                    label="Back"
                                                                    onPress={() => {
                                                                        setCurrentWork({
                                                                            ...currentWork,
                                                                            top_3_topic_selected: false,
                                                                        });

                                                                        saveWork({
                                                                            ...currentWork,
                                                                            top_3_topic_selected: false,
                                                                        });
                                                                    }}
                                                                />
                                                            </View>

                                                            {currentWork.selected_topics?.map((topic, index) => {
                                                                return <View key={index} marginB-20>
                                                                    <View style={{
                                                                        backgroundColor: "white",
                                                                        padding: 20,
                                                                    }}>
                                                                        <Text style={{
                                                                            fontSize: 18,
                                                                            fontFamily: 'SourceSansProSemiBold'
                                                                        }}>{topic.title}</Text>

                                                                        {Label({ marginTop: 10, fontSize: 18, label: "Background" })}
                                                                        {/* <Text>{topic.background}</Text> */}
                                                                        <CustomTextAreaField value={topic.background} onChange={(value) => {
                                                                            setCurrentWork({
                                                                                ...currentWork,
                                                                                selected_topics: currentWork.selected_topics.map((t, i) => {
                                                                                    if (i == index) {
                                                                                        return {
                                                                                            ...t,
                                                                                            background: value,
                                                                                        }
                                                                                    } else {
                                                                                        return t;
                                                                                    }
                                                                                }),
                                                                            });
                                                                        }} />

                                                                        {Label({ marginTop: 10, fontSize: 18, label: "Research Objectives" })}
                                                                        {/* <Text>{topic.objectives}</Text> */}

                                                                        <CustomTextAreaField value={topic.objectives} onChange={(value) => {
                                                                            setCurrentWork({
                                                                                ...currentWork,
                                                                                selected_topics: currentWork.selected_topics.map((t, i) => {
                                                                                    if (i == index) {
                                                                                        return {
                                                                                            ...t,
                                                                                            objectives: value,
                                                                                        }
                                                                                    } else {
                                                                                        return t;
                                                                                    }
                                                                                }),
                                                                            });
                                                                        }} />

                                                                        {Label({ marginTop: 10, fontSize: 18, label: "Proposed Methodology" })}
                                                                        {/* <Text>{topic.methodology}</Text> */}

                                                                        <CustomTextAreaField value={topic.methodology} onChange={(value) => {
                                                                            setCurrentWork({
                                                                                ...currentWork,
                                                                                selected_topics: currentWork.selected_topics.map((t, i) => {
                                                                                    if (i == index) {
                                                                                        return {
                                                                                            ...t,
                                                                                            methodology: value,
                                                                                        }
                                                                                    } else {
                                                                                        return t;
                                                                                    }
                                                                                }),
                                                                            });
                                                                        }} />

                                                                        {/* {Label({ marginTop: 10, fontSize: 18, label: "Expected Outcomes" })} */}
                                                                        {/* <Text>{topic.outcomes}</Text> */}

                                                                        {/* <CustomTextAreaField value={topic.outcomes} onChange={(value) => {
                                                                            setCurrentWork({
                                                                                ...currentWork,
                                                                                selected_topics: currentWork.selected_topics.map((t, i) => {
                                                                                    if (i == index) {
                                                                                        return {
                                                                                            ...t,
                                                                                            outcomes: value,
                                                                                        }
                                                                                    } else {
                                                                                        return t;
                                                                                    }
                                                                                }),
                                                                            });
                                                                        }} /> */}

                                                                        {/* {Label({ marginTop: 10, fontSize: 18, label: "Potential Challenges" })} */}
                                                                        {/* <Text>{topic.challenges}</Text> */}
                                                                        {/* <CustomTextAreaField value={topic.challenges} onChange={(value) => {
                                                                            setCurrentWork({
                                                                                ...currentWork,
                                                                                selected_topics: currentWork.selected_topics.map((t, i) => {
                                                                                    if (i == index) {
                                                                                        return {
                                                                                            ...t,
                                                                                            challenges: value,
                                                                                        }
                                                                                    } else {
                                                                                        return t;
                                                                                    }
                                                                                }),
                                                                            });
                                                                        }} /> */}

                                                                        {Label({ marginTop: 10, fontSize: 18, label: "Research Questions" })}
                                                                        {/* <Text>{topic.questions}</Text> */}

                                                                        <CustomTextAreaField value={topic.questions} onChange={(value) => {
                                                                            setCurrentWork({
                                                                                ...currentWork,
                                                                                selected_topics: currentWork.selected_topics.map((t, i) => {
                                                                                    if (i == index) {
                                                                                        return {
                                                                                            ...t,
                                                                                            questions: value,
                                                                                        }
                                                                                    } else {
                                                                                        return t;
                                                                                    }
                                                                                }),
                                                                            });
                                                                        }} />

                                                                        <CustomModal
                                                                            title="Ask Researchist for Feedback"
                                                                            width={'500px'}
                                                                            visible={showAiTopicFeedbackModal}
                                                                            onClose={() => {
                                                                                setShowAiTopicFeedbackModal(false);
                                                                            }}>
                                                                            <View center marginT-10>
                                                                                {Label({ marginTop: 10, fontSize: 18, label: "Query" })}

                                                                                <CustomTextField value={aiTopicFeedbackQuery} onChange={(value) => {
                                                                                    setAiTopicFeedbackQuery(value);
                                                                                }} />

                                                                                <View marginT-10>
                                                                                    <PrimaryButton
                                                                                        label="Ask"
                                                                                        onPress={() => {
                                                                                            if (aiTopicFeedbackQuery) {
                                                                                                setAiTopicThinking(true);
                                                                                                setAiTopicFeedbackQuery("");
                                                                                                aiFeedbackForTopic(currentWork)
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </View>

                                                                                <View>
                                                                                    {aiTopicThinking ? <View centerH marginT-20>
                                                                                        {/* <dotlottie-player src="https://lottie.host/514accb3-5931-46c9-98f2-5f3528856fa2/bWeYpvBviv.json" background="transparent" speed="1" style={{ width: 100, height: 100 }} loop autoplay></dotlottie-player> */}

                                                                                        <Image assetGroup="images" assetName="animated_logo" style={{
                                                                                            width: 180,
                                                                                            height: 70,
                                                                                        }} resizeMode="contain" />
                                                                                    </View> : null}

                                                                                    {aiTopicFeedback && !aiTopicThinking ? <View marginT-20>
                                                                                        {Label({ marginTop: 10, fontSize: 18, label: "Reseachist Feedback" })}

                                                                                        <Text style={{
                                                                                            fontSize: 14,
                                                                                        }}>{aiTopicFeedback}</Text>
                                                                                    </View> : null}
                                                                                </View>
                                                                            </View>
                                                                        </CustomModal>

                                                                        <View marginT-10>
                                                                            <SecondaryButton
                                                                                label="Ask Researchist for Feedback"
                                                                                onPress={() => {
                                                                                    setShowAiTopicFeedbackModal(true);
                                                                                }}
                                                                            />
                                                                        </View>

                                                                        <View marginT-10>
                                                                            <PrimaryButton
                                                                                label="Select Topic and Generate Research Outline"
                                                                                onPress={() => {
                                                                                    setCurrentWork({
                                                                                        ...currentWork,
                                                                                        selected_topic: topic,
                                                                                    });

                                                                                    saveWork({
                                                                                        ...currentWork,
                                                                                        selected_topic: topic,
                                                                                    });

                                                                                    generateResearchOutlineBasedOnResearchProfilingAndUserLovedPapersAndSelectedTopic(topic, {
                                                                                        ...currentWork,
                                                                                        selected_topic: topic,
                                                                                        current_step_number: 3,
                                                                                    });

                                                                                    setCurrentStep(3);
                                                                                }}
                                                                            />
                                                                        </View>
                                                                    </View>
                                                                </View>
                                                            })}

                                                        </View>}
                                                    </View>}
                                            </>}

                                            {currentStep == 3 && <>
                                                <View marginT-10>
                                                    {generatingResearchOutline ? <View centerH marginT-20>
                                                        <Image assetGroup="images" assetName="animated_logo" style={{
                                                                                            width: 180,
                                                                                            height: 70,
                                                                                        }} resizeMode="contain" />

                                                    </View> : <View>

                                                        {currentWork.research_outline ? <View style={{
                                                            backgroundColor: "white",
                                                            padding: 20,
                                                        }}>
                                                            <Text style={{
                                                                fontSize: 18,
                                                                fontFamily: 'SourceSansProSemiBold'
                                                            }}>{currentWork.research_outline?.title}</Text>

                                                            {Label({ marginTop: 10, fontSize: 18, label: "Background" })}
                                                            {/* <Text>{currentWork.research_outline.background}</Text> */}

                                                            <CustomTextAreaField value={currentWork.research_outline?.background} onChange={(value) => {
                                                                setCurrentWork({
                                                                    ...currentWork,
                                                                    research_outline: {
                                                                        ...(currentWork.research_outline || {}),
                                                                        background: value,
                                                                    }
                                                                });
                                                            }} />

                                                            {Label({ marginTop: 10, fontSize: 18, label: "Research Objectives" })}
                                                            {/* <Text>{currentWork.research_outline.objectives}</Text> */}
                                                            <CustomTextAreaField value={currentWork.research_outline?.objectives} onChange={(value) => {
                                                                setCurrentWork({
                                                                    ...currentWork,
                                                                    research_outline: {
                                                                        ...(currentWork.research_outline || {}),
                                                                        objectives: value,
                                                                    }
                                                                });
                                                            }} />

                                                            {Label({ marginTop: 10, fontSize: 18, label: "Proposed Methodology" })}
                                                            {/* <Text>{currentWork.research_outline.methodology}</Text> */}

                                                            <CustomTextAreaField value={currentWork?.research_outline?.methodology} onChange={(value) => {
                                                                setCurrentWork({
                                                                    ...currentWork,
                                                                    research_outline: {
                                                                        ...(currentWork.research_outline || {}),
                                                                        methodology: value,
                                                                    }
                                                                });
                                                            }} />

                                                            {Label({ marginTop: 10, fontSize: 18, label: "Expected Outcomes" })}
                                                            {/* <Text>{currentWork.research_outline.outcomes}</Text> */}

                                                            <CustomTextAreaField value={currentWork.research_outline?.outcomes} onChange={(value) => {
                                                                setCurrentWork({
                                                                    ...currentWork,
                                                                    research_outline: {
                                                                        ...(currentWork.research_outline || {}),
                                                                        outcomes: value,
                                                                    }
                                                                });
                                                            }} />

                                                            {Label({ marginTop: 10, fontSize: 18, label: "Potential Challenges" })}
                                                            {/* <Text>{currentWork.research_outline.challenges}</Text> */}

                                                            <CustomTextAreaField value={currentWork.research_outline?.challenges} onChange={(value) => {
                                                                setCurrentWork({
                                                                    ...currentWork,
                                                                    research_outline: {
                                                                        ...(currentWork.research_outline || {}),
                                                                        challenges: value,
                                                                    }
                                                                });
                                                            }} />

                                                            {Label({ marginTop: 10, fontSize: 18, label: "Research Questions" })}
                                                            {/* <Text>{currentWork.research_outline.questions}</Text> */}
                                                            <CustomTextAreaField value={currentWork.research_outline?.questions} onChange={(value) => {
                                                                setCurrentWork({
                                                                    ...currentWork,
                                                                    research_outline: {
                                                                        ...(currentWork.research_outline || {}),
                                                                        questions: value,
                                                                    }
                                                                });
                                                            }} />

                                                            {Label({ marginTop: 10, fontSize: 18, label: "Potential Resources" })}
                                                            {/* <Text>{currentWork.research_outline.potential_resources}</Text> */}

                                                            <CustomTextAreaField value={currentWork.research_outline?.potential_resources} onChange={(value) => {
                                                                setCurrentWork({
                                                                    ...currentWork,
                                                                    research_outline: {
                                                                        ...(currentWork.research_outline || {}),
                                                                        potential_resources: value,
                                                                    }
                                                                });
                                                            }} />

                                                            {(currentWork.research_outline.suggested_timeline && currentWork.research_outline.suggested_timeline.length > 0) ? <>
                                                            {Label({ marginTop: 10, fontSize: 18, label: "Suggested Timeline" })}
                                                            <Timeline
                                                                items={currentWork.research_outline.suggested_timeline.map((timeline, index) => {
                                                                    return {
                                                                        children: (
                                                                            <>
                                                                                <p style={{
                                                                                    fontSize: 14,
                                                                                    fontFamily: 'SourceSansProSemiBold'
                                                                                }}>{timeline.timeline_title}</p>
                                                                                <p>{timeline.timeline_description}</p>
                                                                            </>
                                                                        ),
                                                                    }
                                                                })}
                                                            />
                                                            </>
                                                            : null}


                                                            {Label({ marginTop: 10, fontSize: 18, label: "Potential Headings" })}
                                                            <ChaptersAndHeadingsComponent currentWork={currentWork} setCurrentWork={setCurrentWork} />

                                                            <View marginT-10>
                                                                <PrimaryButton
                                                                    label="Decided? Let's Start Writing"
                                                                    onPress={() => {
                                                                        setCurrentWork({
                                                                            ...currentWork,
                                                                            current_step_number: 4,
                                                                        });

                                                                        saveWork({
                                                                            ...currentWork,
                                                                            current_step_number: 4,
                                                                        });

                                                                        setCurrentStep(4);
                                                                    }}
                                                                />
                                                            </View>
                                                        </View> : <View>
                                                            <Text style={{
                                                                fontSize: 14,
                                                                color: "red",
                                                            }}>No research outline found</Text>
                                                        </View>}

                                                    </View>}
                                                </View>
                                            </>}

                                            {currentStep == 4 && <>
                                                {/* <AlertBox type="info" showIcon
                                                    message="Research Writing"
                                                    description={`
                                                    In this step, we will assist you in writing the research paper. You can maintain the research paper in the system add notes for each section and ask for feedback from the researchist.
                                                    `}
                                                /> */}

                                                <View center marginT-10>
                                                    <Segmented
                                                        options={['Research Writing', 'Manage Headings', 'View Timeline']}
                                                        value={selectedSegment}
                                                        onChange={(value) => {
                                                            setSelectedSegment(value);
                                                        }}
                                                    />
                                                </View>

                                                {currentWork.research_outline?.research_papers_table_of_content && currentWork.research_outline?.research_papers_table_of_content?.length > 0 ? <View>
                                                    {selectedSegment == "Research Writing" && <View marginT-10>
                                                        <View row spread centerV>
                                                            <View row>
                                                                <View marginT-10>
                                                                    <Text marginB-5 style={{
                                                                        fontSize: 14,
                                                                        fontFamily: 'SourceSansProSemiBold'
                                                                    }}>Select Main Heading</Text>
                                                                    <View style={{
                                                                        width: 300,
                                                                    }}>
                                                                        <CustomSelect
                                                                            value={selectedChapterIndex == null ? "" : "" + selectedChapterIndex}
                                                                            onChange={(value) => {
                                                                                setSelectedChapterIndex("" + value);
                                                                                setSelectedHeadingIndex(null);
                                                                            }}
                                                                            options={currentWork.research_outline.research_papers_table_of_content.map((chapter, index) => {
                                                                                return {
                                                                                    label: chapter.chapter_title,
                                                                                    value: "" + index,
                                                                                }
                                                                            })}
                                                                        />
                                                                    </View>
                                                                </View>

                                                                {selectedChapterIndex != null && <View marginT-10 marginL-10>
                                                                    <Text marginB-5 style={{
                                                                        fontSize: 14,
                                                                        fontFamily: 'SourceSansProSemiBold'
                                                                    }}>Select Sub Heading</Text>
                                                                    <View style={{
                                                                        width: 300,
                                                                    }}>
                                                                        <CustomSelect
                                                                            value={selectedHeadingIndex == null ? "" : "" + selectedHeadingIndex}
                                                                            onChange={(value) => {
                                                                                setSelectedHeadingIndex("" + value);
                                                                            }}
                                                                            options={currentWork.research_outline.research_papers_table_of_content[selectedChapterIndex]?.heading.map((heading, index) => {
                                                                                return {
                                                                                    label: heading,
                                                                                    value: "" + index,
                                                                                }
                                                                            })}
                                                                        />
                                                                    </View>
                                                                </View>}
                                                            </View>
                                                            <View row bottom>
                                                                {selectedChapterIndex != null && selectedHeadingIndex != null && <PrimaryButton
                                                                    label="Ask Researchist for Feedback"
                                                                    style={{
                                                                        marginRight: 10
                                                                    }}
                                                                    onPress={() => {
                                                                        setShowAiFeedbackModal(true);
                                                                    }} />}

                                                                <ExportButton content={currentWork.research_outline.research_papers_table_of_content} />
                                                            </View>
                                                        </View>

                                                        {selectedChapterIndex != null && selectedHeadingIndex != null && <View marginV-10>
                                                            <Text marginB-5 style={{
                                                                fontSize: 14,
                                                                fontFamily: 'SourceSansProSemiBold'
                                                            }}>Content</Text>

                                                            <CustomEditorField
                                                                key={"editor-" + selectedChapterIndex + "-" + selectedHeadingIndex}
                                                                value={currentWork.research_outline.research_papers_table_of_content[+selectedChapterIndex]?.content ? currentWork.research_outline.research_papers_table_of_content[+selectedChapterIndex]?.content[+selectedHeadingIndex]?.content : ""}
                                                                onChange={(value) => {
                                                                    console.log(value, selectedChapterIndex, selectedHeadingIndex);
                                                                    console.log(currentWork.research_outline.research_papers_table_of_content);

                                                                    setCurrentWork({
                                                                        ...currentWork,
                                                                        research_outline: {
                                                                            ...currentWork.research_outline,
                                                                            research_papers_table_of_content: [...currentWork.research_outline.research_papers_table_of_content.map((chapter, index) => {
                                                                                if (index == +selectedChapterIndex) {
                                                                                    return {
                                                                                        ...chapter,
                                                                                        content: {
                                                                                            [selectedHeadingIndex]: {
                                                                                                ...(chapter.content ? (chapter.content[selectedHeadingIndex] || {}) : {}),
                                                                                                content: value,
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                } else {
                                                                                    return chapter;
                                                                                }
                                                                            })]
                                                                        }
                                                                    });
                                                                }}
                                                            />
                                                        </View>}

                                                        {selectedChapterIndex != null && selectedHeadingIndex != null && <View marginV-10>
                                                            <Text marginB-5 style={{
                                                                fontSize: 14,
                                                                fontFamily: 'SourceSansProSemiBold'
                                                            }}>Notes</Text>

                                                            <CustomEditorField
                                                                value={currentWork.research_outline.research_papers_table_of_content[+selectedChapterIndex]?.content ? currentWork.research_outline.research_papers_table_of_content[+selectedChapterIndex]?.content[+selectedHeadingIndex]?.notes : ""}
                                                                onChange={(value) => {
                                                                    setCurrentWork({
                                                                        ...currentWork,
                                                                        research_outline: {
                                                                            ...currentWork.research_outline,
                                                                            research_papers_table_of_content: currentWork.research_outline.research_papers_table_of_content.map((chapter, index) => {
                                                                                if (index == +selectedChapterIndex) {
                                                                                    return {
                                                                                        ...chapter,
                                                                                        content: (chapter.content || []).map((heading, i) => {
                                                                                            if (i == +selectedHeadingIndex) {
                                                                                                return {
                                                                                                    ...heading,
                                                                                                    notes: value,
                                                                                                }
                                                                                            } else {
                                                                                                return heading;
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                } else {
                                                                                    return chapter;
                                                                                }
                                                                            })
                                                                        }
                                                                    });
                                                                }}
                                                            />
                                                        </View>}
                                                    </View>}

                                                    {selectedSegment == "Manage Headings" && <View marginT-10>
                                                        <ChaptersAndHeadingsComponent currentWork={currentWork} setCurrentWork={setCurrentWork} />
                                                    </View>}

                                                    {selectedSegment == "View Timeline" && <View marginT-10>
                                                        <Timeline
                                                            items={currentWork.research_outline.suggested_timeline.map((timeline, index) => {
                                                                return {
                                                                    children: (
                                                                        <>
                                                                            <p style={{
                                                                                fontSize: 14,
                                                                                fontFamily: 'SourceSansProSemiBold'
                                                                            }}>{timeline.timeline_title}</p>
                                                                            <p>{timeline.timeline_description}</p>
                                                                        </>
                                                                    ),
                                                                }
                                                            })}
                                                        />
                                                    </View>}

                                                    <CustomModal
                                                        title="Ask Researchist for Feedback"
                                                        width={'500px'}
                                                        visible={showAiFeedbackModal}
                                                        onClose={() => {
                                                            setShowAiFeedbackModal(false);
                                                        }}>
                                                        <View center marginT-10>
                                                            {Label({ marginTop: 10, fontSize: 18, label: "Query" })}

                                                            <CustomTextField value={aiFeedbackQuery} onChange={(value) => {
                                                                setAiFeedbackQuery(value);
                                                            }} />

                                                            <View marginT-10>
                                                                <PrimaryButton
                                                                    label="Ask"
                                                                    onPress={() => {
                                                                        const heading_title = currentWork.research_outline.research_papers_table_of_content[+selectedChapterIndex]?.heading[+selectedHeadingIndex];

                                                                        const chapter_title = currentWork.research_outline.research_papers_table_of_content[+selectedChapterIndex]?.chapter_title;

                                                                        const current_content = currentWork.research_outline.research_papers_table_of_content[+selectedChapterIndex]?.content ? currentWork.research_outline.research_papers_table_of_content[+selectedChapterIndex]?.content[+selectedHeadingIndex]?.content : "";

                                                                        assistWritingResearchPaper(
                                                                            chapter_title,
                                                                            heading_title,
                                                                            current_content,
                                                                            aiFeedbackQuery,
                                                                            currentWork
                                                                        );
                                                                    }}
                                                                />
                                                            </View>

                                                            <View>
                                                                {aiThinking ? <View centerH marginT-20>
                                                                    <Image assetGroup="images" assetName="animated_logo" style={{
                                                                                            width: 180,
                                                                                            height: 70,
                                                                                        }} resizeMode="contain" />
                                                                </View> : null}

                                                                {aiFeedback && !aiThinking ? <View marginT-20>
                                                                    {Label({ marginTop: 10, fontSize: 18, label: "Reseachist Feedback" })}

                                                                    <Text style={{
                                                                        fontSize: 14,
                                                                    }}>{aiFeedback}</Text>
                                                                </View> : null}
                                                            </View>


                                                        </View>
                                                    </CustomModal>
                                                </View> : <View>
                                                    <Text center style={{
                                                        fontSize: 14,
                                                        color: "red",
                                                    }}>No research outline found to write for this work</Text>
                                                </View>}
                                            </>}
                                        </View>
                                    </View> : null}
                                </Card>
                            </View>
                        </View>
                    </View>
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>


        <CustomModal
            title={selectedWork ? "Edit Ideation" : "New Ideation"}
            width={'500px'}
            visible={showNewWorkModal}
            onClose={() => {
                setShowNewWorkModal(false);
                setWorkName("");
                setSelectedWork(null);
            }}>
            <View center marginT-10>
                <View centerH style={{
                    width: "100%",
                    maxWidth: 500,
                }}>
                    <View style={{
                        width: "100%",
                    }}>
                        {Label({ marginTop: 10, fontSize: 18, label: "Ideation Title (Something using which u can remember it)" })}
                        <CustomTextField value={workName} onChange={(value) => {
                            setWorkName(value);
                        }} />
                    </View>
                    <View marginT-10 style={{
                        width: "100%",
                    }}>
                        <PrimaryButton
                            label={selectedWork ? "Update Ideation" : "Create Ideation"}
                            onPress={async () => {
                                if (!workName) {
                                    if (Platform.OS === 'web') {
                                        message.success("Please enter ideation name");
                                    } else {
                                        Alert.alert("Please enter ideation name");
                                    }
                                    return;
                                }

                                let res;

                                if (selectedWork) {
                                    res = await api.customRoute("update-work", {
                                        work_id: selectedWork._id,
                                        name: workName,
                                        email_id: email,
                                    });
                                } else {
                                    res = await api.customRoute("new-work", {
                                        name: workName,
                                        email_id: email,
                                    });
                                }

                                if (res.message) {
                                    if (Platform.OS === 'web') {
                                        message.success(res.message);
                                    } else {
                                        Alert.alert(res.message);
                                    }

                                    getWorks();
                                    setShowNewWorkModal(false);

                                    setWorkName("");
                                    setSelectedWork(null);
                                } else {
                                    if (Platform.OS === 'web') {
                                        message.success("Something went wrong");
                                    } else {
                                        Alert.alert("Something went wrong");
                                    }
                                }
                            }}
                        />
                    </View>
                </View>
            </View>
        </CustomModal>

        <CustomModal
            title="Paper Details"
            width={720}
            visible={showPaperModal}
            onClose={() => {
                setShowPaperModal(false);
            }}
            drawer={true}
        >
            <View paddingH-20>
                <PaperDetails publication={selectedPaper} />
                <View padding-5>
                    {/* <View centerH style={{
                        width: "100%"
                    }}> */}
                    {/* <View style={{
                            width: "100%",
                        }}>
                            {Label({ marginTop: 10, fontSize: 18, label: "Title" })}
                            <Text>{selectedPaper?.title}</Text>
                        </View>
                        <View marginT-10 style={{
                            width: "100%",
                        }}>
                            {Label({ marginTop: 10, fontSize: 18, label: "Authors" })}
                            <Text>{selectedPaper?.authorships.map((author) => author.display_name).join(", ")}</Text>
                        </View>
                        <View marginT-10 style={{
                            width: "100%",
                        }}>
                            {Label({ marginTop: 10, fontSize: 18, label: "Publication Year" })}
                            <Text>{selectedPaper?.publication_year}</Text>
                        </View>
                        <View marginT-10 style={{
                            width: "100%",
                        }}>
                            {Label({ marginTop: 10, fontSize: 18, label: "Abstract" })}
                            <Text>{selectedPaper?.abstract}</Text>
                        </View>
                    </View> */}

                    {/* {selectedSegment == "Notes" && <View centerH style={{
                    }}>
                        <ReactQuill
                            value={selectedPaper?.notes}
                            onChange={(value) => {
                                setSelectedPaper({
                                    ...selectedPaper,
                                    notes: value,
                                });
                            }}
                            theme="snow"
                            style={{
                                minHeight: 450,
                            }}
                        />
                    </View>}

                    {selectedSegment == "Tags" && <View style={{
                    }}>
                        <View row marginB-10>
                            {tags.map((tag) => (
                                <span key={tag} style={{ display: 'inline-block' }}>
                                    <Tag
                                        closable
                                        onClose={(e) => {
                                            e.preventDefault();
                                            setTags(tags.filter((t) => t !== tag));
                                        }}
                                    >
                                        {tag}
                                    </Tag>
                                </span>
                            ))}
                        </View>
                        {inputVisible ? (
                            <Input
                                ref={inputRef}
                                type="text"
                                size="small"
                                style={{ width: "100%" }}
                                value={inputValue}
                                placeholder="Enter new tag name here..."
                                onChange={(e) => {
                                    setInputValue(e.target.value);
                                }}
                                onBlur={() => {
                                    if (inputValue && tags.indexOf(inputValue) === -1) {
                                        setTags([...tags, inputValue]);
                                    }
                                    setInputVisible(false);
                                    setInputValue('');
                                }}
                                onPressEnter={() => {
                                    if (inputValue && tags.indexOf(inputValue) === -1) {
                                        setTags([...tags, inputValue]);
                                    }
                                    setInputVisible(false);
                                    setInputValue('');
                                }}
                            />
                        ) : (
                            <Tag onClick={() => {
                                setInputVisible(true);
                            }} style={{
                                background: '#fff',
                                borderStyle: 'dashed',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyItems: 'center',
                                height: 32,
                            }}>
                                <View paddingH-5 row center>
                                    <Icon name="plus" width={16} height={16} />
                                    <View marginR-5>
                                        New Tag
                                    </View>
                                </View>
                            </Tag>
                        )}
                    </View>}

                    {selectedSegment == "Related" && <View centerH style={{
                    }}>
                        <Text>Related</Text>
                    </View>} */}
                </View>
            </View>
        </CustomModal>

        <CustomModal
            title="Add From Folder"
            width={720}
            visible={showAddFromFolderModal}
            onClose={() => {
                setShowAddFromFolderModal(false);
            }}
            drawer={true}
        >
            <View paddingH-20 paddingT-10>
                {/* <CustomTextField
                    value={search}
                    onChange={(value) => {
                        setSearch(value);
                    }}
                    placeholder="Search Paper"
                /> */}

                <View marginT-10>
                    {/* {filteredPapers.map((paper) => (
                        <View key={paper._id} style={{
                            padding: 10,
                            borderBottomWidth: 1,
                            borderBottomColor: "rgba(0, 0, 0, 0.1)",
                        }}>
                            <View row spread>
                                <Text>{paper.paper_details.title}</Text>
                                <TouchableOpacity onPress={() => {
                                    setCurrentWork({
                                        ...currentWork,
                                        inspirations: [...(currentWork.inspirations || []), paper.paper_details],
                                    });
                                    saveWork({
                                        ...currentWork,
                                        inspirations: [...(currentWork.inspirations || []), paper.paper_details],
                                    });
                                    setShowAddFromFolderModal(false);
                                }}>
                                    <Icon name="plus" width={20} height={20} />
                                </TouchableOpacity>
                            </View>
                        </View>
                    ))} */}

                    <View row style={{ flexWrap: 'wrap' }}>
                        {folders.map((folder, index) => {
                            return <TouchableOpacity
                                onPress={() => {
                                    setCurrentFolder(folder._id);
                                }}
                                key={index} style={{
                                    padding: 10,
                                    borderWidth: 1,
                                    borderColor: "rgba(0, 0, 0, 0.1)",
                                    borderRadius: 8,
                                    // backgroundColor: "#f9f9f9",
                                    backgroundColor: currentFolder == folder._id ? "#f9f9f9" : "white",
                                    marginRight: 5,
                                    marginBottom: 5,
                                }}>
                                <Text
                                    style={{
                                        fontSize: 14,
                                        fontFamily: 'SourceSansProSemiBold'
                                    }}
                                >{folder.name}</Text>
                            </TouchableOpacity>;
                        })}
                    </View>

                    <View marginT-10>
                        {currentFolder && papers.filter((paper) => paper.folder_id == currentFolder).map((paper, index) => {
                            return <TouchableOpacity
                                onPress={() => {
                                    const isAlreadyAdded = currentWork.inspirations.map((inspiration) => inspiration.title).includes(paper.paper_details.title);
                                    if (isAlreadyAdded) {
                                        message.error("This paper is already added");
                                        return;
                                    }

                                    setCurrentWork({
                                        ...currentWork,
                                        inspirations: [...(currentWork.inspirations || []), paper.paper_details],
                                    });
                                    saveWork({
                                        ...currentWork,
                                        inspirations: [...(currentWork.inspirations || []), paper.paper_details],
                                    });
                                    setShowAddFromFolderModal(false);
                                }}
                                key={index} style={{
                                    padding: 10,
                                    borderWidth: 1,
                                    borderColor: "rgba(0, 0, 0, 0.1)",
                                    width: "100%",
                                    borderRadius: 4,
                                    backgroundColor: "#f9f9f9",
                                    marginBottom: 5,
                                }}>
                                <Text
                                    style={{
                                        fontSize: 14,
                                        fontFamily: 'SourceSansProSemiBold'
                                    }}
                                >{paper.paper_details.title}</Text>
                            </TouchableOpacity>;
                        })}
                    </View>
                </View>
            </View>
        </CustomModal>
    </View >)
})

export default WorksWritingScreen

const $container = {
    flex: 1,
}

function ChaptersAndHeadingsComponent({ currentWork, setCurrentWork }) {
    return <View>
        {currentWork.research_outline?.research_papers_table_of_content && currentWork.research_outline?.research_papers_table_of_content?.length > 0 ? currentWork.research_outline.research_papers_table_of_content.map((chapter, index) => {
            return <View key={index} marginB-4>
                <View marginT-10>
                    <View row centerV>
                        <Text style={{
                            fontSize: 14,
                            fontFamily: 'SourceSansProSemiBold'
                        }}>{index + 1}.</Text>

                        <View flex paddingH-5>
                            <CustomTextField value={chapter.chapter_title} onChange={(value) => {
                                setCurrentWork({
                                    ...currentWork,
                                    research_outline: {
                                        ...currentWork.research_outline,
                                        research_papers_table_of_content: currentWork.research_outline.research_papers_table_of_content.map((c, i) => {
                                            if (i == index) {
                                                return {
                                                    ...c,
                                                    chapter_title: value,
                                                }
                                            } else {
                                                return c
                                            }
                                        }),
                                    }
                                })
                            }} />
                        </View>

                        <View row>
                            <TouchableOpacity
                                style={{
                                    marginRight: 10,
                                }}
                                onPress={() => {
                                    const new_chapter = {
                                        chapter_title: "",
                                        heading: [],
                                    }

                                    const new_chapters = [...currentWork.research_outline.research_papers_table_of_content]
                                    new_chapters.splice(index + 1, 0, new_chapter)

                                    setCurrentWork({
                                        ...currentWork,
                                        research_outline: {
                                            ...currentWork.research_outline,
                                            research_papers_table_of_content: new_chapters,
                                        }
                                    })
                                }}>
                                <Icon name="plus-square-outline" width={20} height={20} color={"#333333"} fill={'#333333'} />
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={{
                                    marginRight: 10,
                                }}
                                onPress={() => {
                                    if (index > 0) {
                                        const temp = currentWork.research_outline.research_papers_table_of_content[index - 1]
                                        currentWork.research_outline.research_papers_table_of_content[index - 1] = currentWork.research_outline.research_papers_table_of_content[index]
                                        currentWork.research_outline.research_papers_table_of_content[index] = temp

                                        setCurrentWork({
                                            ...currentWork,
                                            research_outline: {
                                                ...currentWork.research_outline,
                                                research_papers_table_of_content: currentWork.research_outline.research_papers_table_of_content,
                                            }
                                        })
                                    }
                                }}>
                                <Icon name="arrow-up-outline" width={20} height={20} fill={'#333333'} />
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={{
                                    marginRight: 10,
                                }}
                                onPress={() => {
                                    if (index < currentWork.research_outline.research_papers_table_of_content.length - 1) {
                                        const temp = currentWork.research_outline.research_papers_table_of_content[index + 1]
                                        currentWork.research_outline.research_papers_table_of_content[index + 1] = currentWork.research_outline.research_papers_table_of_content[index]
                                        currentWork.research_outline.research_papers_table_of_content[index] = temp

                                        setCurrentWork({
                                            ...currentWork,
                                            research_outline: {
                                                ...currentWork.research_outline,
                                                research_papers_table_of_content: currentWork.research_outline.research_papers_table_of_content,
                                            }
                                        })
                                    }
                                }}>
                                <Icon name="arrow-up-outline" width={20} height={20} fill={'#333333'} style={{
                                    transform: [{ rotate: '180deg' }]
                                }} />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => {
                                setCurrentWork({
                                    ...currentWork,
                                    research_outline: {
                                        ...currentWork.research_outline,
                                        research_papers_table_of_content: currentWork.research_outline.research_papers_table_of_content.filter((c, i) => i !== index),
                                    }
                                })
                            }}>
                                <Icon name="trash-2-outline" width={20} height={20} fill={'#333333'} />
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View style={{
                        borderWidth: 1,
                        borderColor: 'rgba(0, 0, 0, 0.07)',
                        borderRadius: 4,
                        borderStyle: 'dashed',
                        padding: 10,
                        marginTop: 10,
                    }}>
                        {chapter.heading.map((heading, i) => {
                            return <View key={i} marginB-4 row centerV>
                                <View flex marginR-10>
                                    <CustomTextField value={heading} onChange={(value) => {
                                        setCurrentWork({
                                            ...currentWork,
                                            research_outline: {
                                                ...currentWork.research_outline,
                                                research_papers_table_of_content: currentWork.research_outline.research_papers_table_of_content.map((c, i) => {
                                                    if (i == index) {
                                                        return {
                                                            ...c,
                                                            heading: c.heading.map((h, j) => {
                                                                if (j == i) {
                                                                    return value
                                                                } else {
                                                                    return h
                                                                }
                                                            }),
                                                        }
                                                    } else {
                                                        return c
                                                    }
                                                }),
                                            }
                                        })
                                    }} />
                                </View>
                                <View row>
                                    <TouchableOpacity
                                        style={{
                                            marginRight: 10,
                                        }}
                                        onPress={() => {
                                            const new_headings = [...currentWork.research_outline.research_papers_table_of_content]
                                            new_headings[index].heading.splice(i + 1, 0, "")

                                            setCurrentWork({
                                                ...currentWork,
                                                research_outline: {
                                                    ...currentWork.research_outline,
                                                    research_papers_table_of_content: new_headings,
                                                }
                                            })
                                        }}
                                    >
                                        <Icon name="plus-square-outline" width={20} height={20} color={"#333333"} fill={'#333333'} />
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        style={{
                                            marginRight: 10,
                                        }}
                                        onPress={() => {
                                            //move up
                                            if (i > 0) {
                                                const temp = currentWork.research_outline.research_papers_table_of_content[index].heading[i - 1]
                                                currentWork.research_outline.research_papers_table_of_content[index].heading[i - 1] = currentWork.research_outline.research_papers_table_of_content[index].heading[i]
                                                currentWork.research_outline.research_papers_table_of_content[index].heading[i] = temp

                                                setCurrentWork({
                                                    ...currentWork,
                                                    research_outline: {
                                                        ...currentWork.research_outline,
                                                        research_papers_table_of_content: currentWork.research_outline.research_papers_table_of_content,
                                                    }
                                                })
                                            }
                                        }}
                                    >
                                        <Icon name="arrow-up-outline" width={20} height={20} color={"#333333"} fill={'#333333'} />
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        style={{
                                            marginRight: 10,
                                        }}
                                        onPress={() => {
                                            //move down
                                            if (i < currentWork.research_outline.research_papers_table_of_content[index].heading.length - 1) {
                                                const temp = currentWork.research_outline.research_papers_table_of_content[index].heading[i + 1]
                                                currentWork.research_outline.research_papers_table_of_content[index].heading[i + 1] = currentWork.research_outline.research_papers_table_of_content[index].heading[i]
                                                currentWork.research_outline.research_papers_table_of_content[index].heading[i] = temp

                                                setCurrentWork({
                                                    ...currentWork,
                                                    research_outline: {
                                                        ...currentWork.research_outline,
                                                        research_papers_table_of_content: currentWork.research_outline.research_papers_table_of_content,
                                                    }
                                                })
                                            }
                                        }}
                                    >
                                        <Icon name="arrow-up-outline" width={20} height={20} color={"#333333"} fill={'#333333'} style={{
                                            transform: [{ rotate: '180deg' }]
                                        }} />
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => {
                                            const new_headings = [...currentWork.research_outline.research_papers_table_of_content]
                                            new_headings[index].heading = new_headings[index].heading.filter((h, j) => j !== i)

                                            setCurrentWork({
                                                ...currentWork,
                                                research_outline: {
                                                    ...currentWork.research_outline,
                                                    research_papers_table_of_content: new_headings,
                                                }
                                            })
                                        }}>
                                        <Icon name="trash-2-outline" width={20} height={20} fill={'#333333'} />
                                    </TouchableOpacity>
                                </View>
                            </View>
                        })}
                    </View>
                </View>
            </View>
        }) : null}
    </View>
}
