// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, TouchableOpacity, KeyboardAwareScrollView } from 'react-native-ui-lib';
import DynamicForm, { CustomCheckbox, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, DangerButton, Label, PrimaryButton, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { App, Progress, Segmented, Steps } from "antd"
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { Icon } from "react-native-eva-icons"
import { HeaderResearch } from "./PaperListingScreen"
import { Path, Svg } from "react-native-svg"

const WorkSingleScreen = observer(function WorkSingleScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const permissions = GlobalMasterStore((state) => state.permissions);
    const setPermissions = GlobalMasterStore((state) => state.setPermissions);

    const token = GlobalMasterStore((state) => state.token);
    const setToken = GlobalMasterStore((state) => state.setToken);

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const [search, setSearch] = useState("");
    const [password, setPassword] = useState("");
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [sendingOTP, setSendingOTP] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [forgotEmail, setForgotEmail] = useState("");
    const [forgotOTP, setForgotOTP] = useState("");
    const [forgotPassword, setForgotPassword] = useState("");
    const email = GlobalMasterStore((state) => state.globalData.email);
    const researchist_id = GlobalMasterStore((state) => state.globalData.researchist_id);
    const uid = GlobalMasterStore((state) => state.globalData.uid);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const [searchTerm, setSearchTerm] = useState("");
    const [category_name, setCategoryName] = useState("");
    const [year, setYear] = useState(null);
    const [authors_name, setAuthorsName] = useState("");
    const [minimum_citation_counts, setMinimumCitationCounts] = useState("");

    const [current, setCurrent] = useState(0);
    const [primary_field, setPrimaryField] = useState("");
    const [sub_topic, setSubTopic] = useState("");
    const [interested_topics, setInterestedTopics] = useState([]);
    const [profileDetails, setProfileDetails] = useState({});
    const [planType, setPlanType] = useState("Monthly Plan");
    const [progress, setProgress] = useState(0);

    return (<View center style={{
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    }}>
        <SafeAreaView style={{
            width: "100%",
            height: Dimensions.get('window').height,
        }}>
            <KeyboardAwareScrollView style={{
                width: "100%",
                height: Dimensions.get('window').height,
                position: 'relative',
            }}>
                <View >
                    <HeaderResearch navigationFn={navigationFn} showPaperCount={true} />

                    <View padding-20 marginT-80 center>
                        <View row>
                            <Text style={{
                                fontSize: 52,
                                fontWeight: 'bold',
                                marginBottom: 10,
                            }}>Over</Text>
                            <Text style={{
                                fontSize: 52,
                                fontWeight: 'bold',
                                marginBottom: 10,
                                color: "#6e0d81",
                                marginHorizontal: 10,
                            }}>1 Billion Research Papers</Text>
                            <Text style={{
                                fontSize: 52,
                                fontWeight: 'bold',
                                marginBottom: 10,
                            }}>To Work With</Text>
                        </View>

                        <Text style={{
                            fontSize: 28,
                            marginBottom: 20,
                        }}>Start your research journey with us</Text>

                        {/* <View style={{
                                    flexDirection: 'row',
                                    marginBottom: 20,
                                }}>
                                    <SecondaryButton label="Take Researchist Tour" style={{
                                        marginRight: 10,
                                    }} />
                                </View> */}

                        {/* <Image
                                source={require('./path-to-your-image.png')}
                                style={styles.image}
                            /> */}

                        <Card marginT-40 style={{
                            padding: 10,
                            // background: #ffffff85;
                            // border-radius: 16px;
                            // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                            // backdrop-filter: blur(5px);
                            // -webkit-backdrop-filter: blur(5px);
                            // border: 1px solid rgba(255, 255, 255, 0.3);
                            width: "90%",
                            backgroundColor: "#ffffff85",
                            borderRadius: 16,
                            shadowColor: "#000",
                            shadowOffset: {
                                width: 0,
                                height: 4,
                            },
                            shadowOpacity: 0.1,
                            shadowRadius: 30,
                            elevation: 5,
                            backdropFilter: "blur(5px)",
                            WebkitBackdropFilter: "blur(5px)",
                            border: "1px solid rgba(255, 255, 255, 0.3)",
                        }}>
                            <View row>
                                <View flex row centerV marginL-10 padding-10 style={{
                                    borderBottomColor: '#00000045',
                                    borderBottomWidth: 1,
                                }}>
                                    <View row flex centerV>
                                        <Svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="icon"
                                            style={{
                                                width: "20px",
                                                verticalAlign: "middle",
                                                fill: "currentColor",
                                                overflow: "hidden",
                                            }}
                                            viewBox="0 0 1024 1024"
                                        >
                                            <Path d="M204.8 341.33v136.54h68.27V409.6h68.26v341.34h-68.26v68.26h204.8v-68.26H409.6V409.6h68.27v68.27h68.26V341.33zm614.4-68.26V204.8c-41.001 0-77.373 18.463-102.4 47.104-25.027-28.631-61.399-47.104-102.4-47.104v68.27a68.27 68.27 0 0 1 68.27 68.27v204.8H614.4v68.26h68.27v68.26a68.28 68.28 0 0 1-68.27 68.28v68.26c41.001 0 77.373-18.463 102.4-47.104 25.027 28.641 61.399 47.104 102.4 47.104v-68.26a68.28 68.28 0 0 1-68.27-68.28V614.4h68.27v-68.26h-68.27v-204.8a68.27 68.27 0 0 1 68.27-68.27z" />
                                        </Svg>
                                        <TextField
                                            placeholder="Search"
                                            placeholderTextColor="#00000045"
                                            style={{
                                                width: 250,
                                                height: 40,
                                                color: "#000",
                                                marginLeft: 10,
                                                fontSize: 14,
                                            }}
                                            onChangeText={(value) => {
                                                setSearchTerm(value);
                                            }}
                                        />
                                    </View>
                                    <TouchableOpacity onPress={() => {
                                        navigationFn("search-results", {
                                            search: searchTerm,
                                            category_name: category_name || "",
                                            year: year || "",
                                            authors_name: authors_name || "",
                                            minimum_citation_counts: minimum_citation_counts || "",
                                        });
                                    }}>
                                        <View style={{
                                            backgroundColor: "#20303c",
                                            borderRadius: 80,
                                            padding: 10,
                                            marginLeft: 10,
                                        }}>
                                            <Icon name="search-outline" width={16} height={16} fill="white" />
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            </View>
                            <View centerH>
                                <View center padding-10 marginV-10 style={{
                                    width: "100%",
                                }}>
                                    <View style={{
                                        width: "100%",
                                    }}>
                                        <View left row centerV>

                                            <View row centerV marginR-10 style={{
                                                flex: 1,
                                            }}>
                                                <View flex>
                                                    <View row centerV marginB-10>
                                                        <Icon name="calendar-outline" width={18} height={18} fill="#20303c" />
                                                        <Text marginL-5 style={{
                                                            fontSize: 14,
                                                            color: "#20303c",
                                                            fontWeight: 'bold',
                                                        }}>Published At</Text>
                                                    </View>

                                                    <CustomSelect
                                                        value={year}
                                                        placeholder="Published At"
                                                        options={[
                                                            { label: "2022", value: 2022 },
                                                            { label: "2021", value: 2021 },
                                                            { label: "2020", value: 2020 },
                                                            { label: "2019", value: 2019 },
                                                            { label: "2018", value: 2018 },
                                                            { label: "2017", value: 2017 },
                                                            { label: "2016", value: 2016 },
                                                            { label: "2015", value: 2015 },
                                                            { label: "2014", value: 2014 },
                                                            { label: "2013", value: 2013 },
                                                            { label: "2012", value: 2012 },
                                                            { label: "2011", value: 2011 },
                                                            { label: "2010", value: 2010 },
                                                            { label: "2009", value: 2009 },
                                                            { label: "2008", value: 2008 },
                                                            { label: "2007", value: 2007 },
                                                            { label: "2006", value: 2006 },
                                                            { label: "2005", value: 2005 },
                                                            { label: "2004", value: 2004 },
                                                            { label: "2003", value: 2003 },
                                                            { label: "2002", value: 2002 },
                                                            { label: "2001", value: 2001 },
                                                            { label: "2000", value: 2000 },
                                                        ]}
                                                        onChange={(value) => {
                                                            setYear(value);
                                                        }}
                                                        allowClear={true}
                                                    />
                                                </View>
                                            </View>

                                            <View row centerV marginR-10 style={{
                                                flex: 1,
                                            }}>
                                                {/* <View backgroundColor="#f0f0f0" marginR-10 style={{
                                                            borderRadius: 80,
                                                            padding: 10
                                                        }}>
                                                            <Icon name="hash-outline" width={40} height={40} fill="#000000" />
                                                        </View> */}
                                                <View flex>
                                                    <View row centerV marginB-10>
                                                        <Icon name="hash-outline" width={18} height={18} fill="#20303c" />
                                                        <Text marginL-5 style={{
                                                            fontSize: 14,
                                                            color: "#20303c",
                                                            fontWeight: 'bold',
                                                        }}>Category</Text>
                                                    </View>
                                                    <CustomSelect
                                                        value={category_name}
                                                        placeholder="Category"
                                                        options={[
                                                            { label: "Computer Science", value: "Computer Science" },
                                                            { label: "Mathematics", value: "Mathematics" },
                                                            { label: "Physics", value: "Physics" },
                                                            { label: "Chemistry", value: "Chemistry" },
                                                            { label: "Biology", value: "Biology" },
                                                            { label: "Medicine", value: "Medicine" },
                                                            { label: "Engineering", value: "Engineering" },
                                                            { label: "Economics", value: "Economics" },
                                                            { label: "Psychology", value: "Psychology" },
                                                            { label: "Sociology", value: "Sociology" },
                                                            { label: "Political Science", value: "Political Science" },
                                                            { label: "Philosophy", value: "Philosophy" },
                                                            { label: "History", value: "History" },
                                                            { label: "Geography", value: "Geography" },
                                                            { label: "Literature", value: "Literature" },
                                                            { label: "Art", value: "Art" },
                                                            { label: "Music", value: "Music" },
                                                            { label: "Film", value: "Film" },
                                                            { label: "Theatre", value: "Theatre" },
                                                            { label: "Dance", value: "Dance" },
                                                            { label: "Sports", value: "Sports" },
                                                            { label: "Games", value: "Games" },
                                                            { label: "Fashion", value: "Fashion" },
                                                            { label: "Food", value: "Food" },
                                                            { label: "Travel", value: "Travel" },
                                                            { label: "Education", value: "Education" },
                                                            { label: "Religion", value: "Religion" },
                                                            { label: "Law", value: "Law" },
                                                            { label: "Business", value: "Business" },
                                                            { label: "Finance", value: "Finance" },
                                                            { label: "Marketing", value: "Marketing" },
                                                            { label: "Management", value: "Management" },
                                                            { label: "Human Resources", value: "Human Resources" },
                                                            { label: "Accounting", value: "Accounting" },
                                                            { label: "Entrepreneurship", value: "Entrepreneurship" },
                                                            { label: "Public Relations", value: "Public Relations" },
                                                        ]}
                                                        onChange={(value) => {
                                                            setCategoryName(value);
                                                        }}
                                                        allowClear={true}
                                                    />
                                                </View>
                                            </View>

                                            <View marginR-10 style={{
                                                flex: 1,
                                            }}>
                                                <View row centerV marginR-10 style={{
                                                    flex: 1,
                                                }}>
                                                    {/* <View backgroundColor="#f0f0f0" marginR-10 style={{
                                                                borderRadius: 80,
                                                                padding: 10
                                                            }}>
                                                                <Icon name="map-outline" width={40} height={40} fill="#000000" />
                                                            </View> */}
                                                    <View flex>
                                                        <View row centerV marginB-10>
                                                            <Icon name="trending-up-outline" width={18} height={18} fill="#20303c" />
                                                            <Text
                                                                marginL-5
                                                                style={{
                                                                    fontSize: 14,
                                                                    color: "#000000",
                                                                    fontWeight: 'bold',
                                                                }}>Min Citation Counts</Text>
                                                        </View>
                                                        <CustomNumberInput
                                                            value={minimum_citation_counts}
                                                            placeholder="Min Citation Counts"
                                                            onChange={(value) => {
                                                                setMinimumCitationCounts(value);
                                                            }}
                                                        />
                                                    </View>
                                                </View>
                                            </View>

                                            <View style={{
                                                flex: 1,
                                            }}>
                                                <View row centerV marginR-10 style={{
                                                    flex: 1,
                                                }}>
                                                    {/* <View backgroundColor="#f0f0f0" marginR-10 style={{
                                                                borderRadius: 80,
                                                                padding: 10
                                                            }}>
                                                                <Icon name="person-outline" width={40} height={40} fill="#000000" />
                                                            </View> */}
                                                    <View flex>
                                                        <View row centerV marginB-10>
                                                            <Icon name="edit-outline" width={18} height={18} fill="#20303c" />
                                                            <Text
                                                                marginL-5
                                                                style={{
                                                                    fontSize: 14,
                                                                    color: "#000000",
                                                                    fontWeight: 'bold'
                                                                }}>Author Name</Text>
                                                        </View>
                                                        <CustomTextField
                                                            placeholder="Authors"
                                                            value={authors_name}
                                                            onChange={(value) => {
                                                                setAuthorsName(value);
                                                            }}
                                                        />
                                                    </View>
                                                </View>
                                            </View>

                                        </View>
                                    </View>
                                </View>
                            </View>
                        </Card>

                    </View>
                </View>

                <View style={{
                    position: 'absolute',
                    zIndex: 0,
                    height: Dimensions.get('window').height,
                    width: Dimensions.get('window').width,
                    backgroundColor: "#000000b3",
                    backdropFilter: "blur(2px)",
                    paddingVertical: 80,
                    paddingHorizontal: 280,
                }}>
                    <Card padding-20 flex style={{ borderRadius: 16 }}>
                        <View>
                            <Progress
                                strokeColor={"#a02b7c"}
                                percent={current * 20}
                                showInfo={false}
                            />
                        </View>

                        <View marginT-10>
                            {current === 0 && (
                                <View marginB-20>
                                    <View marginT-20>
                                        {Label({ label: "What should we call you?", fontSize: 28 })}

                                        <View row centerV>
                                            <Text marginR-10>
                                                Your name:
                                            </Text>
                                            <View flex>
                                                <CustomTextField
                                                    value={profileDetails.name}
                                                    placeholder="Name"
                                                    onChange={(value) => {
                                                        setProfileDetails({
                                                            ...profileDetails,
                                                            name: value,
                                                        });
                                                    }}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            )}

                            {current === 1 && (
                                <View marginB-20>
                                    <View marginT-20>
                                        {Label({ label: "What's your current academic status?", fontSize: 28 })}
                                        {[
                                            "Pre-undergraduate/High School Student",
                                            "Undergraduate Student",
                                            "Master's Student",
                                            "Doctoral Candidate",
                                            "Postdoctoral Researcher",
                                            "Assistant Professor",
                                            "Associate Professor",
                                            "Full Professor",
                                            "Research Scientist",
                                            "Independent Researcher",
                                            "Other"
                                        ].map((status) => (
                                            <View row centerV key={status}>
                                                <CustomCheckbox
                                                    value={profileDetails.academic_status === status}
                                                    onChange={(value) => {
                                                        setProfileDetails({
                                                            ...profileDetails,
                                                            academic_status: value ? status : "",
                                                        });
                                                    }}
                                                />
                                                <Text>{status}</Text>
                                            </View>
                                        ))}

                                        {profileDetails.academic_status === "Other" && (
                                            <View flex marginT-10>
                                                <CustomTextField
                                                    value={profileDetails.other_academic_status}
                                                    placeholder="Please specify"
                                                    onChange={(value) => {
                                                        setProfileDetails({
                                                            ...profileDetails,
                                                            other_academic_status: value,
                                                        });
                                                    }}
                                                />
                                            </View>
                                        )}

                                        <Text marginT-10>
                                            (This helps us tailor our tools to your specific needs)
                                        </Text>
                                    </View>
                                </View>
                            )}

                            {current === 2 && (
                                <View marginB-20>
                                    <View marginT-20>
                                        {Label({ label: "In which field of study do you primarily work?", fontSize: 28 })}

                                        <View row centerV>
                                            <Text marginR-10>
                                                Your primary field of study:
                                            </Text>

                                            <View flex>
                                                <CustomSelect
                                                    value={profileDetails.field_of_study}
                                                    options={[
                                                        { label: "Computer Science", value: "Computer Science" },
                                                        { label: "Medicine", value: "Medicine" },
                                                        { label: "Chemistry", value: "Chemistry" },
                                                        { label: "Biology", value: "Biology" },
                                                        { label: "Materials Science", value: "Materials Science" },
                                                        { label: "Physics", value: "Physics" },
                                                        { label: "Geology", value: "Geology" },
                                                        { label: "Psychology", value: "Psychology" },
                                                        { label: "Art", value: "Art" },
                                                        { label: "History", value: "History" },
                                                        { label: "Geography", value: "Geography" },
                                                        { label: "Sociology", value: "Sociology" },
                                                        { label: "Business", value: "Business" },
                                                        { label: "Political Science", value: "Political Science" },
                                                        { label: "Economics", value: "Economics" },
                                                        { label: "Philosophy", value: "Philosophy" },
                                                        { label: "Mathematics", value: "Mathematics" },
                                                        { label: "Engineering", value: "Engineering" },
                                                        { label: "Environmental Science", value: "Environmental Science" },
                                                        { label: "Agricultural and Food Sciences", value: "Agricultural and Food Sciences" },
                                                        { label: "Education", value: "Education" },
                                                        { label: "Law", value: "Law" },
                                                        { label: "Linguistics", value: "Linguistics" }
                                                    ]}
                                                    placeholder="Your primary field of study"
                                                    onChange={(value) => {
                                                        setProfileDetails({
                                                            ...profileDetails,
                                                            field_of_study: value,
                                                        });
                                                    }}
                                                />
                                            </View>
                                        </View>

                                        <Text marginT-10>
                                            (We'll use this to connect you with relevant research)
                                        </Text>
                                    </View>
                                </View>
                            )}

                            {current === 3 && (
                                <View marginB-20>
                                    <View marginT-20>
                                        {Label({ label: "Now, let's zoom in a bit. What's your specific area of focus?", fontSize: 28 })}

                                        <View row centerV>
                                            <Text marginR-10>
                                                Subtopic or specialization:
                                            </Text>
                                            <View flex>
                                                <CustomSelect
                                                    value={profileDetails.area_of_focus}
                                                    placeholder="Subtopic or specialization"
                                                    options={({
                                                        "Computer Science": [
                                                            "Artificial Intelligence",
                                                            "Machine Learning",
                                                            "Computer Networks",
                                                            "Cybersecurity",
                                                            "Database Systems",
                                                            "Software Engineering",
                                                            "Computer Graphics",
                                                            "Human-Computer Interaction",
                                                            "Algorithms and Complexity",
                                                            "Distributed Systems"
                                                        ],
                                                        "Medicine": [
                                                            "Cardiology",
                                                            "Neurology",
                                                            "Oncology",
                                                            "Pediatrics",
                                                            "Immunology",
                                                            "Endocrinology",
                                                            "Surgery",
                                                            "Psychiatry",
                                                            "Radiology",
                                                            "Public Health"
                                                        ],
                                                        "Chemistry": [
                                                            "Organic Chemistry",
                                                            "Inorganic Chemistry",
                                                            "Physical Chemistry",
                                                            "Analytical Chemistry",
                                                            "Biochemistry",
                                                            "Polymer Chemistry",
                                                            "Computational Chemistry",
                                                            "Environmental Chemistry",
                                                            "Medicinal Chemistry",
                                                            "Nanochemistry"
                                                        ],
                                                        "Biology": [
                                                            "Molecular Biology",
                                                            "Genetics",
                                                            "Ecology",
                                                            "Microbiology",
                                                            "Zoology",
                                                            "Botany",
                                                            "Marine Biology",
                                                            "Evolutionary Biology",
                                                            "Cell Biology",
                                                            "Bioinformatics"
                                                        ],
                                                        "Materials Science": [
                                                            "Nanomaterials",
                                                            "Biomaterials",
                                                            "Polymers",
                                                            "Ceramics",
                                                            "Metallurgy",
                                                            "Composite Materials",
                                                            "Electronic Materials",
                                                            "Energy Materials",
                                                            "Structural Materials",
                                                            "Computational Materials Science"
                                                        ],
                                                        "Physics": [
                                                            "Quantum Physics",
                                                            "Astrophysics",
                                                            "Particle Physics",
                                                            "Condensed Matter Physics",
                                                            "Nuclear Physics",
                                                            "Optics",
                                                            "Thermodynamics",
                                                            "Relativity",
                                                            "Plasma Physics",
                                                            "Biophysics"
                                                        ],
                                                        "Geology": [
                                                            "Mineralogy",
                                                            "Petrology",
                                                            "Geomorphology",
                                                            "Tectonics",
                                                            "Paleontology",
                                                            "Hydrogeology",
                                                            "Volcanology",
                                                            "Seismology",
                                                            "Geochemistry",
                                                            "Stratigraphy"
                                                        ],
                                                        "Psychology": [
                                                            "Clinical Psychology",
                                                            "Cognitive Psychology",
                                                            "Developmental Psychology",
                                                            "Social Psychology",
                                                            "Neuropsychology",
                                                            "Industrial-Organizational Psychology",
                                                            "Educational Psychology",
                                                            "Health Psychology",
                                                            "Forensic Psychology",
                                                            "Environmental Psychology"
                                                        ],
                                                        "Art": [
                                                            "Art History",
                                                            "Visual Arts",
                                                            "Performing Arts",
                                                            "Digital Arts",
                                                            "Art Therapy",
                                                            "Art Education",
                                                            "Curatorial Studies",
                                                            "Art Conservation",
                                                            "Art Criticism",
                                                            "Art Theory"
                                                        ],
                                                        "History": [
                                                            "Ancient History",
                                                            "Medieval History",
                                                            "Modern History",
                                                            "Military History",
                                                            "Social History",
                                                            "Cultural History",
                                                            "Economic History",
                                                            "Intellectual History",
                                                            "World History",
                                                            "Historiography"
                                                        ],
                                                        "Geography": [
                                                            "Physical Geography",
                                                            "Human Geography",
                                                            "Geographic Information Systems (GIS)",
                                                            "Cartography",
                                                            "Urban Geography",
                                                            "Economic Geography",
                                                            "Political Geography",
                                                            "Cultural Geography",
                                                            "Biogeography",
                                                            "Climatology"
                                                        ],
                                                        "Sociology": [
                                                            "Social Theory",
                                                            "Urban Sociology",
                                                            "Rural Sociology",
                                                            "Medical Sociology",
                                                            "Environmental Sociology",
                                                            "Criminology",
                                                            "Gender Studies",
                                                            "Race and Ethnicity Studies",
                                                            "Organizational Sociology",
                                                            "Social Stratification"
                                                        ],
                                                        "Business": [
                                                            "Marketing",
                                                            "Finance",
                                                            "Management",
                                                            "Accounting",
                                                            "International Business",
                                                            "Entrepreneurship",
                                                            "Operations Management",
                                                            "Human Resource Management",
                                                            "Business Ethics",
                                                            "Strategic Management"
                                                        ],
                                                        "Political Science": [
                                                            "Comparative Politics",
                                                            "International Relations",
                                                            "Political Theory",
                                                            "Public Policy",
                                                            "Public Administration",
                                                            "Political Economy",
                                                            "Electoral Studies",
                                                            "Political Behavior",
                                                            "Geopolitics",
                                                            "Constitutional Studies"
                                                        ],
                                                        "Economics": [
                                                            "Microeconomics",
                                                            "Macroeconomics",
                                                            "Econometrics",
                                                            "Development Economics",
                                                            "International Economics",
                                                            "Labor Economics",
                                                            "Environmental Economics",
                                                            "Behavioral Economics",
                                                            "Health Economics",
                                                            "Financial Economics"
                                                        ],
                                                        "Philosophy": [
                                                            "Ethics",
                                                            "Logic",
                                                            "Metaphysics",
                                                            "Epistemology",
                                                            "Philosophy of Science",
                                                            "Philosophy of Mind",
                                                            "Political Philosophy",
                                                            "Aesthetics",
                                                            "Existentialism",
                                                            "Philosophy of Language"
                                                        ],
                                                        "Mathematics": [
                                                            "Algebra",
                                                            "Analysis",
                                                            "Geometry",
                                                            "Number Theory",
                                                            "Topology",
                                                            "Probability Theory",
                                                            "Statistics",
                                                            "Applied Mathematics",
                                                            "Discrete Mathematics",
                                                            "Mathematical Logic"
                                                        ],
                                                        "Engineering": [
                                                            "Mechanical Engineering",
                                                            "Electrical Engineering",
                                                            "Civil Engineering",
                                                            "Chemical Engineering",
                                                            "Aerospace Engineering",
                                                            "Biomedical Engineering",
                                                            "Environmental Engineering",
                                                            "Industrial Engineering",
                                                            "Software Engineering",
                                                            "Robotics Engineering"
                                                        ],
                                                        "Environmental Science": [
                                                            "Ecology",
                                                            "Climate Science",
                                                            "Environmental Chemistry",
                                                            "Conservation Biology",
                                                            "Environmental Policy",
                                                            "Sustainable Development",
                                                            "Pollution Control",
                                                            "Renewable Energy",
                                                            "Waste Management",
                                                            "Natural Resource Management"
                                                        ],
                                                        "Agricultural and Food Sciences": [
                                                            "Agronomy",
                                                            "Animal Science",
                                                            "Horticulture",
                                                            "Food Technology",
                                                            "Soil Science",
                                                            "Plant Pathology",
                                                            "Agricultural Economics",
                                                            "Agricultural Engineering",
                                                            "Crop Science",
                                                            "Food Safety"
                                                        ],
                                                        "Education": [
                                                            "Curriculum and Instruction",
                                                            "Educational Psychology",
                                                            "Special Education",
                                                            "Higher Education",
                                                            "Early Childhood Education",
                                                            "Educational Technology",
                                                            "Educational Leadership",
                                                            "Adult Education",
                                                            "Comparative Education",
                                                            "STEM Education"
                                                        ],
                                                        "Law": [
                                                            "Constitutional Law",
                                                            "Criminal Law",
                                                            "Civil Law",
                                                            "International Law",
                                                            "Corporate Law",
                                                            "Environmental Law",
                                                            "Intellectual Property Law",
                                                            "Human Rights Law",
                                                            "Tax Law",
                                                            "Health Law"
                                                        ],
                                                        "Linguistics": [
                                                            "Phonetics",
                                                            "Phonology",
                                                            "Syntax",
                                                            "Semantics",
                                                            "Pragmatics",
                                                            "Sociolinguistics",
                                                            "Psycholinguistics",
                                                            "Historical Linguistics",
                                                            "Computational Linguistics",
                                                            "Applied Linguistics"
                                                        ]
                                                    }[profileDetails.field_of_study] || []).map((subField) => (
                                                        { label: subField, value: subField }
                                                    ))}
                                                    onChange={(value) => {
                                                        setProfileDetails({
                                                            ...profileDetails,
                                                            area_of_focus: value,
                                                        });
                                                    }}
                                                />
                                            </View>
                                        </View>

                                        <Text marginT-10>
                                            (For example, if your field is Computer Science, you might enter Machine Learning, AI, Cybersecurity, etc.)
                                        </Text>

                                        <Text marginT-10>
                                            (This helps us find even more targeted research for you)
                                        </Text>
                                    </View>
                                </View>
                            )}

                            {current === 4 && (
                                <View marginB-20>
                                    <View marginT-20>
                                        {Label({ label: "What's your research passion? Give us your top 3-5 keywords!", fontSize: 28 })}

                                        <View row centerV>
                                            <Text marginR-10>
                                                Research interests:
                                            </Text>
                                            <View flex>
                                                <CustomTextField
                                                    value={profileDetails.research_passion}
                                                    placeholder="Research interests"
                                                    onChange={(value) => {
                                                        setProfileDetails({
                                                            ...profileDetails,
                                                            research_passion: value,
                                                        });
                                                    }}
                                                />
                                            </View>
                                        </View>

                                        <Text marginT-10>
                                            (Example: Machine Learning, AI, Cybersecurity)
                                        </Text>

                                        <Text marginT-10>
                                            (This helps our AI find the most relevant papers for you)
                                        </Text>
                                    </View>
                                </View>
                            )}

                            {current === 5 && (
                                <View marginB-20>
                                    <View marginT-20>
                                        {Label({ label: "How did you come to know about us?", fontSize: 28 })}
                                        {[
                                            "Reddit", "LinkedIn", "Twitter", "YouTube", "Facebook",
                                            "Instagram", "Google Search", "Colleagues/Friends", "Other"
                                        ].map((source) => (
                                            <View row centerV key={source}>
                                                <CustomCheckbox
                                                    value={profileDetails.discovered_through === source}
                                                    onChange={(value) => {
                                                        setProfileDetails({
                                                            ...profileDetails,
                                                            discovered_through: value ? source : "",
                                                        });
                                                    }}
                                                />
                                                <Text>{source}</Text>
                                            </View>
                                        ))}
                                        {profileDetails.discovered_through === "Other" && (
                                            <View flex marginT-10>
                                                <CustomTextField
                                                    value={profileDetails.other_discovered_through}
                                                    placeholder="Please specify"
                                                    onChange={(value) => {
                                                        setProfileDetails({
                                                            ...profileDetails,
                                                            other_discovery_source: value,
                                                        });
                                                    }}
                                                />
                                            </View>
                                        )}

                                        <Text marginT-10>
                                            (Your answer helps us reach more researchers like you!)
                                        </Text>
                                    </View>
                                </View>
                            )}
                        </View>

                        <View marginT-20>
                            <View row spread>
                                {current > 0 ? <SecondaryButton
                                    style={{ marginRight: 10 }}
                                    onPress={() => {
                                        if (current > 0) {
                                            setCurrent(current - 1);
                                        }
                                    }}
                                >
                                    <View row>
                                        <Icon name="arrow-back-outline" width={16} height={16} fill="#20303c" />
                                        <Text marginL-4 style={{ fontFamily: 'SourceSansProSemiBold' }}>Go Back</Text>
                                    </View>

                                </SecondaryButton> : <View></View>}

                                <PrimaryButton
                                    label={current == 5 ? "Finish" : "Next"}
                                    onPress={async () => {

                                        // const email = GlobalMasterStore((state) => state.email);
                                        // const researchist_id = GlobalMasterStore((state) => state.researchist_id);
                                        // const uid = GlobalMasterStore((state) => state.uid);

                                        console.log("email", email);
                                        console.log("researchist_id", researchist_id);
                                        console.log("uid", uid);


                                        // check if all fields are filled
                                        if (current == 0 && !profileDetails.name) {
                                            message.error("Please enter your name");
                                            return;
                                        }

                                        if (current == 1 && !profileDetails.academic_status) {
                                            message.error("Please select your academic status");
                                            return;
                                        }

                                        if (current == 1 && profileDetails.academic_status === "Other" && !profileDetails.other_academic_status) {
                                            message.error("Please specify your academic status");
                                            return;
                                        }

                                        if (current == 2 && !profileDetails.field_of_study) {
                                            message.error("Please select your field of study");
                                            return;
                                        }

                                        if (current == 3 && !profileDetails.area_of_focus) {
                                            message.error("Please enter your area of focus");
                                            return;
                                        }

                                        if (current == 4 && !profileDetails.research_passion) {
                                            message.error("Please enter your research passion");
                                            return;
                                        }

                                        if (current == 5) {
                                            await api.customRoute("save-user", {
                                                user: {
                                                    _id: researchist_id,
                                                    email: email,
                                                    uid: uid,
                                                    ...profileDetails,
                                                }
                                            })

                                            setGlobalData({
                                                name: profileDetails.name,
                                            });

                                            navigationFn("home");
                                            return;
                                        }

                                        setCurrent(current + 1);
                                    }}
                                />
                            </View>
                        </View>
                    </Card>
                </View>

            </KeyboardAwareScrollView>
        </SafeAreaView>
    </View >)
})

export default WorkSingleScreen

const $container = {
    flex: 1,
}