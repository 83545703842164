// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, TouchableOpacity, KeyboardAwareScrollView } from 'react-native-ui-lib';
import DynamicForm, { CustomDatePicker, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, DangerButton, Label, PrimaryButton, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { App } from "antd"
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { Icon } from "react-native-eva-icons"
import { HeaderResearch } from "./PaperListingScreen"
import { Path, Svg } from "react-native-svg"
import SubscriptionPage from "./Subscription"

const SubscriptionScreen = observer(function SubscriptionScreen(props) {
    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }


    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const [searchTerm, setSearchTerm] = useState("");
    const [category_name, setCategoryName] = useState("");
    const [year, setYear] = useState(null);
    const [authors_name, setAuthorsName] = useState("");
    const [minimum_citation_counts, setMinimumCitationCounts] = useState("");
    const email = GlobalMasterStore((state) => state.globalData.email);

    const [suggested_searches, setSuggestedSearches] = useState([]);

    const getUser = async () => {
        console.log("email", email);

        try {
            const response = await api.customRoute("get-user", {
                email: email,
            });

            if (response.user.relavant_search_terms) {
                setSuggestedSearches(response.user.relavant_search_terms);

            }
        } catch (error) {
        }
    }

    useEffect(() => {
        getUser();
    }, [email]);

    return (<View center style={{
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    }}>
        <SafeAreaView style={{
            width: "100%",
            height: Dimensions.get('window').height,
        }}>
            <KeyboardAwareScrollView style={{
                width: "100%",
                height: Dimensions.get('window').height,
            }}>
                <View row style={{
                    width: "100%",
                    height: Dimensions.get('window').height,
                }}>

                    <View flexG
                        style={{
                            width: "100%",
                            height: Platform.OS == 'web' ? Dimensions.get('window').height : undefined,
                            position: 'relative',
                            zIndex: 1,
                        }}
                    >

                        <View >
                            <HeaderResearch navigationFn={navigationFn} showPaperCount={true} />

                            <View padding-20 marginT-40 center>
                                <SubscriptionPage />
                            </View>
                        </View>
                    </View>
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>
    </View >)
})

export default SubscriptionScreen

const $container = {
    flex: 1,
}