// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, DeviceEventEmitter, TouchableOpacity } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, ProgressBar } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomDatePicker, CustomModal, CustomNumberInput, CustomSelect, CustomTextAreaField, CustomTextField, CustomTextFieldWithScanner, CustomUpload, DangerButton, Heading, Label, PrimaryButton, Row, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import { Icon } from "react-native-eva-icons"
import { CustomSelectItemForContacts, TransactionPDFPreview, invoiceItemAdjustment } from "../../../../../models/accountancy/transaction"
import { create } from 'zustand'
import { FlashList } from "@shopify/flash-list";
import { GlobalModalStore } from "../../../../../models/ModalStore"
import { App } from 'antd';
import * as Sharing from 'expo-sharing';
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import CalendarStrip from 'react-native-calendar-strip';
import moment from "moment"
import Svg, { Circle, Path, Rect } from "react-native-svg"
import { Agenda } from 'react-native-calendars';
import { AgendaIcon, DateIcon, InviteIcon } from "../../../../../models/taqwa/Meeting/Meetings"
import RenderHtml from 'react-native-render-html';
import FaceAuth from "../main/FaceAuth/FaceAuth"
import * as Location from 'expo-location';
import EventsScreen from "./EventsScreen"
import ListingScreen from "../../../CRUD/ListingScreen"
import ReportScreenSingle from "../../../CRUD/ReportScreenSingle"


const GoalsScreen = observer(function GoalsScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    let queryParams = { ...(props?.route?.params || {}) };

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const goBack = () => {
        if (Platform.OS === 'web') {
            window.history.back();
        } else {
            navigation.goBack();
        }
    }

    const moduleName = "DailyActivities";
    const { fields, dataTabs, preferencesFields, ExtraSaveComponent } = api.getForm(moduleName);
    const params = {};

    const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
    const globalData = GlobalMasterStore((state) => state.globalData);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const employee = GlobalMasterStore((state) => state.employee);

    const employee_id = employee._id;

    const [dates, setDates] = useState([
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
    ]);

    const [month, setMonth] = useState(moment().month() + 1);

    const [goalType, setGoalType] = useState('Goals');
    const [updateKey, setUpdateKey] = useState(null);

    useEffect(() => {
        setUpdateKey(Math.random());
    }, [dates, goalType]);

    return (
        <View padding-10 style={{
            flex: 1
        }}>
            <View w-100 flex>
                <View spread>
                    <SegmentedControl
                        outlineColor={"rgb(41, 69, 156)"} activeColor={"rgb(41, 69, 156)"} borderRadius={4}
                        segments={[
                            { label: 'Goals', value: 'Goals' },
                            { label: 'KPIs', value: 'KPIs' },
                        ]}
                        onChangeIndex={(index) => {
                            setGoalType(index == 0 ? 'Goals' : 'KPIs');
                        }}
                        value={goalType}
                    />

                    {/* <View marginT-10 style={{
                        width: '100%'
                    }}>
                        <View row spread centerV>
                            <TouchableOpacity onPress={() => {
                                setDates([
                                    moment(dates[0]).subtract(1, 'months').toDate(),
                                    moment(dates[1]).subtract(1, 'months').toDate(),
                                ]);
                            }} style={{
                                paddingLeft: 20,
                                paddingRight: 20
                            }}>
                                <Icon name="arrow-back" width={24} height={24} fill={colors.primary} />
                            </TouchableOpacity>
                            <Text h1>{moment(dates[0]).format('MMMM YYYY')}</Text>
                            <TouchableOpacity onPress={() => {
                                setDates([
                                    moment(dates[0]).add(1, 'months').toDate(),
                                    moment(dates[1]).add(1, 'months').toDate(),
                                ]);
                            }} style={{
                                paddingLeft: 20,
                                paddingRight: 20
                            }}>
                                <Icon name="arrow-forward" width={24} height={24} fill={colors.primary} />
                            </TouchableOpacity>
                        </View>
                    </View> */}
                </View>
                {updateKey != null && <>{goalType == 'Goals' ? <ListingScreen
                    key={updateKey}
                    {...{
                        moduleName: 'Goals',
                        hideWrapper: true,
                        extraFilters: {
                            'employee_id': employee_id ?? null,
                        },
                        hideViews: true,
                        hideMainHeader: true,
                    }}
                    goalType={goalType}
                    title={" "}
                    disabledClone={true}
                    disableDelete={true}
                /> :
                    <ReportScreenSingle
                        moduleName={"EmployeeKPIDetails"}
                        hideWrapper={true}
                        noMono={true}
                        hideHeader={true}
                        params={{
                            quickFilterKey: 'employee_id',
                            quickFilterValue: employee_id,
                            hideQuickFilter: true,
                        }}
                        route={{
                            params: {
                                quickFilterKey: 'employee_id',
                                quickFilterValue: employee_id,
                                hideQuickFilter: true,
                                name: "EmployeeKPIDetails",
                            }
                        }}
                    />}
                </>}
            </View>
        </View>
    )
})

export default GoalsScreen