// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, TouchableOpacity, KeyboardAwareScrollView } from 'react-native-ui-lib';
import DynamicForm, { CustomCheckbox, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, DangerButton, Label, PrimaryButton, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { App, Table } from "antd"
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { Icon } from "react-native-eva-icons"
import { HeaderResearch } from "./PaperListingScreen"
import { Path, Svg } from "react-native-svg"

const ProfileScreenResearchist = observer(function ProfileScreenResearchist(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const permissions = GlobalMasterStore((state) => state.permissions);
    const setPermissions = GlobalMasterStore((state) => state.setPermissions);

    const token = GlobalMasterStore((state) => state.token);
    const setToken = GlobalMasterStore((state) => state.setToken);

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const [search, setSearch] = useState("");
    const [password, setPassword] = useState("");
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [sendingOTP, setSendingOTP] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [forgotEmail, setForgotEmail] = useState("");
    const [forgotOTP, setForgotOTP] = useState("");
    const [forgotPassword, setForgotPassword] = useState("");
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const email = GlobalMasterStore((state) => state.globalData.email);
    const [profileDetails, setProfileDetails] = useState({});

    const getUser = async () => {
        const res = await api.customRoute("get-user", {
            email: email,
        });

        setProfileDetails(res.user);
    }

    useEffect(() => {
        getUser();
    }, [email]);

    return (<View center style={{
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    }}>
        <SafeAreaView style={{
            width: "100%",
            height: Dimensions.get('window').height,
        }}>
            <KeyboardAwareScrollView style={{
                width: "100%",
                height: Dimensions.get('window').height,
            }}>
                <View row style={{
                    width: "100%",
                    height: Dimensions.get('window').height,
                }}>

                    <View flexG
                        style={{
                            width: "100%",
                            height: Platform.OS == 'web' ? Dimensions.get('window').height : undefined,
                            position: 'relative',
                            zIndex: 1,
                        }}
                    >
                        <HeaderResearch navigationFn={navigationFn} showPaperCount={true} />

                        <View row style={{
                            flexWrap: 'wrap',
                            padding: 20,
                        }}>
                            <Card flex padding-20>
                                <View marginT-10>
                                    <View marginB-20>
                                        <View marginT-20>
                                            {Label({ label: "Name", fontSize: 16 })}

                                            <View row centerV>
                                                <View flex>
                                                    <CustomTextField
                                                        value={profileDetails.name}
                                                        placeholder="Name"
                                                        onChange={(value) => {
                                                            setProfileDetails({
                                                                ...profileDetails,
                                                                name: value,
                                                            });
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                    </View>

                                    <View marginB-20>
                                        <View marginT-20>
                                            {Label({ label: "Academic status", fontSize: 16 })}
                                            {[
                                                "Pre-undergraduate/High School Student",
                                                "Undergraduate Student",
                                                "Master's Student",
                                                "Doctoral Candidate",
                                                "Postdoctoral Researcher",
                                                "Assistant Professor",
                                                "Associate Professor",
                                                "Full Professor",
                                                "Research Scientist",
                                                "Independent Researcher",
                                                "Other"
                                            ].map((status) => (
                                                <View row centerV key={status}>
                                                    <CustomCheckbox
                                                        value={profileDetails.academic_status === status}
                                                        onChange={(value) => {
                                                            setProfileDetails({
                                                                ...profileDetails,
                                                                academic_status: value ? status : "",
                                                            });
                                                        }}
                                                    />
                                                    <Text>{status}</Text>
                                                </View>
                                            ))}

                                            {profileDetails.academic_status === "Other" && (
                                                <View flex marginT-10>
                                                    <CustomTextField
                                                        value={profileDetails.other_academic_status}
                                                        placeholder="Please specify"
                                                        onChange={(value) => {
                                                            setProfileDetails({
                                                                ...profileDetails,
                                                                other_academic_status: value,
                                                            });
                                                        }}
                                                    />
                                                </View>
                                            )}
                                        </View>
                                    </View>

                                    <View marginB-20>
                                        <View marginT-20>
                                            {Label({ label: "Field of study", fontSize: 16 })}

                                            <View row centerV>
                                                <View flex>
                                                    <CustomSelect
                                                        value={profileDetails.field_of_study}
                                                        options={[
                                                            { label: "Computer Science", value: "Computer Science" },
                                                            { label: "Medicine", value: "Medicine" },
                                                            { label: "Chemistry", value: "Chemistry" },
                                                            { label: "Biology", value: "Biology" },
                                                            { label: "Materials Science", value: "Materials Science" },
                                                            { label: "Physics", value: "Physics" },
                                                            { label: "Geology", value: "Geology" },
                                                            { label: "Psychology", value: "Psychology" },
                                                            { label: "Art", value: "Art" },
                                                            { label: "History", value: "History" },
                                                            { label: "Geography", value: "Geography" },
                                                            { label: "Sociology", value: "Sociology" },
                                                            { label: "Business", value: "Business" },
                                                            { label: "Political Science", value: "Political Science" },
                                                            { label: "Economics", value: "Economics" },
                                                            { label: "Philosophy", value: "Philosophy" },
                                                            { label: "Mathematics", value: "Mathematics" },
                                                            { label: "Engineering", value: "Engineering" },
                                                            { label: "Environmental Science", value: "Environmental Science" },
                                                            { label: "Agricultural and Food Sciences", value: "Agricultural and Food Sciences" },
                                                            { label: "Education", value: "Education" },
                                                            { label: "Law", value: "Law" },
                                                            { label: "Linguistics", value: "Linguistics" }
                                                        ]}
                                                        placeholder="Your primary field of study"
                                                        onChange={(value) => {
                                                            setProfileDetails({
                                                                ...profileDetails,
                                                                field_of_study: value,
                                                            });
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                    </View>

                                    <View marginB-20>
                                        <View marginT-20>
                                            {Label({ label: "Area of focus", fontSize: 16 })}

                                            <View row centerV>
                                                <View flex>
                                                    <CustomSelect
                                                        value={profileDetails.area_of_focus}
                                                        placeholder="Subtopic or specialization"
                                                        options={({
                                                            "Computer Science": [
                                                                "Artificial Intelligence",
                                                                "Machine Learning",
                                                                "Computer Networks",
                                                                "Cybersecurity",
                                                                "Database Systems",
                                                                "Software Engineering",
                                                                "Computer Graphics",
                                                                "Human-Computer Interaction",
                                                                "Algorithms and Complexity",
                                                                "Distributed Systems"
                                                            ],
                                                            "Medicine": [
                                                                "Cardiology",
                                                                "Neurology",
                                                                "Oncology",
                                                                "Pediatrics",
                                                                "Immunology",
                                                                "Endocrinology",
                                                                "Surgery",
                                                                "Psychiatry",
                                                                "Radiology",
                                                                "Public Health"
                                                            ],
                                                            "Chemistry": [
                                                                "Organic Chemistry",
                                                                "Inorganic Chemistry",
                                                                "Physical Chemistry",
                                                                "Analytical Chemistry",
                                                                "Biochemistry",
                                                                "Polymer Chemistry",
                                                                "Computational Chemistry",
                                                                "Environmental Chemistry",
                                                                "Medicinal Chemistry",
                                                                "Nanochemistry"
                                                            ],
                                                            "Biology": [
                                                                "Molecular Biology",
                                                                "Genetics",
                                                                "Ecology",
                                                                "Microbiology",
                                                                "Zoology",
                                                                "Botany",
                                                                "Marine Biology",
                                                                "Evolutionary Biology",
                                                                "Cell Biology",
                                                                "Bioinformatics"
                                                            ],
                                                            "Materials Science": [
                                                                "Nanomaterials",
                                                                "Biomaterials",
                                                                "Polymers",
                                                                "Ceramics",
                                                                "Metallurgy",
                                                                "Composite Materials",
                                                                "Electronic Materials",
                                                                "Energy Materials",
                                                                "Structural Materials",
                                                                "Computational Materials Science"
                                                            ],
                                                            "Physics": [
                                                                "Quantum Physics",
                                                                "Astrophysics",
                                                                "Particle Physics",
                                                                "Condensed Matter Physics",
                                                                "Nuclear Physics",
                                                                "Optics",
                                                                "Thermodynamics",
                                                                "Relativity",
                                                                "Plasma Physics",
                                                                "Biophysics"
                                                            ],
                                                            "Geology": [
                                                                "Mineralogy",
                                                                "Petrology",
                                                                "Geomorphology",
                                                                "Tectonics",
                                                                "Paleontology",
                                                                "Hydrogeology",
                                                                "Volcanology",
                                                                "Seismology",
                                                                "Geochemistry",
                                                                "Stratigraphy"
                                                            ],
                                                            "Psychology": [
                                                                "Clinical Psychology",
                                                                "Cognitive Psychology",
                                                                "Developmental Psychology",
                                                                "Social Psychology",
                                                                "Neuropsychology",
                                                                "Industrial-Organizational Psychology",
                                                                "Educational Psychology",
                                                                "Health Psychology",
                                                                "Forensic Psychology",
                                                                "Environmental Psychology"
                                                            ],
                                                            "Art": [
                                                                "Art History",
                                                                "Visual Arts",
                                                                "Performing Arts",
                                                                "Digital Arts",
                                                                "Art Therapy",
                                                                "Art Education",
                                                                "Curatorial Studies",
                                                                "Art Conservation",
                                                                "Art Criticism",
                                                                "Art Theory"
                                                            ],
                                                            "History": [
                                                                "Ancient History",
                                                                "Medieval History",
                                                                "Modern History",
                                                                "Military History",
                                                                "Social History",
                                                                "Cultural History",
                                                                "Economic History",
                                                                "Intellectual History",
                                                                "World History",
                                                                "Historiography"
                                                            ],
                                                            "Geography": [
                                                                "Physical Geography",
                                                                "Human Geography",
                                                                "Geographic Information Systems (GIS)",
                                                                "Cartography",
                                                                "Urban Geography",
                                                                "Economic Geography",
                                                                "Political Geography",
                                                                "Cultural Geography",
                                                                "Biogeography",
                                                                "Climatology"
                                                            ],
                                                            "Sociology": [
                                                                "Social Theory",
                                                                "Urban Sociology",
                                                                "Rural Sociology",
                                                                "Medical Sociology",
                                                                "Environmental Sociology",
                                                                "Criminology",
                                                                "Gender Studies",
                                                                "Race and Ethnicity Studies",
                                                                "Organizational Sociology",
                                                                "Social Stratification"
                                                            ],
                                                            "Business": [
                                                                "Marketing",
                                                                "Finance",
                                                                "Management",
                                                                "Accounting",
                                                                "International Business",
                                                                "Entrepreneurship",
                                                                "Operations Management",
                                                                "Human Resource Management",
                                                                "Business Ethics",
                                                                "Strategic Management"
                                                            ],
                                                            "Political Science": [
                                                                "Comparative Politics",
                                                                "International Relations",
                                                                "Political Theory",
                                                                "Public Policy",
                                                                "Public Administration",
                                                                "Political Economy",
                                                                "Electoral Studies",
                                                                "Political Behavior",
                                                                "Geopolitics",
                                                                "Constitutional Studies"
                                                            ],
                                                            "Economics": [
                                                                "Microeconomics",
                                                                "Macroeconomics",
                                                                "Econometrics",
                                                                "Development Economics",
                                                                "International Economics",
                                                                "Labor Economics",
                                                                "Environmental Economics",
                                                                "Behavioral Economics",
                                                                "Health Economics",
                                                                "Financial Economics"
                                                            ],
                                                            "Philosophy": [
                                                                "Ethics",
                                                                "Logic",
                                                                "Metaphysics",
                                                                "Epistemology",
                                                                "Philosophy of Science",
                                                                "Philosophy of Mind",
                                                                "Political Philosophy",
                                                                "Aesthetics",
                                                                "Existentialism",
                                                                "Philosophy of Language"
                                                            ],
                                                            "Mathematics": [
                                                                "Algebra",
                                                                "Analysis",
                                                                "Geometry",
                                                                "Number Theory",
                                                                "Topology",
                                                                "Probability Theory",
                                                                "Statistics",
                                                                "Applied Mathematics",
                                                                "Discrete Mathematics",
                                                                "Mathematical Logic"
                                                            ],
                                                            "Engineering": [
                                                                "Mechanical Engineering",
                                                                "Electrical Engineering",
                                                                "Civil Engineering",
                                                                "Chemical Engineering",
                                                                "Aerospace Engineering",
                                                                "Biomedical Engineering",
                                                                "Environmental Engineering",
                                                                "Industrial Engineering",
                                                                "Software Engineering",
                                                                "Robotics Engineering"
                                                            ],
                                                            "Environmental Science": [
                                                                "Ecology",
                                                                "Climate Science",
                                                                "Environmental Chemistry",
                                                                "Conservation Biology",
                                                                "Environmental Policy",
                                                                "Sustainable Development",
                                                                "Pollution Control",
                                                                "Renewable Energy",
                                                                "Waste Management",
                                                                "Natural Resource Management"
                                                            ],
                                                            "Agricultural and Food Sciences": [
                                                                "Agronomy",
                                                                "Animal Science",
                                                                "Horticulture",
                                                                "Food Technology",
                                                                "Soil Science",
                                                                "Plant Pathology",
                                                                "Agricultural Economics",
                                                                "Agricultural Engineering",
                                                                "Crop Science",
                                                                "Food Safety"
                                                            ],
                                                            "Education": [
                                                                "Curriculum and Instruction",
                                                                "Educational Psychology",
                                                                "Special Education",
                                                                "Higher Education",
                                                                "Early Childhood Education",
                                                                "Educational Technology",
                                                                "Educational Leadership",
                                                                "Adult Education",
                                                                "Comparative Education",
                                                                "STEM Education"
                                                            ],
                                                            "Law": [
                                                                "Constitutional Law",
                                                                "Criminal Law",
                                                                "Civil Law",
                                                                "International Law",
                                                                "Corporate Law",
                                                                "Environmental Law",
                                                                "Intellectual Property Law",
                                                                "Human Rights Law",
                                                                "Tax Law",
                                                                "Health Law"
                                                            ],
                                                            "Linguistics": [
                                                                "Phonetics",
                                                                "Phonology",
                                                                "Syntax",
                                                                "Semantics",
                                                                "Pragmatics",
                                                                "Sociolinguistics",
                                                                "Psycholinguistics",
                                                                "Historical Linguistics",
                                                                "Computational Linguistics",
                                                                "Applied Linguistics"
                                                            ]
                                                        }[profileDetails.field_of_study] || []).map((subField) => (
                                                            { label: subField, value: subField }
                                                        ))}
                                                        onChange={(value) => {
                                                            setProfileDetails({
                                                                ...profileDetails,
                                                                area_of_focus: value,
                                                            });
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                    </View>

                                    <View marginB-20>
                                        <View marginT-20>
                                            {Label({ label: "Research interests", fontSize: 16 })}

                                            <View row centerV>
                                                <View flex>
                                                    <CustomTextField
                                                        value={profileDetails.research_passion}
                                                        placeholder="Research interests"
                                                        onChange={(value) => {
                                                            setProfileDetails({
                                                                ...profileDetails,
                                                                research_passion: value,
                                                            });
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View marginT-20>
                                    <View row spread>
                                        <PrimaryButton
                                            label={"Save"}
                                            onPress={async () => {
                                                if (!profileDetails.name) {
                                                    message.error("Please enter your name");
                                                    return;
                                                }

                                                if (!profileDetails.academic_status) {
                                                    message.error("Please select your academic status");
                                                    return;
                                                }

                                                if (profileDetails.academic_status === "Other" && !profileDetails.other_academic_status) {
                                                    message.error("Please specify your academic status");
                                                    return;
                                                }

                                                if (!profileDetails.field_of_study) {
                                                    message.error("Please select your field of study");
                                                    return;
                                                }

                                                if (!profileDetails.area_of_focus) {
                                                    message.error("Please enter your area of focus");
                                                    return;
                                                }

                                                if (!profileDetails.research_passion) {
                                                    message.error("Please enter your research passion");
                                                    return;
                                                }

                                                await api.customRoute("save-user", {
                                                    user: {
                                                        ...profileDetails,
                                                    }
                                                })

                                                setGlobalData({
                                                    name: profileDetails.name,
                                                });

                                                navigationFn("home");
                                                return;
                                            }}
                                        />
                                    </View>
                                </View>
                            </Card>
                        </View>
                    </View>
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>

        <CustomModal
            title="Forgot Password"
            width={'500px'} visible={showForgotPasswordModal} onClose={() => {
                setShowForgotPasswordModal(false);
            }}>
            <View center paddingH-20>
                <View centerH style={{
                    width: "100%",
                    maxWidth: 500,
                }}>
                    <View style={{
                        width: "100%",
                    }}>
                        {Label({ label: "Email" })}
                        <CustomTextField value={forgotEmail} onChange={(value) => {
                            setForgotEmail(value);
                        }} />
                    </View>
                    {!otpSent && <View marginT-10 style={{
                        width: "100%",
                    }}>
                        <PrimaryButton label="Send OTP" disabled={sendingOTP} onPress={async () => {
                            if (!forgotEmail) {
                                if (Platform.OS === 'web') {
                                    message.success("Please enter email");
                                } else {
                                    Alert.alert("Please enter email");
                                }
                                return;
                            }

                            setSendingOTP(true);
                            const res = await api.customRoute("forgot-password", {
                                email: forgotEmail,
                            });

                            if (res.message) {
                                if (Platform.OS === 'web') {
                                    message.success(res.message);
                                } else {
                                    Alert.alert(res.message);
                                }
                            } else {
                                if (Platform.OS === 'web') {
                                    message.success("Something went wrong");
                                } else {
                                    Alert.alert("Something went wrong");
                                }
                            }
                            setSendingOTP(false);
                            setOtpSent(true);
                        }} />
                    </View>}
                    {otpSent && <View marginT-10 style={{
                        width: "100%",
                    }}>
                        {Label({ label: "OTP" })}
                        <CustomTextField value={forgotOTP} onChange={(value) => {
                            setForgotOTP(value);
                        }} />
                    </View>}
                    {otpSent && <View marginT-10 style={{
                        width: "100%",
                    }}>
                        {Label({ label: "New Password" })}
                        <CustomTextField type="password" value={forgotPassword} onChange={(value) => {
                            setForgotPassword(value);
                        }} />
                    </View>}
                    {otpSent && <View marginT-10 style={{
                        width: "100%",
                    }}>
                        <PrimaryButton label="Reset Password" onPress={async () => {
                            if (!forgotOTP) {
                                if (Platform.OS === 'web') {
                                    message.success("Please enter OTP");
                                } else {
                                    Alert.alert("Please enter OTP");
                                }
                                return;
                            }

                            if (!forgotPassword) {
                                if (Platform.OS === 'web') {
                                    message.success("Please enter new password");
                                } else {
                                    Alert.alert("Please enter new password");
                                }
                                return;
                            }

                            if (forgotPassword.length < 6) {
                                if (Platform.OS === 'web') {
                                    message.success("Password should be atleast 6 characters long");
                                } else {
                                    Alert.alert("Password should be atleast 6 characters long");
                                }
                                return;
                            }

                            const res = await api.customRoute("reset-password", {
                                email: forgotEmail,
                                otp: forgotOTP,
                                password: forgotPassword,
                            })

                            if (res.message) {
                                if (Platform.OS === 'web') {
                                    message.success(res.message);
                                } else {
                                    Alert.alert(res.message);
                                }

                                if (res.success) {
                                    setShowForgotPasswordModal(false);
                                }
                            } else {
                                if (Platform.OS === 'web') {
                                    message.success("Something went wrong");
                                } else {
                                    Alert.alert("Something went wrong");
                                }
                            }
                        }} />
                    </View>}
                </View>
            </View>

        </CustomModal>
    </View >)
})

export default ProfileScreenResearchist

const $container = {
    flex: 1,
}